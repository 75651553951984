import { useContext } from "react";
import { TFileInfo } from "../../generated/gql/graphql";
import { AppContext } from "../contexts/AppContext";
import { useQuery } from "@apollo/client";
import { LIST_FILES } from "../graphql/query";


export type FileLoadingResult = {
  loading: boolean;
  files: TFileInfo[];
  refetch: () => void;
}


export function useFiles(siteId?: string, flowId?: string): FileLoadingResult {
  const { setError } = useContext(AppContext);
  const { data, loading, refetch } = useQuery(LIST_FILES, {
    variables: { siteId, flowId },
    onError: setError,
  });

  return {
    loading,
    files: data?.files || [],
    refetch,
  };
}
