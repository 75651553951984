import { $getRoot, ElementNode, LexicalNode, LineBreakNode, ParagraphNode, TextNode } from 'lexical';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { TreeView } from '@lexical/react/LexicalTreeView';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';
import { DynamicValue, FormattedDynamicValue } from '../../../types/DynamicValueTypes';
import { DataReferenceNode } from './nodes/DataReferenceNode';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import DataReferenceAutoSuggestPlugin from './plugins/DataReferenceAutoSuggestPlugin';

const theme = {
  // Theme styling goes here
  //...
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

function TreeViewPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  return (
    <TreeView
      viewClassName="tree-view-output"
      treeTypeButtonClassName="debug-treetype-button"
      timeTravelPanelClassName="debug-timetravel-panel"
      timeTravelButtonClassName="debug-timetravel-button"
      timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
      timeTravelPanelButtonClassName="debug-timetravel-panel-button"
      editor={editor}
    />
  );
}


type ParamInputFieldProps = {
  initialValue: DynamicValue | FormattedDynamicValue | null,
  onChange: (val: DynamicValue | FormattedDynamicValue | null) => void,
  resetTrigger: any,
  debug?: boolean,
}


function ParamInputFieldInternal(props: ParamInputFieldProps): React.ReactElement {

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const formattedDv = !props.initialValue
      ? []
      : Array.isArray(props.initialValue)
        ? props.initialValue
        : [props.initialValue];

    editor.update(() => {
      const root = $getRoot();
      root.clear();
      let currentParagraph = new ParagraphNode();
      root.append(currentParagraph);
      for (const val of formattedDv) {
        if (typeof val === 'string') {
          let line = '';
          for (const char of val) {
            if (char === '\n') {
              currentParagraph.append(new TextNode(line));
              line = '';
              currentParagraph = new ParagraphNode();
              root.append(currentParagraph);
            } else {
              line += char;
            }
          }
          if (line) {
            currentParagraph.append(new TextNode(line));
          }
        } else {
          currentParagraph.append(new DataReferenceNode(val));
        }
      }
    });
  }, [props.resetTrigger]);

  return <>
    <RichTextPlugin
      contentEditable={<ContentEditable />}
      placeholder={<div>Enter some text...</div>}
      ErrorBoundary={LexicalErrorBoundary}
    />
    <DataReferenceAutoSuggestPlugin />
    <HistoryPlugin />
    <AutoFocusPlugin />
    {props.debug && <TreeViewPlugin />}
    <OnChangePlugin
      ignoreHistoryMergeTagChange
      ignoreSelectionChange
      onChange={(editorState) => {
        editorState.read(() => {
          const root = $getRoot();
          const nodes = root.getChildren();
          const dv = nodes.flatMap(lexicalNodeToDynamicValue);
          // remove trailing newline
          if (dv.length > 0 && dv[dv.length - 1] === '\n') {
            dv.pop();
          }
          if (dv.length === 0) {
            props.onChange(null);
          }
          props.onChange(dv);
        });
      }}
    />
  </>
}


// NOTE: source of truth value is in editor state.
export function ParamInputField(props: ParamInputFieldProps): React.ReactElement {
  const initialConfig = {
    namespace: 'GoPixie.ParamInputField',
    theme,
    onError,
    nodes: [DataReferenceNode],
  };

  return <LexicalComposer initialConfig={initialConfig}>
    <ParamInputFieldInternal {...props} />
  </LexicalComposer>;
}


function lexicalNodeToDynamicValue(node: LexicalNode): FormattedDynamicValue {
  // NOTE: this branch needs to be ahead of TextNode branch
  // since DataReferenceNode is a subclass of TextNode
  if (node instanceof DataReferenceNode) {
    return [node.__value];
  } else if (node instanceof TextNode) {
    return [node.getTextContent()];
  } else if (node instanceof LineBreakNode) {
    return ['\n'];
  } else if (node instanceof ElementNode) {
    let children = node.getChildren().flatMap(lexicalNodeToDynamicValue);
    if (node instanceof ParagraphNode) {
      children.push('\n');
    }
    return children;
  }
}
