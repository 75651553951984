import {
  Node,
} from 'reactflow';
import {
  TFlowPluginV2,
} from '../../generated/gql/graphql';


export const flowNodeType = "flowNode";
export type FlowNodeType = typeof flowNodeType;
export type FlowNodeData = TFlowPluginV2;
export type GraphNode = Node<FlowNodeData, FlowNodeType>;
