import _ from 'lodash';
import { JSONSchema7 } from 'json-schema';

export default function intersectJsonSchemas(schemas: (JSONSchema7 | null | undefined)[]): JSONSchema7 | null | undefined {
  if (schemas.length === 0) return {};

  // Start with the first schema
  let intersectedSchema: JSONSchema7 | null | undefined = schemas[0];

  for (let i = 1; i < schemas.length; i++) {
    intersectedSchema = intersectTwoSchemas(intersectedSchema, schemas[i]);
  }

  return intersectedSchema;
}

function intersectTwoSchemas(schema1: JSONSchema7 | null | undefined, schema2: JSONSchema7 | null | undefined): JSONSchema7 | null | undefined {

  if (!schema1) return schema2;
  if (!schema2) return schema1;
  if (!schema1 || !schema2) return null;

  const intersectedSchema: JSONSchema7 = {};

  // Intersect top-level properties
  if (schema1.type === schema2.type) {
    intersectedSchema.type = schema1.type;
  }

  // Intersect properties
  if (schema1.properties && schema2.properties) {
    intersectedSchema.properties = {};
    const commonProperties = _.intersection(Object.keys(schema1.properties), Object.keys(schema2.properties));
    commonProperties.forEach((prop) => {
      intersectedSchema.properties![prop] = intersectTwoSchemas(
        schema1.properties![prop] as JSONSchema7,
        schema2.properties![prop] as JSONSchema7
      ) || false;
    });
  }

  // Intersect required fields
  if (schema1.required && schema2.required) {
    intersectedSchema.required = _.intersection(schema1.required, schema2.required);
  }

  // Intersect additional properties, items, etc. if needed
  // This can be expanded based on more complex schema requirements
  return intersectedSchema;
}
