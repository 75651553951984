
// use the newer upload_file endpoint
import { useMutation } from "@apollo/client/react";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UPLOAD_FILE, UPLOAD_IMAGE } from "../../graphql/mutation";
import { Accept, useDropzone } from "react-dropzone";
import { Box, Button, Card, Divider, LinearProgress, Stack, TextField, Typography, useTheme } from "@mui/material";
import { AppContext } from "../../contexts/AppContext";

interface ImageMetadata {
  [fileName: string]: {
    file: File,
    temp_url: string,
    description: string,
  };
}

interface UploadStatus {
  [fileName: string]: number | false | null,
}

export function UploaderV2(props: {
  onChange?: (fileNames: string[]) => void,
  // IMPORTANT: the onComplete object is expected to be non-empty
  onComplete: (fileIdByNames: { [fileName: string]: string }) => void,
  siteId?: string,
  flowId?: string,
  maxFiles?: number,
  // key is MIME type, values are extensions (dot included)
  accept?: Accept,
}): React.ReactElement {
  const { setError } = useContext(AppContext);
  const theme = useTheme();

  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [files, setFiles] = useState<File[]>([]);
  const uploadStatus = useRef<UploadStatus>({});
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: files => {
      setFiles(files);
      props.onChange?.(files.map(f => f.name));
    },
    maxFiles: props.maxFiles,
    accept: props.accept,
  });

  function upload() {
    setUploadInProgress(true);

    uploadStatus.current = [
      ...files.map(f => f.name),
    ].reduce(
      (obj, fileName) => {
        obj[fileName] = null;
        return obj;
      },
      {},
    );
    if (Object.keys(uploadStatus.current).length == 0) {
      setUploadInProgress(false);
      setUploadCompleted(true);
      return;
    }

    files.forEach((file) => {
      uploadFile({ variables: { file, siteId: props.siteId, flowId: props.flowId } })
        .then(res => {
          if (res.errors) setError(res.errors[0]);
          if (res.data) uploadStatus.current[file.name] = parseInt(res.data.auploadFile);
        })
        .catch(e => setError(JSON.stringify(e)))
        .finally(() => {
          if (uploadStatus.current[file.name] === null) uploadStatus.current[file.name] = false;
          // TODO handle upload failure
          setUploadCompleted(Object.values(uploadStatus.current).every(v => v));
        })
    });
  }

  useEffect(() => {
    // TODO handle upload failure
    if (uploadCompleted) {
      let fileIdByNames: { [fileName: string]: string } = {};
      Object.entries(uploadStatus.current).forEach(([fileName, status]) => {
        if (typeof status === 'number') {
          fileIdByNames[fileName] = status.toString();
        }
      });
      props.onComplete(fileIdByNames);
    }
    if (uploadInProgress) setUploadInProgress(false);
  }, [uploadCompleted]);

  return (
    <Card sx={{ p: 4 }}>
      <Stack spacing={2}>
        {uploadInProgress
          ? <Typography variant="h4">Uploading...</Typography>
          : <Box {...getRootProps()} sx={{
            height: '200px',
            border: `2px dashed ${theme.palette.divider}`,
            p: 2,
            borderRadius: 2,
            cursor: 'pointer',
            userSelect: 'none',
          }}>
            <input {...getInputProps()} />
            <Typography sx={{ cursor: 'pointer' }}>{
              isDragActive ?
                'Drop the files here ...' :
                "Drag 'n' drop some files here, or click to select files"
            }</Typography>
          </Box>
        }
        <Stack spacing={1} pl={2} pr={2} divider={<Divider variant="middle" />}>
          {files.map((f, idx) => {
            return <Box key={`document-${idx}`}>
              <Typography variant="subtitle1"><b>{f.name}</b></Typography>
            </Box>;
          })}
        </Stack>

        {uploadInProgress
          ? <LinearProgress />
          : <Button disabled={files.length == 0} onClick={upload} variant="contained">Done</Button>
        }
      </Stack>
    </Card>

  );
}
