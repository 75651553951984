import { Fade, GlobalStyles } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import { Route, useLocation } from 'react-router-dom';
import React, { createContext, useState, useEffect, useContext, Ref } from 'react';

export function usePulse() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => setShow(prevShow => !prevShow), 500);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return show;
}


export function Pulse(props: {
  children: React.ReactElement,
  disabled?: boolean,
}): React.ReactElement {
  const show = usePulse();

  if (props.disabled) return props.children;

  return <Fade in={show}>
    {props.children}
  </Fade>
}

export function RouteWithFade(props: {
  path: string,
  children: React.ReactNode,
  duration?: number,
  nodeRef?: Ref<HTMLElement | undefined>,
  exact?: boolean,
}): React.ReactElement {
  const duration = props.duration || 1000;
  const currentRoutePath = useLocation().pathname;
  const classNames = 'animations-route-fade';

  return <>
    <GlobalStyles styles={{
      '.animations-route-fade-enter': {
        opacity: 0,
      },
      '.animations-route-fade-enter-active': {
        opacity: 1,
        transition: `opacity ${duration}ms`,
      },
      '.animations-route-fade-exit': {
        opacity: 1,
      },
      '.animations-route-fade-exit-active': {
        opacity: 0,
        transition: `opacity ${duration}ms`,
      },
    }} />
    <Route path={props.path} exact={props.exact}>
      <CSSTransition
        key={`${props.path}:animations-route-fade-transition`}
        classNames={classNames}
        in={props.path == currentRoutePath}
        timeout={duration}
        nodeRef={props.nodeRef}
      >
        {props.children}
      </CSSTransition>
    </Route>
  </>;
}
