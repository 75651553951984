import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { AppRunError, ClientState } from '../types/ClientState';
import { TAppDebugInfo, TLayout, TMessage, TStatusMessage, TUserInputRequirement, TPalette, TFont, IWebInteractionOutcome } from '../../generated/gql/graphql';
import { ApolloClient } from '@apollo/client';
import { GET_STYLING } from '../graphql/query';
import { computed } from '../utils/zustand-computed';
import { createTheme } from '@mui/material';
import Color from 'color';
import { isEqual } from 'lodash';
import { removeTypename } from '../utils/removeTypename';

export type ClientStateWithHelpers = ClientState & {
  setClientId: (clientId: string | undefined) => void,
  setFlowId: (flowId: string | undefined) => void,
  setInConversation: (conversationOpen: boolean) => void,
  clearUnreadCount: () => void,
  increaseUnreadCount: (increaseBy?: number) => void,

  setInitializing: (initializing: boolean) => void,
  setServerInProgress: (serverInProgress: boolean) => void,
  setInputRequirement: (inputRequirement: TUserInputRequirement | undefined) => void,
  addError: (error: AppRunError) => void,
  setCompleted: (completed: boolean) => void,
  setInterrupted: (interrupted: 'error' | 'stop' | null) => void,
  addDebugLog: (debugLog: TAppDebugInfo) => void,
  // remove the taget and all debug logs following the target log
  updateDebugLogs: (func: (logs: TAppDebugInfo[]) => TAppDebugInfo[]) => void,
  addMessage: (messages: TMessage | TStatusMessage) => void,
  clearChatEntries: () => void,
  resetClient: () => void,
  setWebInteractionOutcome: (outcome: IWebInteractionOutcome) => void,

  // graphql
  loadStylingCustomizations: (apolloClient: ApolloClient<object>) => Promise<void>,

  // computed properties
  getPalatte: () => TPalette,
  getFont: () => TFont,
  getLayout: () => TLayout,
  isDefaultPalette: () => boolean,
  isDefaultFont: () => boolean,
  isDefaultLayout: () => boolean,
}

export const defaultLayout: TLayout = {
  fabSize: 80,
  fabSpacing: { xs: 40, sm: 60, md: 80 },
  dialogWidth: { xs: '100vw', md: '60vw', lg: '40vw', xl: '30vw' },
  dialogHeight: '70vh',
  dialogDraggable: false,
}

const defaultTheme = createTheme();

function standardizePalette(palette: TPalette): TPalette {
  return {
    primary: Color(palette.primary).hex(),
    accent1: Color(palette.accent1).hex(),
    accent2: Color(palette.accent2).hex(),
    callToAction: Color(palette.callToAction).hex(),
  };
}

export const defaultPalette: TPalette = standardizePalette({
  primary: defaultTheme.palette.background.default,
  accent1: defaultTheme.palette.secondary.main,
  accent2: defaultTheme.palette.primary.main,
  callToAction: defaultTheme.palette.info.main,
})

export const defaultFont: TFont = {
  fontSize: defaultTheme.typography.fontSize,
  fontFamily: defaultTheme.typography.fontFamily,
}

export const initialClientState: ClientState = {
  clientId: undefined,
  // TODO the usage of flowId is not consistent
  // In embedApp this is source of truth
  // while in other app view (web, editor) this is not used & maintained
  flowId: undefined,
  inConversation: false,
  unreadCount: 0,
  initializing: true,
  serverInProgress: false,
  inputRequirement: undefined,
  chatEntries: [],
  debugLogs: [],
  completed: false,
  stylingLoaded: false,
  _palatte: undefined,
  _font: undefined,
  _layout: undefined,
  icon: null,
  openAfter: null,
  interrupted: null,
  webInteractionOutcome: {},
}

export const useClientStore = create<ClientStateWithHelpers>()(
  devtools(subscribeWithSelector(immer((set, get) => ({
    ...initialClientState,
    setClientId: clientId => {
      set(state => {
        state.clientId = clientId
      });
    },
    setFlowId: flowId => {
      set(state => {
        if (state.flowId !== flowId) {
          // preserve the inConversation state
          const inConversation = state.inConversation;
          resetClientState(state);
          state.inConversation = inConversation;
        }
        state.flowId = flowId;
      });
    },
    setInConversation: inConversation => {
      set(state => {
        state.inConversation = inConversation
      });
    },
    clearUnreadCount: () => {
      set(state => {
        state.unreadCount = 0
      });
    },
    increaseUnreadCount: (increaseBy = 1) => {
      set(state => {
        state.unreadCount += increaseBy
      });
    },
    setInitializing: initializing => {
      set(state => {
        state.initializing = initializing
      });
    },
    setServerInProgress: serverInProgress => {
      set(state => {
        if (!serverInProgress) {
          removeTransitionalStatusMessage(state);
        }
        state.serverInProgress = serverInProgress;
      });
    },
    setInputRequirement: inputRequirement => {
      set(state => {
        removeTransitionalStatusMessage(state);
        state.inputRequirement = inputRequirement;
      });
    },
    addError: error => {
      set(state => {
        removeTransitionalStatusMessage(state);
        // NOTE: cast to add readonly error to the state
        // error objects in chatEntries are readonly
        state.chatEntries.push(error as any);
      });
    },
    setCompleted: completed => {
      set(state => {
        removeTransitionalStatusMessage(state);
        state.completed = completed;
      });
    },
    setInterrupted: interrupted => {
      set(state => {
        state.interrupted = interrupted;
      });
    },
    addDebugLog: debugLog => {
      set(state => {
        state.debugLogs.push(debugLog)
      });
    },
    updateDebugLogs: func => {
      set(state => {
        state.debugLogs = func(state.debugLogs);
      });
    },
    addMessage: message => {
      set(state => {
        removeTransitionalStatusMessage(state);
        state.chatEntries.push(message);
      });
    },
    clearChatEntries: () => {
      set(state => {
        state.chatEntries = [];
      });
    },
    resetClient: () => {
      set(resetClientState);
    },

    setWebInteractionOutcome: outcome => {
      set(state => {
        state.webInteractionOutcome = outcome;
      });
    },

    loadStylingCustomizations: async apolloClient => {
      const state = get();
      if (!state.flowId) {
        throw new Error('Cannot load styling without flowId');
      }
      const styling = await queryStyling(apolloClient, state.flowId).finally(() => set(state => {
        state.stylingLoaded = true;
      }));
      set(state => {
        state._palatte = styling.palette;
        state._font = styling.font;
        state._layout = styling.layout;
        state.icon = styling.icon || null;
        state.openAfter = styling.openAfter || null;
      });
    },

    // computed properties
    getPalatte: computed(
      () => [get()._palatte],
      ([], maybePalatte) => maybePalatte || defaultPalette,
    ),
    getFont: computed(
      () => [get()._font],
      ([], maybeFont) => maybeFont || defaultFont,
    ),
    getLayout: computed(
      () => [get()._layout],
      ([], maybeLayout) => maybeLayout || defaultLayout,
    ),
    isDefaultPalette: computed(
      () => [get()._palatte],
      ([], maybePalatte) => {
        return !maybePalatte || isEqual(removeTypename(maybePalatte), defaultPalette)
      }
    ),
    isDefaultFont: computed(
      () => [get()._font],
      ([], maybeFont) => {
        return !maybeFont || isEqual(removeTypename(maybeFont), defaultFont)
      },
    ),
    isDefaultLayout: computed(
      () => [get()._layout],
      ([], maybeLayout) => {
        return !maybeLayout || isEqual(removeTypename(maybeLayout), defaultLayout);
      }
    ),
  }))))
)


async function queryStyling(apolloClient: ApolloClient<object>, flowId: string) {
  const res = await apolloClient.query({
    query: GET_STYLING,
    variables: { flowId },
    fetchPolicy: 'no-cache',
  });
  return res.data.styling;
}


function resetClientState(state: ClientState) {
  const flowId = state.flowId;
  for (const key in initialClientState) {
    state[key] = initialClientState[key];
  }
  // Preserve flowId
  state.flowId = flowId;
}

export function isMessage(entry: TMessage | TStatusMessage | AppRunError): entry is TMessage {
  return (entry as any)?.__typename === 'TMessage';
}

export function isStatusMessage(entry: TMessage | TStatusMessage | AppRunError): entry is TStatusMessage {
  return (entry as any)?.__typename === 'TStatusMessage';
}


function removeTransitionalStatusMessage(state: ClientState) {
  const lastMessage = state.chatEntries[state.chatEntries.length - 1];
  if (lastMessage && isStatusMessage(lastMessage) && !lastMessage.persistent) {
    state.chatEntries.pop();
  }
}
