import { Alert, Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IUserInput } from '../../../../../generated/gql/graphql';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Pulse } from '../../../../components/animations';
import ChatBubble from './ChatBubble';
import ChatInput from './ChatInput';
import Markdown from '../../../../components/material-markdown';
import { AppRun } from '../../../../hooks/useAppRun';
import { useClientStore } from '../../../../hooks/ClientState';
import { useShallow } from 'zustand/react/shallow';


export function ChatView(props: AppRun & { width: string }): React.ReactElement {
  const [input, setInput] = useState<IUserInput>({});
  const [
    interrupted,
    setInterrupted,
  ] = useClientStore(useShallow(state => [
    state.interrupted,
    state.setInterrupted,
  ]));
  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [props.messages, props.inProgress, props.inputRequirement, props.completed]);


  return props.initializing
    ? <Box width={props.width} height='100%' display='flex' justifyContent='center' alignItems='center'>
      <CircularProgress size='5rem' />
    </Box>
    // TODO error display is needed when the flow cannot be initialized
    : <Stack spacing={2} sx={{
      display: 'flex',
      justifyContent: 'space-between',
      width: props.width,
      height: '100%',
      overflow: 'hidden',
    }}>
      <Stack ref={containerRef} spacing={2} sx={{
        p: 2,
        flexGrow: 1,
        overflowY: 'auto',
        //TODO hack - hide convo when questionnaire is present
        //need to handle hide/show convo more generically and gracefully
        // display: inputRequirement.questionnaire && !waitingForServer && !chatEnded ? 'none' : 'inherit',
      }}>

        {props.messages.map(
          (msg, idx) => msg.__typename == 'TStatusMessage'
            ? <Box key={idx} maxWidth='100%' textOverflow='ellipsis' whiteSpace='pre-wrap' textAlign='center'>
              <Markdown>{msg.content}</Markdown>
            </Box>
            : <ChatBubble
              key={idx}
              message={msg}
            />
          // : <ChatCard key={idx} data={msgOrCard.card as TCard} />
        )}
        {props.inProgress ? <Box p={1}><Pulse><MoreHorizIcon color='disabled' /></Pulse></Box> : undefined}
        {props.error
          ? <Alert severity='error'>{props.error.name}: {props.error.message}</Alert>
          : undefined
        }
      </Stack>
      {props.completed
        ? <Box width='100%' p={1} display='flex' justifyContent='center' alignItems='center'>
          <Typography variant='subtitle1' color={theme.palette.text.secondary}>Session has ended.</Typography>
        </Box>
        : interrupted
          ? <Button variant='outlined' color='secondary' sx={{ borderRadius: '40px' }} onClick={() => {
            setInterrupted(null);
            props.send([], () => setInput({}));
          }}><Typography variant='h6'>{interrupted === 'stop' ? 'Resume' : 'Try Again'}</Typography></ Button>
          : <ChatInput
            processing={props.inProgress}
            disabled={props.inProgress}
            value={input}
            inputRequirment={props.inputRequirement || {
              choicesOnly: false,
              choicesMultiple: false,
              knowledgeSelect: false,
              datasetSelect: false,
              fileSelect: false,
            }}
            onChange={setInput}
            onSubmit={s => props.send([s], () => setInput({}))}
            onCancel={() => {
              props.disconnect(true);
              setInterrupted('stop');
            }}
          />
      }
    </Stack>
}
