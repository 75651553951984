import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';

import { DynamicValue } from '../../../../types/DynamicValueTypes';
import React from 'react';
import { DynamicValueDisplay } from '../../DynamicValueDisplay';


export type SerializedDataReferenceNode = {
  type: 'dataReference';
  version: 1;
  value: DynamicValue;
};

export class DataReferenceNode extends DecoratorNode<React.ReactNode> {
  __value: DynamicValue;

  static getType(): string {
    return 'dataReference';
  }

  static clone(node: DataReferenceNode): DataReferenceNode {
    return new DataReferenceNode(node.__value, node.__key);
  }

  constructor(dynamicValue: DynamicValue, key?: NodeKey) {
    super(key);
    this.__value = structuredClone(dynamicValue);
  }

  exportJSON(): SerializedDataReferenceNode {
    return {
      type: 'dataReference',
      version: 1,
      value: structuredClone(this.__value),
    };
  }

  static importJSON(serializedNode: SerializedDataReferenceNode): DataReferenceNode {
    const node = $createDataReferenceNode(serializedNode.value);
    return node;
  }

  // TODO to use react component, this node needs to be a decoratorNode instead of a textNode
  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): React.ReactNode {
    return <DynamicValueDisplay dynamicValue={this.__value} />;
  }
}


export function $createDataReferenceNode(value: DynamicValue, key?: NodeKey): DataReferenceNode {
  return new DataReferenceNode(value, key);
}

export function $isDataReferenceNode(node: LexicalNode | null | undefined): node is DataReferenceNode {
  return node instanceof DataReferenceNode;
}
