import { AxiosError } from "axios";
import React from "react";
import { ApolloError } from "@apollo/client";
import { TUser } from "../../generated/gql/graphql";
import { GraphQLError } from "graphql";

export const AppContext = React.createContext({
  error: undefined as AxiosError | ApolloError | GraphQLError | string | undefined,
  setError: (e: AxiosError | GraphQLError | ApolloError | string | undefined) => { },
  containerRef: undefined as React.RefObject<HTMLElement> | undefined,
  setSuccessMessage: (msg: string) => { },
});
