import React, {
  useCallback,
  useEffect, useRef,
  useState
} from 'react';
import { TLayout } from '../../../../../generated/gql/graphql';
import { useClientStore } from '../../../../hooks/ClientState';
import { Property } from 'csstype';
import {
  Box,
  CircularProgress, alpha,
  useTheme,
  Badge,
  IconButton,
  styled,
  Fab,
  Popover
} from '@mui/material';
import { useApolloClient } from '@apollo/client';
import CloseRounded from "@mui/icons-material/CloseRounded";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import Lottie from "lottie-react";
import Draggable from "react-draggable";
import { Pulse } from '../../../../components/animations';
import { GOPIXIE_DRAWER_ELEM_ID } from '../../../../constants/common';
import { useUserAndWorkspaceStore } from '../../../../hooks/UserAndWorkspaceStore';
import { useShallow } from 'zustand/react/shallow';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: '10px',
    top: '10px',
    border: `2px solid ${theme.palette.background.paper}`,
    zIndex: 9001,
  },
}));
function ChatFABIcon(props: {
  pulse: boolean;
}): React.ReactElement {
  const icon = useClientStore(state => state.icon);

  const [animationData, setAnimationData] = useState<object | null>(null);

  useEffect(() => {
    if (icon?.lottieUrl) {
      const fetchData = async () => {
        try {
          const response = await fetch(icon.lottieUrl);
          const data = await response.json();
          setAnimationData(data);
        }
        catch (error) {
          console.error('Error loading animation:', error);
        }
      };

      fetchData();
    }
  }, [icon?.lottieUrl]);

  return animationData
    ? <Box><Lottie animationData={animationData} loop style={{ width: '100%', height: '100%' }} /></Box>
    : <Pulse disabled={!props.pulse}><SmsRoundedIcon sx={{ fontSize: 35 }} /></Pulse>;
}
export function AppPopover(props: {
  children: React.ReactElement;
  authToken?: string;
  // app will not open nor start when showLoading is true
  showLoading?: boolean;
  initialOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  backdropFilter?: Property.BackdropFilter;
  // if true, backdrop click or escape key will not close the popover
  disableQuickClose?: boolean;
  layout?: TLayout;
}): React.ReactElement {
  const [
    inConversation, unreadCount, setInConversation, clearUnreadCount,
  ] = useClientStore(useShallow(state => [
    state.inConversation,
    state.unreadCount,
    state.setInConversation,
    state.clearUnreadCount,
  ]));

  const [
    loading, loadIdentity,
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.isLoading(),
    state.loadIdentity,
  ]));

  const client = useApolloClient();
  const theme = useTheme();


  const skipAutoOpen = useRef(false);
  const autoOpen = useRef(null);
  const layoutFromStore = useClientStore(useShallow(state => state.getLayout()));
  const layout = props.layout || layoutFromStore;
  const openAfter = useClientStore(state => state.openAfter);

  useEffect(() => {
    if (skipAutoOpen.current) {
      return;
    }
    if (openAfter !== null) {
      autoOpen.current = setTimeout(() => {
        startApp();
      }, openAfter * 1000);
    }
    skipAutoOpen.current = true;
  }, [openAfter]);

  const anchorEl = useRef<HTMLButtonElement>(null);
  const isDragging = useRef(false);
  const dragStartTimeout = useRef(null);
  const dragEndTimeout = useRef(null);

  const draggableProps = {
    onStart: () => {
      isDragging.current = false;
      if (dragStartTimeout.current) {
        clearTimeout(dragStartTimeout.current);
      }
      // delay drag start to make sure click is not omitted by tiny drag
      dragStartTimeout.current = setTimeout(() => {
        isDragging.current = true;
      }, 200);
    },

    onStop: () => {
      if (dragStartTimeout.current) {
        clearTimeout(dragStartTimeout.current);
      }
      if (dragEndTimeout.current) {
        clearTimeout(dragEndTimeout.current);
      }
      dragEndTimeout.current = setTimeout(() => {
        isDragging.current = false;
      }, 0);
    },
  };

  const closeApp = useCallback(() => {
    clearUnreadCount();
    props.onClose?.();
    setInConversation(false);
  }, [props.onClose]);

  const startApp = useCallback(() => {
    if (!isDragging.current && !props.showLoading) {
      setInConversation(true);
      clearUnreadCount();
      if (autoOpen.current) {
        clearTimeout(autoOpen.current);
      }
      skipAutoOpen.current = true;
    }
  }, [props.onOpen, props.showLoading]);

  // This only open the app once based on the initial value of initialOpen
  // except when the app's isLoading state changes
  useEffect(() => {
    if (props.initialOpen) {
      startApp();
    }
  }, [props.showLoading]);

  // represent whether the app popover is open
  const appPopoverIsOpen = inConversation && anchorEl.current != null && !props.showLoading;

  useEffect(() => {
    if (appPopoverIsOpen) {
      props.onOpen?.();
    }
  }, [appPopoverIsOpen]);

  useEffect(() => {
    loadIdentity(client, true);
  }, [props.authToken]);

  return <>
    {/* TODO somehow the draggable wrapper is causing the FAB to be unclickable on linkedin */}
    {/* <Draggable axis='y' {...draggableProps}> */}
    <Box
      height={layout.fabSize}
      width={layout.fabSize}
      borderRadius={layout.fabSize}
      position='fixed'
      bottom={layout.fabSpacing}
      right={layout.fabSpacing}
      sx={{
        // zIndex: 2147483647,
        opacity: appPopoverIsOpen ? 0 : 1,
      }}
    >
      <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent={unreadCount} color='error'>
        <Fab
          ref={anchorEl}
          disabled={loading || props.showLoading}
          color='secondary'
          sx={{ height: layout.fabSize, width: layout.fabSize }}
          onClick={startApp}
        >
          {!loading
            ? props.showLoading
              ? <CircularProgress size={35} />
              : <ChatFABIcon pulse={unreadCount !== 0} />
            : <PersonOffRoundedIcon sx={{ fontSize: 35 }} />}
        </Fab>
      </StyledBadge>
    </Box>
    {/* </Draggable> */}
    <Draggable {...draggableProps} disabled={!layout.dialogDraggable}>
      <Popover
        // MUI modal is actually created outside of the container DOM
        // to remove it from web content capture we need a separate element id for tracking
        // TODO use container props in global theme to force everything inside container DOM
        // https://chatgpt.com/share/8302c553-be8c-445d-a0a9-6bdb95442c70
        id={GOPIXIE_DRAWER_ELEM_ID}
        open={appPopoverIsOpen}
        anchorEl={anchorEl.current}
        onClose={props.disableQuickClose ? undefined : closeApp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // style={{ zIndex: 2147483647 }} // max z-index
        slotProps={{
          paper: {
            sx: {
              p: 2,
              borderRadius: 10,
              minHeight: 80,
              zIndex: 9002,
              background: `linear-gradient(to bottom right, ${alpha(theme.palette.primary.light, 0.1)}, ${alpha(theme.palette.secondary.light, 0.2)})`,
              backdropFilter: 'blur(10px)',
              height: layout.dialogHeight,
              width: layout.dialogWidth,
            }
          },
          root: {
            sx: {
              backdropFilter: props.backdropFilter,
            }
          }
        }}
        keepMounted
      >
        <Badge
          badgeContent={<IconButton onClick={closeApp}><CloseRounded /></IconButton>}
          sx={{
            height: '100%',
            width: '100%',
            '& .MuiBadge-badge': {
              right: '12px',
              top: '12px',
            }
          }}
        >
          <Box sx={{
            textOverflow: 'wrap',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
          }}
          >
            {props.children}
          </Box>
        </Badge>
      </Popover>
    </Draggable>
  </>;
}
