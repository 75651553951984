import { Box, Button, CircularProgress, CircularProgressProps, Drawer, Grid, IconButton, Stack, TextField, Typography, alpha, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Dictaphone, { isDictphoneAvailable } from "./SpeechInput";
import KeyboardVoiceRoundedIcon from '@mui/icons-material/KeyboardVoiceRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Pulse } from "../../../../components/animations";
import { IUserInput, KnowledgeBaseType, TCard, TMessage, TUserInputRequirement } from "../../../../../generated/gql/graphql";
import WebContentInput, { canUploadWebContent } from "../../../../components/input/WebContent";
import { Uploader } from '../../../../components/input/uploader';
import DatasetSelect from "../../../../components/pixie/param-inputs/datasetSelect";
import FileSelect from "../../../../components/pixie/param-inputs/fileSelect";
import ParamEditor from "../../../../components/pixie/ParamEditor";
import { useClientStore } from "../../../../hooks/ClientState";
import { useUserAndWorkspaceStore } from "../../../../hooks/UserAndWorkspaceStore";
import StopRoundedIcon from '@mui/icons-material/StopRounded';


function shouldHideChatInput(inputRequirement: TUserInputRequirement): boolean {
  return (Boolean(inputRequirement.choices?.length) && inputRequirement.choicesOnly)
    || canUploadWebContent(inputRequirement.webContent)
    || inputRequirement.knowledgeSelect;
}


export function AdditionalInputView(props: {
  inputRequirement: TUserInputRequirement,
  value: IUserInput,
  onChange: (chatInput: IUserInput) => void,
  onSubmit: (chatInput: IUserInput) => void,
  conversation?: TMessage[],
}): React.ReactElement {
  const [choices, setChoices] = useState<string[]>([]);
  // const [links, setLinks] = useState<string[]>([]);
  const theme = useTheme();
  const workspaceId = useUserAndWorkspaceStore(state => state.workspaceId);
  const appId = useClientStore(state => state.flowId);

  useEffect(() => {
    setChoices([]);
    // setLinks([]);
  }, [props.inputRequirement]);

  // useEffect(() => {
  //   props.onChange({ ...props.value, links })
  // }, [links]);

  const filesToMessage = (files: string[]) => files.length == 0 ? "Nothing to upload" : 'Uploaded: ' + files.join(', ')

  return <Box display='flex' maxHeight='100%' overflow='auto'>
    {/* {props.inputRequirement.questionnaire
      ? <Questionnaire
        questionnaire={props.inputRequirement.questionnaire}
        onSubmit={answers => {
          const newVal = structuredClone(props.value);
          newVal.questionnaireResponses = answers;
          props.onChange(newVal);
          props.onSubmit(newVal);
        }}
        message={props.conversation ? props.conversation[props.conversation.length - 1] : undefined}
      />
      : undefined
    } */}
    {props.inputRequirement.choices
      ? <Grid container spacing={2} justifyContent='center' p={2}>
        {props.inputRequirement.choicesMultiple
          ? <>
            {props.inputRequirement.choices.map((opt, j) =>
              <Grid item key={`opt-${j}`} xs={12} md={6} lg={4}>
                <Button
                  fullWidth
                  variant={choices.includes(opt) ? 'contained' : 'outlined'}
                  color={choices.includes(opt) ? "secondary" : "primary"}
                  onClick={() => {
                    const selected = choices.includes(opt)
                      ? choices.filter(item => item !== opt)
                      : [...choices, opt];
                    props.onChange({ ...props.value, message: selected.join(', '), choices: selected });
                    setChoices(selected);
                  }}
                  sx={{ pl: 3, pr: 3, borderRadius: 7 }}
                >
                  {opt}
                </Button>
              </Grid>
            )}
            {props.inputRequirement.choicesOnly
              ? <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  color="success"
                  disabled={choices.length == 0}
                  onClick={() => props.onSubmit({ ...props.value, message: choices.join(', '), choices })}
                >Submit</Button>
              </Grid>
              : undefined
            }

          </>
          : props.inputRequirement.choices.map((opt, j) =>
            <Grid item key={`opt-${j}`} xs={12} md={6} lg={4}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  props.onChange({ ...props.value, message: opt, choices: [opt] });
                  props.onSubmit({ ...props.value, message: opt, choices: [opt] });
                }}
                sx={{ pl: 3, pr: 3, borderRadius: 7 }}
              >
                {opt}
              </Button>
            </Grid>
          )
        }
      </Grid>
      : undefined
    }
    {props.inputRequirement.webContent && <WebContentInput
      webContentRequirement={props.inputRequirement.webContent}
      onSubmit={wc => props.onSubmit({ ...props.value, webContent: wc })}
    />
    }
    {props.inputRequirement.knowledgeSelect && <Box width='100%' display='flex' justifyContent='center'>
      <Uploader
        onChange={files => props.onChange({ ...props.value, message: filesToMessage(files) })}
        onSubmit={files => props.onSubmit({
          ...props.value,
          message: filesToMessage(Object.keys(files)),
          knowledgeBases: [
            ...(props.value.knowledgeBases || []).filter(kb => kb.knowledgeType !== KnowledgeBaseType.Document),
            ...Object.entries(files)
              .filter(([name, status]) => typeof status === 'number')
              .map(([name, status]) => ({ knowledgeType: KnowledgeBaseType.Document, id: status as number }))
          ]
        })}
      />
    </Box>
    }
    {props.inputRequirement.fileSelect && <FileSelect
      label="Select a file"
      fileId={props.value.fileId}
      onChange={fileId => props.onChange({ ...props.value, fileId })}
      onSubmit={(fileName, fileId) => {
        props.onChange({ ...props.value, message: `Selected ${fileName}`, fileId: fileId });
        props.onSubmit({ ...props.value, message: `Selected ${fileName}`, fileId: fileId });
      }}
    />
    }
    {props.inputRequirement.datasetSelect && <DatasetSelect
      label="Select dataset"
      datasetId={props.value.dataset}
      // when the app is running from editor (workspaceId exists), scope the upload to workspace
      // otherwise scope the upload to the app
      siteId={workspaceId || undefined}
      appId={workspaceId == null ? appId : undefined}
      onChange={v => {
        props.onChange({ ...props.value, dataset: v });
        props.onSubmit({ ...props.value, dataset: v });
      }} />}
    {props.inputRequirement.jsonDataSchema &&
      <Stack spacing={2} sx={{
        p: 2,
        width: '100%',
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}>
        <ParamEditor
          schema={props.inputRequirement.jsonDataSchema.dataSchema}
          value={props.value.jsonData}
          onChange={jsonData => props.onChange({ ...props.value, jsonData })}
          resetTrigger={props.inputRequirement.jsonDataSchema}
          dynamicDisabled
        />
        <Button
          variant="contained"
          onClick={() => props.onSubmit(props.value)}
        >Submit</Button>
      </Stack>
    }
    {/* {props.inputRequirement.linkUpload && <LinkInput
      links={links}
      onChange={setLinks}
    />
    } */}
    {/* case "DefaultContentSettings": return <ContentSettingsInput
      defaults={inputConfig}
      onSubmit={contentSettings => props.onSubmit({ ...props.value, contentSettings })}
    />
    case "SelectPageToEdit": return <Card sx={{ p: 3 }}>
      {pageInEdit
        ? <><Typography variant="subtitle1">Editing:</Typography><Typography>{pageInEdit.label || pageInEdit.id}</Typography></>
        : <Typography variant="subtitle1" color='error'>Please select the content you want to edit by double clicking it.</Typography>
      }
    </Card> */}
    {/* {props.inputRequirement.control
      ? <ControlCommandSelect control={props.inputRequirement.control} onSubmit={cmd => {
        props.onChange({ ...props.value, command: cmd });
        props.onSubmit({ ...props.value, command: cmd });
      }} />
      : undefined
    } */}
    {/* {props.inputRequirement.tuning
      ? <TuningInputView
        inputRequirement={props.inputRequirement.tuning}
        value={props.value.tuning || { choice: TuningOption.Skip }}
        onChange={i => props.onChange({ ...props.value, tuning: i })}
        onSumbit={i => props.onSubmit({ ...props.value, tuning: i })}
      />
      : undefined
    } */}
  </Box>
}

const CircularProgressWithIcon = (props: CircularProgressProps & { onClick: () => void }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress {...props} color="secondary" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton onClick={props.onClick}>
          <StopRoundedIcon color="secondary" />
        </IconButton>
      </Box>
    </Box>
  );
};


function shouldHideChatBox(inputRequirement: TUserInputRequirement): boolean {
  return inputRequirement.choicesOnly
    || inputRequirement.datasetSelect
    || inputRequirement.fileSelect
    || inputRequirement.knowledgeSelect
    || Boolean(inputRequirement.jsonDataSchema)
    || canUploadWebContent(inputRequirement.webContent);
}


export default function ChatInput(props: {
  inputRequirment: TUserInputRequirement,
  value: IUserInput,
  onChange: (chatInput: IUserInput) => void,
  onSubmit: (chatInput: IUserInput) => void,
  onCancel: () => void,
  processing?: boolean,
  disabled?: boolean,
  conversation?: (TMessage | TCard)[],
}): React.ReactElement {
  const [voiceDrawerOpen, setVoiceDrawerOpen] = React.useState(false);
  const [voiceInput, setVoiceInput] = React.useState('');
  const inputRef = React.useRef<HTMLDivElement>(null);

  const theme = useTheme();

  // const shouldShowInputBox = useCallback((req: TUserInputRequirement) => {
  //   // return !req.questionnaire && !req.retry && !req.tuning && !req.hideInput;
  //   return true;
  // }, []);

  useEffect(() => {
    if (!props.processing && !props.disabled && !shouldHideChatInput(props.inputRequirment)) {
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  }, [props, inputRef.current]);

  function isEmpty(userInput: IUserInput) {
    return !userInput.message?.trim()?.length;
  }

  function validateInput(userInput: IUserInput) {
    // switch (props.inputRequirment.validation) {
    //   case ChatInputValidation.Email:
    //     const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    //     return emailPattern.test(userInput.message);
    //   default: return true;
    // }
    return true;
  }

  const isInputValid = validateInput(props.value);

  const errorMessage = isInputValid
    ? undefined : "Please enter a valid email address."

  const sendButton = isInputValid && !isEmpty(props.value)
    ? <SendRoundedIcon
      color="secondary"
      fontSize="large"
      onClick={() => props.onSubmit(props.value)}
      sx={{ cursor: 'pointer' }}
    />
    : undefined

  const dictaphone = isDictphoneAvailable()
    ? <Dictaphone
      onChange={setVoiceInput}
      onStart={() => setVoiceDrawerOpen(true)}
      onEnd={() => {
        if (voiceInput.length > 0) {
          props.onChange({ ...props.value, message: `${props.value.message} ${voiceInput}` });
        }
        setVoiceDrawerOpen(false);
      }}
    />
    : <KeyboardArrowRightRoundedIcon fontSize="large" />;

  return <Stack spacing={2} sx={{
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  }}>
    {!props.processing && !props.disabled
      ? <AdditionalInputView
        inputRequirement={props.inputRequirment}
        value={props.value}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
      // conversation={props.conversation}
      />
      : <></>
    }

    {/* {props.inputRequirment.retry
      ? <Button
        size="large"
        variant="contained"
        onClick={() => props.onSubmit({ message: '' })}
      >
        <Typography variant="h5"><b>Retry</b></Typography>
      </Button>
      : undefined
    } */}
    {!shouldHideChatBox(props.inputRequirment) && <TextField
      disabled={props.disabled}
      value={props.value.message || ''}
      error={!isInputValid}
      helperText={errorMessage}
      onChange={e => props.onChange({ ...props.value, message: e.target.value })}
      onKeyDown={e => {
        if (!e.shiftKey && e.key === "Enter") {
          e.preventDefault();
          if (!props.processing) props.onSubmit(props.value);
        }
      }}
      inputRef={inputRef}
      fullWidth
      variant="outlined"
      multiline
      // sx={{
      //   '& .MuiInputBase-input': {
      //     p: 0,
      //     m: 0,
      //   },
      //   '& fieldset': {
      //     borderRadius: '40px',
      //   },
      //   p: '5px',
      //   display: shouldHideChatInput(props.inputRequirment) ? 'none' : 'inherit',
      // }}
      InputProps={{
        sx: {
          borderRadius: '40px',
          p: 1,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
        },
        startAdornment: dictaphone,
        endAdornment: props.processing
          ? <CircularProgressWithIcon size='2.5rem' onClick={props.onCancel} />
          : sendButton,
      }}
    />}
    <Drawer open={voiceDrawerOpen} onClick={() => setVoiceDrawerOpen(false)} anchor="bottom">
      <Stack spacing={3} sx={{
        p: { xs: 2, md: 3 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30vh',
      }}>
        <Typography>{voiceInput}</Typography>
        <Pulse><KeyboardVoiceRoundedIcon sx={{ fontSize: '3rem' }} /></Pulse>
      </Stack>
    </Drawer>
  </Stack>
}
