import { create } from 'zustand'
import { createJSONStorage, devtools, persist, subscribeWithSelector } from 'zustand/middleware'
import { ApolloClient, ApolloError } from '@apollo/client';
import { IEditorSettings, IShortcut, PluginConstructType, PluginType, Site, TUser } from '../../generated/gql/graphql';
import { CURRENT_USER, GET_EDITOR_SETTINGS, GET_SITES } from '../graphql/query';
import { computed } from '../utils/zustand-computed';
import { removeTypename } from '../utils/removeTypename';

export interface UserAndWorkspaceStore {
  user: TUser | null,
  editorSettings: IEditorSettings,
  workspaceId: string | null,
  workspaces: Site[],
  getShortcuts: () => IShortcut[],
}

const defaultShortcuts: IShortcut[] = [
  { pluginType: PluginType.AwaitMessageInput },
  { pluginType: PluginType.DisplayMessage },
  { pluginType: PluginType.AiChat },
  { pluginType: PluginType.GetConversation },
  { pluginType: PluginType.AiComplete },
  { pluginConstructorType: PluginConstructType.AiConvert },
  { pluginConstructorType: PluginConstructType.AiChoose },
  { pluginType: PluginType.Condition },
]

export const defaultEditorSettings: IEditorSettings = {
  shortcuts: defaultShortcuts
}

const defaultIdentity = {
  user: null,
  editorSettings: defaultEditorSettings,
  workspaceId: null,
  workspaces: [],
}

export interface UserAndWorkspaceLoader {
  loadingStatus: {
    user: boolean,
    workspaces: boolean,
    editorSettings: boolean,
  },
  loadError: {
    user: ApolloError | null,
    workspaces: ApolloError | null,
    editorSettings: ApolloError | null,
  },
  isLoading: () => boolean,
  loadIdentity(client: ApolloClient<object>, loadWorkspaces?: boolean): Promise<void>,
  loadEditorSettings(client: ApolloClient<object>): Promise<void>,
}

export const useUserAndWorkspaceStore = create<UserAndWorkspaceStore & UserAndWorkspaceLoader>()(
  devtools(persist(subscribeWithSelector((set, get) => ({
    getShortcuts: () => {
      let shortcuts = get().editorSettings?.shortcuts;
      return shortcuts?.length ? shortcuts : defaultShortcuts;
    },
    loadIdentity: async (client: ApolloClient<object>, shouldLoadWorkspaces: boolean) => {
      if (await loadUser(client, get, set)) {
        await loadEditorSettings(client, get, set);

        if (shouldLoadWorkspaces) {
          await loadWorkspaces(client, get, set)
        }
      }
    },
    loadEditorSettings: async (client) => {
      await loadEditorSettings(client, get, set);
    },
    loadingStatus: {
      user: false,
      workspaces: false,
      editorSettings: false,
    },
    isLoading: () => {
      return get().loadingStatus.user || get().loadingStatus.workspaces || get().loadingStatus.editorSettings;
    },
    loadError: {
      user: null,
      workspaces: null,
      editorSettings: null,
    },
    error: undefined,
    ...defaultIdentity,
  })),
    {
      name: 'go-pixie-identity-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ))
)

async function loadEditorSettings(
  client: ApolloClient<object>,
  get: () => UserAndWorkspaceLoader & UserAndWorkspaceStore,
  set: (updates: Partial<UserAndWorkspaceLoader & UserAndWorkspaceStore>) => void,
): Promise<void> {
  set({ loadingStatus: { ...get().loadingStatus, editorSettings: true } });
  const { data: loadEditorSettings, error: loadEditorSettingsError } = await client.query({
    query: GET_EDITOR_SETTINGS,
    fetchPolicy: 'no-cache',
  });
  if (loadEditorSettingsError) {
    set({ loadError: { ...get().loadError, editorSettings: loadEditorSettingsError } });
  }
  else if (loadEditorSettings.editorSettings?.shortcuts?.length) {
    set({ editorSettings: removeTypename(loadEditorSettings.editorSettings) });
  }
  else {
    set({ editorSettings: defaultEditorSettings });
  }
  set({ loadingStatus: { ...get().loadingStatus, editorSettings: false } });
}

async function loadWorkspaces(
  client: ApolloClient<object>,
  get: () => UserAndWorkspaceLoader & UserAndWorkspaceStore,
  set: (updates: Partial<UserAndWorkspaceLoader & UserAndWorkspaceStore>) => void,
): Promise<void> {
  set({ loadingStatus: { ...get().loadingStatus, workspaces: true } });
  const { data: loadWorkspace, error: loadWorkspaceError } = await client.query({
    query: GET_SITES,
    fetchPolicy: 'no-cache',
  });
  if (loadWorkspaceError) {
    set({
      loadError: { ...get().loadError, workspaces: loadWorkspaceError },
      workspaceId: null,
      workspaces: [],
    });
  } else {
    const currentWorkspaceId = get().workspaceId;
    const workspaceId = loadWorkspace.sites.find(s => s.id === currentWorkspaceId) ? currentWorkspaceId : loadWorkspace.sites[0].id || null;
    set({ workspaces: loadWorkspace.sites, workspaceId });
  }
  set({ loadingStatus: { ...get().loadingStatus, workspaces: false } });
}

async function loadUser(
  client: ApolloClient<object>,
  get: () => UserAndWorkspaceLoader & UserAndWorkspaceStore,
  set: (updates: Partial<UserAndWorkspaceLoader & UserAndWorkspaceStore>) => void,
): Promise<TUser | null> {
  set({ loadingStatus: { ...get().loadingStatus, user: true } });
  const { data: loadUser, error: loadUserError } = await client.query({
    query: CURRENT_USER,
    fetchPolicy: 'no-cache',
  });
  if (loadUserError) {
    set({ loadError: { ...get().loadError, user: loadUserError } });
    set({ loadingStatus: { ...get().loadingStatus, user: false } });
    return null;
  }
  set({ user: loadUser.user || null, workspaces: [], workspaceId: null });
  set({ loadingStatus: { ...get().loadingStatus, user: false } });
  return loadUser.user;
}
