import "regenerator-runtime/runtime";
import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import KeyboardVoiceRoundedIcon from '@mui/icons-material/KeyboardVoiceRounded';

export function isDictphoneAvailable() {
  const {
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition();
  return browserSupportsSpeechRecognition && isMicrophoneAvailable && navigator.onLine && navigator.mediaDevices;
}

export default function Dictaphone(props: {
  onChange: (txt: string) => void,
  onStart?: () => void,
  onEnd?: () => void,
}): React.ReactElement {
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition();

  useEffect(() => {
    if (listening && props.onStart) props.onStart();
    if (!listening && props.onEnd) props.onEnd();
  }, [listening]);

  useEffect(() => {
    if (props.onChange) props.onChange(transcript);
  }, [transcript]);

  if (!isDictphoneAvailable()) return <></>;

  // if (!isMicrophoneAvailable) {
  //   return <Typography
  //     sx={{fontWeight: 'bold', cursor: 'pointer'}}
  //     onClick={() => {
  //       navigator.mediaDevices.getUserMedia({ audio: true });
  //     }}
  //   >Click here to enable voice input.</Typography>
  // }

  return <IconButton onClick={() => { SpeechRecognition.startListening(); }}>
    <KeyboardVoiceRoundedIcon fontSize="large" />
  </IconButton>
};
