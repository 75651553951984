import React, { createContext, useContext } from "react";
import { SiteDocument, TDatasetIdWIthName, TKnowledgeLink, TKnowledgeSitemap } from "../../generated/gql/graphql";
import { useQuery } from "@apollo/client";
import { GET_DATASETS, GET_DOCUMENTS, GET_KNOWLEDGE_LINKS, GET_KNOWLEDGE_SITEMAPS } from "../graphql/query";
import { AppContext } from "./AppContext";
import { useUserAndWorkspaceStore } from "../hooks/UserAndWorkspaceStore";

export const AssetsContext = createContext({
  documents: [] as SiteDocument[],
  refetchDocuments: () => { },
  datasets: [] as TDatasetIdWIthName[],
  refetchDatasets: () => { },
  knowledgeLinks: [] as TKnowledgeLink[],
  refetchKnowledgeLinks: () => { },
  knowledgeSitemaps: [] as TKnowledgeSitemap[],
  refetchKnowledgeSitemaps: () => { },
});


export default function SiteAssetsProvider(props: {
  siteId: string,
  children: React.ReactNode,
}): React.ReactElement {
  const user = useUserAndWorkspaceStore(state => state.user);

  const documentsQuery = useQuery(GET_DOCUMENTS, { variables: { siteId: props.siteId }, skip: !user });
  const datasetsQuery = useQuery(GET_DATASETS, { variables: { siteId: props.siteId }, skip: !user });
  const linksQuery = useQuery(GET_KNOWLEDGE_LINKS, { variables: { siteId: props.siteId }, skip: !user });
  const sitemapsQuery = useQuery(GET_KNOWLEDGE_SITEMAPS, { variables: { siteId: props.siteId }, skip: !user });


  return <AssetsContext.Provider value={{
    documents: documentsQuery.data?.documents || [],
    refetchDocuments: documentsQuery.refetch,
    datasets: datasetsQuery.data?.datasets || [],
    refetchDatasets: datasetsQuery.refetch,
    knowledgeLinks: linksQuery.data?.agetLinks || [],
    refetchKnowledgeLinks: linksQuery.refetch,
    knowledgeSitemaps: sitemapsQuery.data?.agetSitemaps || [],
    refetchKnowledgeSitemaps: sitemapsQuery.refetch,
  }}>
    {props.children}
  </AssetsContext.Provider>
}
