import { annotateDOM, filterDOM } from "../../utils/webContent";

export class DOMChangeTracker {
  private observer: MutationObserver;
  private changes: Set<Node> = new Set();
  private addedNodes: Set<Node> = new Set();  // Track completely new nodes separately

  constructor() {
    this.observer = new MutationObserver(this.handleMutations.bind(this));
  }

  startTracking(targetNode: Node = document.body) {
    this.changes.clear();
    this.addedNodes.clear();
    this.observer.observe(targetNode, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true
    });
  }

  stopTracking(): Node | null {
    this.observer.disconnect();
    return this.buildMinimalTree();
  }

  private handleMutations(mutations: MutationRecord[]) {
    mutations.forEach(mutation => {
      // Track the directly changed node
      this.changes.add(mutation.target);

      // Track added nodes and their content
      mutation.addedNodes?.forEach(node => {
        this.addedNodes.add(node);  // Mark as a completely new node
        this.changes.add(node);
      });

      // Track removed nodes' parents since the nodes themselves are no longer in DOM
      mutation.removedNodes?.forEach(node => {
        if (mutation.target) {
          this.changes.add(mutation.target);
        }
      });
    });
  }

  private buildMinimalTree(): Document {

    // If nothing changed, return an empty doc or null
    if (this.changes.size === 0) {
      return document.implementation.createHTMLDocument("");
    }

    // 1. Find all 'relevantNodes' by climbing ancestors of each changed node
    const relevantNodes = this.collectRelevantNodes();

    // 2. Create a brand-new Document
    const newDoc = document.implementation.createHTMLDocument("");
    annotateDOM(document.documentElement);
    // 3. Clone relevant subtrees of <body> into the newDoc.body
    this.cloneRelevantChildren(document.body, newDoc.body, relevantNodes);
    newDoc.documentElement.replaceChild(filterDOM(newDoc.body), newDoc.body);
    return newDoc;
  }

  /**
   * Gathers all changed nodes + all their ancestors.
   */
  private collectRelevantNodes(): Set<Node> {
    const relevant = new Set<Node>();
    // 'changes' includes any node that was mutated
    this.changes.forEach(node => {
      let current: Node | null = node;
      // Add node and climb upward through its parents (in the main DOM)
      while (current) {
        relevant.add(current);
        current = current.parentNode;
      }
    });
    return relevant;
  }

  /**
   * Recursively clone only those children that are in (or contain) relevant nodes.
   */
  private cloneRelevantChildren(origParent: Node, newParent: Node, relevant: Set<Node>) {
    // Go through all children in the original DOM
    origParent.childNodes.forEach(child => {
      // If this child or *any descendant* is relevant, we keep it
      if (this.containsRelevant(child, relevant)) {
        // Clone shallowly first (just the node itself, no subtree yet)
        const childClone = child.cloneNode(false);
        newParent.appendChild(childClone);

        // Recurse to copy only relevant sub-children
        this.cloneRelevantChildren(child, childClone, relevant);
      }
    });
  }

  /**
   * Check if 'node' itself or any of its descendants is in 'relevant'.
   */
  private containsRelevant(node: Node, relevant: Set<Node>): boolean {
    if (relevant.has(node)) return true;
    // Otherwise, check if any grandchild is relevant
    return Array.from(node.childNodes).some(child => this.containsRelevant(child, relevant));
  }

  // return minimized HTML with unchanged nodes stripped out, while keeping the structure
  getModifiedHTML(): string {
    const minimalTree = this.buildMinimalTree();
    return minimalTree.documentElement.outerHTML;
  }
}
