import React, { useEffect, useState } from 'react';
import { AppRun } from '../../../../hooks/useAppRun';
import { IUserInput } from '../../../../../generated/gql/graphql';
import {
  isMessage,
  isStatusMessage,
  useClientStore
} from '../../../../hooks/ClientState';
import {
  Box,
  CircularProgress, Stack, useTheme,
  Alert,
  Button,
  Typography
} from '@mui/material';
import { Pulse } from '../../../../components/animations';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Markdown from "../../../../components/material-markdown";
import ChatBubble from "./ChatBubble";
import ChatInput from './ChatInput';
import { useShallow } from 'zustand/react/shallow';

export function ChatView(props: AppRun & { width: string; }): React.ReactElement {
  const [input, setInput] = useState<IUserInput>({});
  const [
    interrupted,
    setInterrupted,
  ] = useClientStore(useShallow(state => [
    state.interrupted,
    state.setInterrupted,
  ]));
  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const [
    chatEntries, completed, initializing, inputRequirement,
  ] = useClientStore(useShallow(state => [
    state.chatEntries,
    state.completed,
    state.initializing,
    state.inputRequirement,
  ]));

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [chatEntries, props.inProgress, inputRequirement, completed]);


  return initializing
    ? <Box width={props.width} height='100%' display='flex' justifyContent='center' alignItems='center'>
      <CircularProgress size='5rem' />
    </Box>
    // TODO error display is needed when the flow cannot be initialized
    : <Stack spacing={2} sx={{
      display: 'flex',
      justifyContent: 'space-between',
      width: props.width,
      height: '100%',
      overflow: 'hidden',
    }}>
      <Stack ref={containerRef} spacing={2} sx={{
        p: 2,
        flexGrow: 1,
        overflowY: 'auto',
        //TODO hack - hide convo when questionnaire is present
        //need to handle hide/show convo more generically and gracefully
        // display: inputRequirement.questionnaire && !waitingForServer && !chatEnded ? 'none' : 'inherit',
      }}>

        {chatEntries.map(
          (entry, idx) => isStatusMessage(entry)
            ? <Box key={idx} maxWidth='100%' textOverflow='ellipsis' whiteSpace='pre-wrap' textAlign='center'>
              <Markdown>{entry.content}</Markdown>
            </Box>
            : isMessage(entry)
              ? <ChatBubble
                key={idx}
                message={entry} />
              : <Alert key={idx} severity='error'>{entry.name}: {entry.message}</Alert>
          // : <ChatCard key={idx} data={msgOrCard.card as TCard} />
        )}
        {props.inProgress ? <Box p={1}><Pulse><MoreHorizIcon color='disabled' /></Pulse></Box> : undefined}
      </Stack>
      {completed
        ? <Box width='100%' p={1} display='flex' justifyContent='center' alignItems='center'>
          <Typography variant='subtitle1' color={theme.palette.text.secondary}>Session has ended.</Typography>
        </Box>
        : interrupted
          ? <Button variant='outlined' color='secondary' sx={{ borderRadius: '40px' }} onClick={() => {
            setInterrupted(null);
            props.send([], () => setInput({}));
          }}><Typography variant='h6'>{interrupted === 'stop' ? 'Resume' : 'Try Again'}</Typography></Button>
          : <ChatInput
            processing={props.inProgress}
            disabled={props.inProgress}
            value={input}
            inputRequirment={inputRequirement || {
              choicesOnly: false,
              choicesMultiple: false,
              knowledgeSelect: false,
              datasetSelect: false,
              fileSelect: false,
              webInteractionOutcome: false,
            }}
            onChange={setInput}
            onSubmit={s => props.send([s], () => setInput({}))}
            onCancel={() => {
              props.disconnect(true);
              setInterrupted('stop');
            }} />}
    </Stack>;
}
