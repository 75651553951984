import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Paper, styled } from '@mui/material';

export const InlineCode = styled('span')({
  backgroundColor: '#f2f2f2',
  borderRadius: '3px',
  padding: '0.2em 0.4em',
  fontFamily: 'monospace',
});

const LinkRenderer = ({ ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />

const components = (variant) => {
  return {
    a: LinkRenderer,
    h1: ({ children }) => <Typography variant="h3" sx={{ pt: 2, pb: 2 }}>{children}</Typography>,
    h2: ({ children }) => <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>{children}</Typography>,
    h3: ({ children }) => <Typography variant="h5" sx={{ pt: 2, pb: 2 }}>{children}</Typography>,
    h4: ({ children }) => <Typography variant="h6" sx={{ pt: 2, pb: 2 }}>{children}</Typography>,
    h5: ({ children }) => <Typography variant="subtitle1" sx={{ pt: 2, pb: 2 }}>{children}</Typography>,
    h6: ({ children }) => <Typography variant="subtitle2" sx={{ pt: 2, pb: 2 }}>{children}</Typography>,
    p: ({ children }) => <Typography variant={variant}>{children}</Typography>,
    em: ({ children }) => <Typography variant={variant} component="em">{children}</Typography>,
    strong: ({ children }) => <Typography variant={variant} component="strong">{children}</Typography>,
    blockquote: ({ children }) => (
      <Paper elevation={3} sx={{ p: 2, m: 2 }}>
        <Typography variant="body2" component="blockquote">{children}</Typography>
      </Paper>
    ),
    ul: ({ children }) => <Typography variant={variant} component="ul">{children}</Typography>,
    ol: ({ children }) => <Typography variant={variant} component="ol">{children}</Typography>,
    li: ({ children }) => <Typography variant={variant} component="li">{children}</Typography>,
    //a: ({ href, children }) => <Link href={href}>{children}</Link>,
    //img: ({ src, alt }) => <img src={src} alt={alt} style={{ maxWidth: '100%' }} />,
    table: ({ children }) => <Table>{children}</Table>,
    thead: ({ children }) => <TableHead>{children}</TableHead>,
    tbody: ({ children }) => <TableBody>{children}</TableBody>,
    tr: ({ children }) => <TableRow>{children}</TableRow>,
    th: ({ children }) => <TableCell>{children}</TableCell>,
    td: ({ children }) => <TableCell>{children}</TableCell>,
    code: ({ children, inline }) => inline
      ? <InlineCode>{children}</InlineCode>
      : <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}><code>{children}</code></pre>
  }
};

const Markdown = (props: { variant?, children }) => {
  return (
    <ReactMarkdown components={components(props.variant || 'body1')}>{props.children}</ReactMarkdown>
  );
};

export function removeMarkdownCodeBlock(text: string): string {
  // This regex matches the ```markdown at the start and the ``` at the end
  const regex = /^```markdown\s+|\s+```$/g;
  const ret = text.replace(regex, '');
  return ret
}

export default Markdown;
