import { Alert, Card, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { IWebContent, TWebContentRequirement } from '../../../generated/gql/graphql';
import { AppContext } from '../../contexts/AppContext';
import { captureWebContent } from '../../utils/webContent';

export default function WebContentInput(props: {
  webContentRequirement: TWebContentRequirement,
  onSubmit: (webContent: IWebContent) => void,
}): React.ReactElement {
  const theme = useTheme();
  const { setError } = useContext(AppContext);

  useEffect(() => {
    if (!canUploadWebContent(props.webContentRequirement)) return;
    const tm = setInterval(async () => {
      const content = await captureWebContent();
      props.onSubmit(content);
    }, props.webContentRequirement.delay * 1000);
    return () => clearInterval(tm);
  }, [props.webContentRequirement]);



  return canUploadWebContent(props.webContentRequirement) ?
    <Card sx={{ p: 2, width: '100%' }}>
      <Stack direction='row' spacing={1} justifyContent='center' display='flex'>
        <CircularProgress />
        <Typography>Waiting for web content at <a target='blank' href={window.location.href}>{window.location.href}</a></Typography>
      </Stack>
    </Card>
    : <Alert severity="warning">Auto-upload web content is only available with chrome extension. Please manually submit html content.</Alert>;
}

//TODO this might no longer be correct
export function canUploadWebContent(req: TWebContentRequirement | null | undefined): boolean {
  return Boolean(req && chrome?.runtime);
}
