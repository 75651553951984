export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

export function arrayToObject<T>(arr: T[], key: string | string[]): { [key: string]: T } {
  const keys = typeof key === 'string' ? [key] : key;
  const getKey = item => {
    let ret = item;
    for (const k of keys) ret = ret[k];
    return ret;
  }

  return arr.reduce((obj, item) => ({ ...obj, [getKey(item)]: item }), {});
}
