import React, { useContext, useState } from 'react';
import { Select, MenuItem, Stack, Button, Dialog } from '@mui/material';
import { Uploader } from '../../input/uploader';
import { AssetsContext } from '../../../contexts/AssetsContext';
import { SignInRequired } from '../../Auth';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { KnowledgeBaseType, TKnowledgeBase } from '../../../../generated/gql/graphql';
import { snakeToCamel } from '../../../utils/snakeToCamel';


export default function KnowledgeBaseSelect(props: {
  name?: string,
  // important: do not include __typename
  value?: TKnowledgeBase,
  onChange: (knowledgeBase: TKnowledgeBase) => void,
}): React.ReactElement {
  const { documents, knowledgeLinks, knowledgeSitemaps } = useContext(AssetsContext);

  const getSecondarySelect = () => {
    if (!props.value?.knowledgeType) return undefined;

    const getMenuItems = () => {
      switch (props.value?.knowledgeType) {
        case KnowledgeBaseType.Document:
          return documents.map(d => <MenuItem key={d.documentId} value={d.documentId}>{d.name}</MenuItem>);
        case KnowledgeBaseType.Link:
          return knowledgeLinks.map(d => <MenuItem key={d.id} value={d.id}>{d.url}</MenuItem>);
        case KnowledgeBaseType.Sitemap:
          return knowledgeSitemaps.map(d => <MenuItem key={d.id} value={d.id}>{d.url}</MenuItem>);
        default: return undefined;
      }
    }

    return <Select
      value={props.value?.id}
      onChange={e => props.onChange({ ...props.value!, id: +e.target.value })}
    >
      {getMenuItems()}
    </Select >
  }

  return <Stack spacing={1}>
    <Select
      placeholder='Select Knowledge Base Type'
      value={props.value?.knowledgeType || ''}
      onChange={e => {
        if (e.target.value) {
          props.onChange({ knowledgeType: e.target.value as KnowledgeBaseType, id: -1 })
        }
      }}
    >
      {Object.values(KnowledgeBaseType).map(t => <MenuItem value={t} key={t}>{snakeToCamel(t.toLowerCase())}</MenuItem>)}
    </Select >
    {getSecondarySelect()}
  </Stack>
}


export function DocumentCta(): React.ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const { refetchDocuments } = useContext(AssetsContext);

  return <>
    <SignInRequired message='sign in to add document'>
      <Button color='secondary'
        variant='outlined'
        onClick={() => setModalOpen(true)}
      >
        <AddRoundedIcon />
      </Button>
    </SignInRequired>
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth='md' fullWidth>
      <Uploader onChange={() => { }} onSubmit={() => {
        refetchDocuments();
        setModalOpen(false);
      }} />
    </Dialog>
  </>
}
