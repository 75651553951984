import React, { useCallback, useContext } from "react";
import { RoleV2, TMessage } from "../../../../../generated/gql/graphql";
import { Box, Button, Card, Fade, Stack, Typography, lighten, useTheme } from "@mui/material";
import { Pulse } from "../../../../components/animations";
import Markdown from "../../../../components/material-markdown";
import { ItentoAppContext } from "../../../../contexts/ItentoAppContext";

interface ChatBubbleProps {
  message: TMessage,
  incomplete?: boolean,
  fade?: boolean,
  isTask?: boolean,
  debug?: boolean,
}

const ChatBubble = React.forwardRef<HTMLDivElement, ChatBubbleProps>(({
  message,
  incomplete = false,
  fade = true,
  isTask,
  debug,
}, ref) => {
  const theme = useTheme();
  const { setTaskProgressionOpen } = useContext(ItentoAppContext);

  const isUser = message.role == RoleV2.User;

  const chatBubble = <Card sx={{
    background: isUser
      ? `linear-gradient(to bottom right, ${lighten(theme.palette.secondary.main, 0.8)}, ${lighten(theme.palette.secondary.main, 0.7)})`
      : theme.palette.background.default,
    minHeight: '1.5rem',
    minWidth: '3rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    borderBottomLeftRadius: isUser ? undefined : "0px",
    borderBottomRightRadius: isUser ? "0px" : undefined,
    p: 2,
    border: `1px solid ${theme.palette.divider}`,
  }}>
    {isTask
      ? <Box>
        {/* {incomplete ? <CircularProgress /> : <CheckIcon />} */}
        {message.content}
        <Button variant="text" onClick={() => setTaskProgressionOpen(true)}>See Details</Button>
      </Box>
      : <Box sx={{ width: '100%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        <Markdown>{message.content}</Markdown>
        {incomplete
          ? <Pulse><Typography>{'...'}</Typography></Pulse>
          : <></>
        }
      </Box>
    }
  </Card>

  const spacer = <Box sx={{ width: { xs: 2, md: '14rem' } }} />

  const isDisplayableMessage = useCallback((msg: TMessage) => {
    return [RoleV2.User, RoleV2.Assistant].includes(msg.role);
  }, []);

  if (!debug && !isDisplayableMessage(message)) return <></>

  return <Fade in={true} timeout={fade ? 500 : 0}>
    <Stack direction='row' ref={ref} spacing={2} sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: isUser ? 'flex-end' : 'flex-start',
    }}>
      {isUser
        ? <>{spacer}{chatBubble}</>
        : <>{chatBubble}{spacer}</>
      }
    </Stack>
  </Fade>;
}
);

export default ChatBubble;
