import { AccessPathSegment, DynamicValue, DynamicValueParam, isComposite, isSimple, parseDistance } from "../types/DynamicValueTypes";
import { EditorState, useEditorStore } from './EditorState';
import { TFlowPluginV2 } from "../../generated/gql/graphql";
import { useMemo } from "react";
import { getDynamicValue, getSchema } from "../utils/dynamic-value-utils";
import Ajv, { ErrorObject } from 'ajv';
import { useShallow } from "zustand/react/shallow";
import { JSONSchema7Definition } from "json-schema";


type ValidationError = {
  refNodeId?: string,
  accessPath: AccessPathSegment[],
  message: string,
  errorObject?: ErrorObject,
  targetSchema?: JSONSchema7Definition,
}


function getDataReferences(dynamicValue: DynamicValueParam | undefined): DynamicValue[] {
  if (!dynamicValue) {
    return [];
  }
  if (isSimple(dynamicValue)) {
    return [dynamicValue];
  }
  else if (isComposite(dynamicValue)) {
    const references: DynamicValue[] = [];
    Object.values(dynamicValue.props).forEach(val => {
      references.push(...getDataReferences(val));
    });
    return references;
  }
  else {
    return dynamicValue.filter(val => typeof val !== 'string') as DynamicValue[];
  }
}


function getAccessPathForError(e: ErrorObject): AccessPathSegment[] {
  const dataSegments = e.instancePath.split('/'); // data path start with leading /
  const schemaSegments = e.schemaPath.split('/').slice(1, -1); // remove the # at the beginning and keyword at the end
  const accessPath: AccessPathSegment[] = [];
  let dataSegIdx = 0;
  for (let schemaSegIdx = 0; schemaSegIdx < schemaSegments.length; schemaSegIdx++) {
    switch (schemaSegments[schemaSegIdx]) {
      case 'properties':
        dataSegIdx++;
        schemaSegIdx++;
        accessPath.push({ type: 'property', value: schemaSegments[schemaSegIdx] });
        break;
      case 'items':
        dataSegIdx++;
        accessPath.push({ type: 'arrayIndex', value: parseInt(dataSegments[dataSegIdx]) });
        break;
      case 'anyOf':
        schemaSegIdx++;
        accessPath.push({ type: 'typeSelect', value: parseInt(schemaSegments[schemaSegIdx]) });
        break;
    }
    if (schemaSegments[schemaSegIdx] === 'properties') {
      schemaSegments[schemaSegIdx + 1] = dataSegments[schemaSegIdx];
    }
  }
  if (e.keyword === 'required') {
    accessPath.push({ type: 'property', value: e.params.missingProperty });
  }
  return accessPath;
}



export function useNodeParamValidation(nodeId: string): ValidationError[] {
  const nodeData: TFlowPluginV2 | undefined = useEditorStore(useShallow(
    state => state.app.graph.nodes.find(n => n.id === nodeId)?.data
  ));
  const paramSchema = useEditorStore(
    state => getSchema(state.actions.graph.getTypeInfo()[nodeId]?.pluginInfo?.parameterSchema)
  );
  const references = getDataReferences(nodeData?.dynamicParams);
  const dataRefErrors = useDataReferenceValidation(nodeId, references);

  const validationErrors = useMemo(() => {
    const ret = []
    if (!nodeData?.params) {
      ret.push({
        accessPath: [],
        message: 'Parameters are not configured.',
        targetSchema: paramSchema,
      });
    }
    else if (paramSchema) {
      try {
        const ajv = new Ajv({ strict: false });
        const validate = ajv.compile(paramSchema);
        if (!validate(nodeData.params)) {
          for (const e of validate.errors || []) {
            const p = getAccessPathForError(e);
            if (!getDynamicValue(nodeData?.dynamicParams, p)) {
              ret.push({
                accessPath: p,
                message: e.message,
                errorObject: e,
                targetSchema: paramSchema,
              });
            }
          }
        }
      }
      catch (e) {
        // TODO schema compilation errors should not happen, these need to be fixed
        console.error('Plugin param schema compilation error', nodeId, e);
      }
    }
    return [...ret, ...dataRefErrors];
  }, [nodeData?.params, nodeData?.dynamicParams, paramSchema, dataRefErrors]);

  return validationErrors;
}


function getSubSchema(schema: JSONSchema7Definition, accessPath: AccessPathSegment[]): JSONSchema7Definition | undefined {
  let subSchema = getSchema(schema);
  for (const seg of accessPath) {
    if (!subSchema) {
      return undefined;
    }
    if (seg.type === 'property') {
      subSchema = getSchema(subSchema.properties?.[seg.value]);
    }
    else if (seg.type === 'arrayIndex') {
      subSchema = getSchema(subSchema.items);
    }
    else if (seg.type === 'typeSelect') {
      subSchema = getSchema(subSchema.anyOf?.[seg.value]);
    }
  }
  return subSchema;
}


function validateReferenceSchema(refNodeId: string, state: EditorState, accessPath: AccessPathSegment[]): ValidationError | undefined {
  const dataResultSchema = state.actions.graph.getTypeInfo()[refNodeId]?.pluginInfo?.dataResultSchema;
  const refSchema = getSubSchema(dataResultSchema, accessPath);
  if (!refSchema) {
    return {
      refNodeId,
      accessPath,
      message: `Property does not exist in the result of "${refNodeId}".`,
    };
  }
}


export function useDataReferenceValidation(nodeId: string, references: DynamicValue[]): ValidationError[] {
  const validationErrors = useEditorStore(useShallow(
    state => {
      const ret = [];
      for (const ref of references) {
        const maybeDistance = parseDistance(ref.reference);
        if (maybeDistance !== null) {
          const relativeNodeIds = state.actions.graph.getRelatedNodeIds(maybeDistance, nodeId);
          for (const refNodeId of relativeNodeIds) {
            const maybeError = validateReferenceSchema(refNodeId, state, ref.access_path);
            if (maybeError) {
              ret.push(maybeError);
            }
          }
          continue;
        }
        else {
          const maybeRefNode = state.app.graph.nodes.find(n => n.id === ref.reference);
          if (!maybeRefNode) {
            ret.push({
              refNodeId: ref.reference,
              accessPath: [],
              message: `Referenced function "${ref.reference}" does not exist in the graph.`,
            });
          }
          else {
            const maybeError = validateReferenceSchema(ref.reference, state, ref.access_path);
            if (maybeError) {
              ret.push(maybeError);
            }
          }
        }
      }
      return ret;
    }
  ));
  return validationErrors;
}
