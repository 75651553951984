import React from "react"
import { TPluginInfo } from "../../generated/gql/graphql"
import { FlowNodeData } from "./GraphNode"

export type AccessPathSegment = {
  type: 'property',
  value: string,
} | {
  type: 'arrayIndex' | 'typeSelect',
  value: number,
}

// This should match chatbot.framework.v2.schema.DynamicValue
// NOTE key needs to be in SNAKE case, unlike the strawberry types
export type DynamicValue = {
  reference: string,
  access_path: AccessPathSegment[],
  version_delta?: number,
  default?: any,
}

type ComplexDynamicValuePrecedence = 'latest' | 'order';

// This should match chatbot.framework.v2.schema.ComplexDynamicValue
// NOTE key needs to be in SNAKE case, unlike the strawberry types
export type ComplexDynamicValue = {
  values: DynamicValue[],
  precedence: ComplexDynamicValuePrecedence,
  default?: any,
}

// This should match chatbot.framework.v2.schema.CompositeDynamicValue
// NOTE key needs to be in SNAKE case, unlike the strawberry types
export type CompositeDynamicValue = {
  props: {
    [key: string]: CompositeDynamicValue | DynamicValue | FormattedDynamicValue | ComplexDynamicValue,
  }
  items_to_merge?: number[],
}

export type FormattedDynamicValue = (string | DynamicValue | ComplexDynamicValue)[];

export type DynamicValueParam = DynamicValue | CompositeDynamicValue | FormattedDynamicValue | ComplexDynamicValue | null;

export function isSimple(val: DynamicValueParam): val is DynamicValue {
  return val != null && 'access_path' in val;
}

export function isComplex(val: DynamicValueParam): val is ComplexDynamicValue {
  return val != null && 'values' in val;
}

export function isComposite(val: DynamicValueParam): val is CompositeDynamicValue {
  return val != null && 'props' in val;
}

export function toExpression(val: DynamicValue, includeTypeSelect?: boolean): string {
  let s = val.reference;
  for (const seg of val.access_path) {
    switch (seg.type) {
      case "property": s += `.${seg.value}`; break;
      case "arrayIndex": s += `[${seg.value}]`; break;
      case "typeSelect":
        if (includeTypeSelect) s += `<${seg.value}>`;
        break;
    }
  }
  return s
}

export interface WithFocusHandlers {
  onBlur?: (event: React.FocusEvent<any>) => void;
  onFocus?: (event: React.FocusEvent<any>) => void;
  onClick?: (event: React.MouseEvent<any>) => void;
}

export type DataReferences = { [id: string]: { info: TPluginInfo, data: FlowNodeData } }
export function parseDistance(input: string): number | null {
  const match = input.match(/^@(\d+)$/);
  return match ? parseInt(match[1], 10) : null;
}
