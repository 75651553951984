import Slider from "react-slick";
import React, { ComponentPropsWithRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'

const SLIDES_TO_SCROLL = 5

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollTo(emblaApi.selectedScrollSnap() - SLIDES_TO_SCROLL)
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollTo(emblaApi.selectedScrollSnap() + SLIDES_TO_SCROLL)
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}


const CustomArrow = (props: any) => {
  const { onClick, disabled, icon } = props;
  return <IconButton
    onClick={onClick}
    disabled={disabled}
    color='secondary'
  >
    {icon}
  </IconButton>
};

type ButtonProps = ComponentPropsWithRef<typeof IconButton>

const CustomUpArrow = (props: ButtonProps) => <CustomArrow {...props} icon={<KeyboardArrowUpRoundedIcon fontSize="large" />} />
const CustomDownArrow = (props: ButtonProps) => <CustomArrow {...props} icon={<KeyboardArrowDownRoundedIcon fontSize="large" />} />

type VerticalCarouselProps<T> = {
  items: T[],
  renderItem: (item: T, index: number) => React.ReactNode,
  maxHeight: string | number,
  scrollToEndOnItemsChange?: boolean,
}

const verticalCarouselOptions: EmblaOptionsType = {
  axis: 'y',
  dragFree: true,
}


export function VerticalCarousel<T>(props: VerticalCarouselProps<T>) {
  const [emblaRef, emblaApi] = useEmblaCarousel(verticalCarouselOptions);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)
  const childrenCount = props.items.length;
  const prevChildrenCount = useRef(childrenCount);

  useEffect(() => {
    if (props.scrollToEndOnItemsChange && emblaApi && childrenCount !== prevChildrenCount.current) {
      prevChildrenCount.current = childrenCount;
      const t = setTimeout(() => emblaApi.scrollTo(childrenCount - 1), 100);
      return () => clearTimeout(t);
    }
  }, [childrenCount, emblaApi, props.scrollToEndOnItemsChange]);

  return <Stack>
    <Stack direction='row' spacing={2} justifyContent='center'>
      <CustomUpArrow onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      <CustomDownArrow onClick={onNextButtonClick} disabled={nextBtnDisabled} />
    </Stack>
    <Box ref={emblaRef} sx={{ overflow: 'hidden', userSelect: 'none' }}>
      <Stack maxHeight={props.maxHeight}>
        {props.items.map(props.renderItem).map((elem, idx) => {
          return <Box key={idx} sx={{
            flex: '0 0 100%',
            minHeight: 0,
            transform: "translate3d(0, 0, 0)",
          }}>{elem}</Box>
        })}
      </Stack>
    </Box>
  </Stack>
}
