import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Stack, Typography, useTheme, IconButton, TextField } from '@mui/material';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import { typeToString } from '../../utils/dynamic-value-utils';
import { DynamicValue, AccessPathSegment, parseDistance } from '../../types/DynamicValueTypes';
import { FlowNodeName } from '../../features/Pixie/Editor/FlowNodeName';

import CloseIcon from '@mui/icons-material/Close';
import { WithFocusHandlers } from '../../types/DynamicValueTypes';
import { logDebug } from '../../utils/logging';
import { useEditorStore } from '../../hooks/EditorState';
import { TypeString } from './common';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { DynamicValueDisplay } from './DynamicValueDisplay';
import JsonView from 'react18-json-view';
import { getSchemaDef } from '../../utils/dynamic-value-utils';
import intersectJsonSchemas from '../../utils/intersectJsonSchema';


export type DataRefAutoSuggestOption =
  // for autosuggest child properties
  {
    type: 'property',
    key: string,
    schemaDef: JSONSchema7Definition,
    value: DynamicValue,
  }
  // for array
  | { type: "arrayIndex" }
  // for autoguggest nodes as references
  | { type: 'reference', reference: string };


export function getDataRefAutoSuggestOptions(
  leafSchemaDef: JSONSchema7Definition | null | undefined,
  currentDataRef: string,
  currentAccessPath: AccessPathSegment[],
): DataRefAutoSuggestOption[] {
  if (!leafSchemaDef || typeof leafSchemaDef == 'boolean') return [];
  if (leafSchemaDef.properties) {
    return Object.entries(leafSchemaDef.properties).map(
      ([key, schemaDef]) => ({
        type: 'property',
        key,
        schemaDef,
        value: {
          reference: currentDataRef,
          access_path: [...currentAccessPath, { type: 'property', value: key }],
        }
      })
    )
  }
  else if (leafSchemaDef.items) {
    return [{ type: 'arrayIndex' }];
  }
  else if (leafSchemaDef.anyOf) {
    // TODO subtype selection and render properties according to selection
    return leafSchemaDef.anyOf.flatMap(
      (oneOfSchemaDef, idx) => getDataRefAutoSuggestOptions(
        oneOfSchemaDef,
        currentDataRef,
        [...currentAccessPath, { type: 'typeSelect', value: idx }],
      )
    )
  }
  return [];
}



function getAutoSuggestOptionElements(
  schemaDef: JSONSchema7Definition | null | undefined,
  currentDataRef: string,
  currentAccessPath: AccessPathSegment[],
  onDynamicValueChange: (v: DynamicValue) => void,
  includeHeader?: boolean,
): React.ReactNode | undefined {
  if (!schemaDef || typeof schemaDef == 'boolean') return undefined;
  if (schemaDef.properties) {
    return <>
      {includeHeader && <Typography variant='subtitle1'><b>Properties</b></Typography>}
      {Object.entries(schemaDef.properties).map(
        ([key, schemaDef]) => <AutosuggestPropertyItem
          key={key}
          schemaDef={schemaDef}
          onClick={() => onDynamicValueChange({
            reference: currentDataRef,
            access_path: [...currentAccessPath, { type: 'property', value: key }],
          })}
        >
          <Typography>{key}</Typography>
        </AutosuggestPropertyItem>
      )}
    </>
  }
  else if (schemaDef.items) {
    return <>
      {includeHeader && <Typography variant='subtitle1'><b>List Index</b></Typography>}
      <AutosuggestArrayIndexItem submit={(idx) => onDynamicValueChange({
        reference: currentDataRef,
        access_path: [...currentAccessPath, { type: 'arrayIndex', value: idx }]
      })} />
    </>
  }
  else if (schemaDef.anyOf) {
    // TODO subtype selection and render properties according to selection
    return <>
      {includeHeader && <Typography variant='subtitle1'><b>Properties</b></Typography>}
      {schemaDef.anyOf.map(
        (oneOfSchemaDef, idx) => <>{getAutoSuggestOptionElements(
          oneOfSchemaDef,
          currentDataRef,
          [...currentAccessPath, { type: 'typeSelect', value: idx }],
          onDynamicValueChange,
        )}</>
      )}
    </>
  }
  return undefined;
}

function AutosuggestArrayIndexItem(props: {
  submit: (idx: number) => void,
}): React.ReactElement {
  const [idx, setIdx] = useState(0);


  return <Stack direction='row' display='flex' alignItems='center'>
    <TextField
      type='number'
      value={idx}
      onChange={e => setIdx(Math.max(0, parseInt(e.target.value)))}
      sx={{ flexGrow: 1 }}
    />
    <IconButton color='success' disabled={idx < 0} onClick={() => props.submit(idx)}>
      <CheckRoundedIcon fontSize='small' />
    </IconButton>
  </Stack>
}

function AutosuggestPropertyItem(props: {
  schemaDef: JSONSchema7Definition | undefined,
  onClick: () => void,
  children: React.ReactNode,
}): React.ReactElement {
  const theme = useTheme();
  return <Stack
    direction='row'
    spacing={2}
    p={1}
    sx={{
      cursor: 'pointer',
      userSelect: 'none',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 1,
      '&:hover': {
        border: `1px solid ${theme.palette.secondary.dark}`,
        boxShadow: `1px 5px 5px ${theme.palette.divider}`,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}
    onClick={props.onClick}
  >
    <Typography component='div' minWidth='50%'>{props.children}</Typography>
    <TypeString>{typeToString(props.schemaDef)}</TypeString>
  </Stack>
}


export function useSchemaDefForReference(reference: string | undefined, dataRefType?: 'parameter' | 'result'): JSONSchema7 | undefined {
  const typeInfoCollection = useEditorStore(state => state.actions.graph.getTypeInfo());
  const referencedNodeIdsByDistance = useEditorStore(state => {
    if (!reference) return [];
    const distance = parseDistance(reference);
    return distance !== null ? state.actions.graph.getRelatedNodeIds(distance) : [];
  });

  if (reference === undefined) return undefined;

  const referencedNodeIds = referencedNodeIdsByDistance.length > 0 ? referencedNodeIdsByDistance : [reference];

  const getSchema = (reference: string) => dataRefType === 'parameter'
    ? typeInfoCollection[reference]?.pluginInfo?.parameterSchema
    : typeInfoCollection[reference]?.pluginInfo?.dataResultSchema;

  return intersectJsonSchemas(referencedNodeIds.map(getSchema)) || undefined;
}


export const DataRefAutocomplete = (props: Omit<DataRefPopoverProps, 'initialFocus'>) => {
  const theme = useTheme();
  const typeInfoCollection = useEditorStore(state => state.actions.graph.getTypeInfo());
  const refSchema = useSchemaDefForReference(props.dynamicValue?.reference, props.dataRefType);

  const leafSchemaDef = props.dynamicValue && getSchemaDef(refSchema, props.dynamicValue.access_path);
  const optionNodes = props.dynamicValue && getAutoSuggestOptionElements(
    leafSchemaDef,
    props.dynamicValue.reference,
    props.dynamicValue.access_path,
    props.onChange,
    true,
  );

  const defaultRef = useRef(props.dynamicValue?.default);

  useEffect(() => {
    defaultRef.current = props.dynamicValue?.default;
  }, [props.dynamicValue?.default])

  return <Stack spacing={1}>
    {!props.dynamicValue
      ? <>
        {/* <Typography variant='subtitle1'><b>Function Results</b></Typography> */}
        {/* temporary input for referencing nodes in the path */}
        <AutosuggestArrayIndexItem submit={(idx) => props.onChange({
          reference: `@${idx}`,
          access_path: [],
        })} />
        {Object.entries(typeInfoCollection).map(
          ([nodeId, typeInfo]) =>
            typeInfo.pluginInfo &&
            <AutosuggestPropertyItem
              key={nodeId}
              schemaDef={
                props.dataRefType === 'parameter'
                  ? typeInfo.pluginInfo.parameterSchema
                  : typeInfo.pluginInfo.dataResultSchema
              }
              onClick={() => props.onChange({
                reference: nodeId,
                access_path: [],
              })}
            >
              <FlowNodeName nodeId={nodeId} variant='description' />
            </AutosuggestPropertyItem>
        )}
      </>
      : <>
        <Typography variant='subtitle1'><b>Default Value</b></Typography>
        <TextField label='Version delta' type='number' value={props.dynamicValue.version_delta || 0} onChange={e => {
          props.onChange({ ...props.dynamicValue!, version_delta: parseInt(e.target.value) })
        }} />
        <JsonView
          src={structuredClone(props.dynamicValue.default)}
          enableClipboard={false}
          editable
          onEdit={c => {
            if (c.depth == 1 && 'newValue' in c && (c.parentType === null || c.parentType === undefined)) {
              props.onChange({ ...props.dynamicValue!, version_delta: 0, default: c.newValue })
            }
          }}
          onAdd={c => {
            props.onChange({ ...props.dynamicValue!, default: c.src })
          }}
          onDelete={c => {
            if (c.depth == 1) {
              const newV = structuredClone(props.dynamicValue!);
              delete newV.default;
              delete newV.version_delta;
              props.onChange(newV);
            }
          }}
        // onChange={c => {
        //   console.log(c);
        //   props.onChange({ ...props.dynamicValue!, default: c.src })
        // }}
        />
        {optionNodes}
      </>
    }
  </Stack>
};

// IMPORTANT: focus/blur of wrapped component will be partially managed by the HOC
// as result any refObject set in the props will be discarded
export function withDataRefPopover<P extends WithFocusHandlers>(WrappedComponent: React.ComponentType<P>) {
  const WithPopoverHandling: React.FC<P & { dataRefProps?: DataRefPopoverProps }> = (props) => {

    Object.entries(props)
      .filter(([k, v]) => k.toLowerCase().includes('ref') && v !== null && typeof v === 'object' && 'current' in v)
      .forEach(([k, v]) => console.warn(`withDataRefPopover will discard any refObject in props. Potentially discarded key: ${k}; value: ${v}`))

    const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    const popoverFocus = React.useRef(false);

    const innerRef = useRef<HTMLElement>(null);

    // we only set focus based on initialFocus once on initial mount.
    // NOTE no dependencies are taken thus only the initial value of innerRef (after mount),
    // as well as initialFocus and setInnerFocus in props will be used
    useEffect(() => {
      if (props.dataRefProps?.initialFocus) {
        logDebug('Focusing wrapped components inside WithDataRefPopover:');
        logDebug(WrappedComponent);
        if (typeof props.dataRefProps.setInnerFocus == 'function') {
          props.dataRefProps.setInnerFocus();
        }
        else if (innerRef.current) {
          innerRef.current.focus();
        }
      }
    }, []);

    const openPopover = () => {
      setPopoverOpen(true);
    };

    const closePopover = (reason: 'blur' | 'closeIcon') => {
      setPopoverOpen(false);
      popoverFocus.current = false;
      props.dataRefProps?.onPopoverClose?.(reason);
    };

    const closePopoverOnBlur = () => {
      setTimeout(() => {
        if (!popoverFocus.current) closePopover('blur');
      }, 50);
    }

    const typeInfo = useEditorStore(state => {
      const key = props.dataRefProps?.dynamicValue?.reference;
      if (!key) return undefined;
      return state.actions.graph.getTypeInfo()[key];
    });
    const currentDataRefSchemaDef = props.dataRefProps?.dynamicValue && getSchemaDef(
      typeInfo?.pluginInfo?.dataResultSchema,
      props.dataRefProps.dynamicValue.access_path
    )

    const autocomplete = props.dataRefProps && <DataRefAutocomplete {...props.dataRefProps} />;

    const popover = Boolean(autocomplete) && popoverOpen && <Box position='relative'>
      <Card
        tabIndex={0}
        elevation={5}
        onFocus={() => {
          popoverFocus.current = true;
        }}
        onBlur={() => {
          popoverFocus.current = false;
        }}
        sx={{
          zIndex: 1002,
          position: 'absolute',
          mt: 1,
          top: '100%',
          width: '100%'
        }}
      >
        <Stack m={2} spacing={2}>
          <Stack direction='row' display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>Reference Data ...</Typography>
            <IconButton onClick={() => closePopover('closeIcon')}><CloseIcon /></IconButton>
          </Stack>
          {props.dataRefProps?.dynamicValue &&
            <Stack spacing={1}>
              <DynamicValueDisplay dynamicValue={props.dataRefProps.dynamicValue} />
              <Box><TypeString>{typeToString(currentDataRefSchemaDef)}</TypeString></Box>
            </Stack>
          }
          {autocomplete}
        </Stack>
      </Card>
    </Box>

    const newProps = { ...props };
    delete newProps.dataRefProps;
    if (props.dataRefProps) {
      if (!props.dataRefProps.setInnerFocus) {
        newProps['ref'] = innerRef;
      }
      else if (typeof props.dataRefProps.setInnerFocus == 'string') {
        newProps[props.dataRefProps.setInnerFocus] = innerRef;
      }
    }
    //return props.getChildren(popOver, openPopover, closePopoverOnBlur);
    return <Stack>
      <WrappedComponent
        {...newProps}
        onFocus={e => {
          openPopover();
          props.onFocus?.(e);
        }}
        onBlur={e => {
          closePopoverOnBlur();
          props.onBlur?.(e);
        }}
        onClick={e => {
          openPopover();
          props.onClick?.(e);
        }}
      />
      {popover}
    </Stack>;
  };

  return WithPopoverHandling;
}

// function DynamicParamInputFieldInternal(props: DynamicParamInputProps & WithFocusHandlers): React.ReactElement {
//   const theme = useTheme();

//   const [focused, setFocused] = useState(false);

//   const reference = props.value?.reference && props.references?.[props.value.reference];

//   return <>
//     {!reference
//       ? <Button
//         variant='outlined'
//         sx={{
//           textTransform: 'none',
//           borderRadius: 25,
//         }}
//         onClick={e => {
//           props.onClick?.(e);
//           setFocused(false);
//         }}
//       >Choose reference</Button>
//       : <Stack
//         direction='row'
//         display='flex'
//         alignItems='center'
//         flexWrap='wrap'
//         tabIndex={0}
//         onFocus={e => {
//           props.onFocus?.(e);
//           setFocused(true);
//         }}
//         onBlur={e => {
//           props.onBlur?.(e);
//           setFocused(false);
//         }}
//       >
//         <FlowNodeName data={reference.data} pluginInfo={reference.info} variant='description' />
//         {(props.value?.access_path || []).map(
//           (seg, idx) => seg.type != 'typeSelect' && <Box key={idx} sx={{
//             cursor: 'default',
//             userSelect: 'none',
//             // '&:hover': {
//             //   textDecorationLine: 'underline',
//             //   textDecorationColor: theme.palette.secondary.main,
//             //   textDecorationThickness: 3,
//             //   textUnderlineOffset: '4px',
//             // }
//           }}>
//             <Typography p='1px' key={idx}>
//               <b>{seg.type == 'property' ? `.${seg.value}` : `[${seg.value}]`}</b>
//             </Typography>
//           </Box>
//         )}
//         {focused && <Pulse>
//           <Box m='2px' height='100%' sx={{
//             background: alpha(theme.palette.secondary.dark, 0.6),
//             whiteSpace: 'preserve',
//           }}><b>  </b></Box>
//         </Pulse>}
//       </Stack>}
//   </>
// }

// export const DynamicParamInputField = withDataRefPopover(DynamicParamInputFieldInternal);

export type DataRefPopoverProps = {
  dynamicValue: DynamicValue | null;
  // TODO use schema to show type mismatch warning
  // schema: JSONSchema7 | undefined;
  onChange: (dynamicValue: DynamicValue) => void;
  // whether the popover or wrapped input should be focused on initial render
  // note that focus/blur of the wrapped input will be managed by the wrapper
  initialFocus: boolean,
  // optional method to set focus on the wrapped component
  // if not provided, a ref will be passed to wrapped component with key 'ref'
  // and focus will be set by refObject.current.focus()
  // set to string to specify a different key (e.g. inputRef)
  // set to function to use customized logic for setting focus (e.g. for slate editor).
  setInnerFocus?: string | (() => void),
  onPopoverClose?: (reason: 'blur' | 'closeIcon') => void,
  dataRefType?: 'parameter' | 'result',  //default to result
}
