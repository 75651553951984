import React from 'react';
import { createRoot } from 'react-dom/client';
import { EmbeddedChatApp } from '../Client/Extension/EmbeddedChatApp';
import { defaultFont, defaultLayout, defaultPalette, initialClientState, useClientStore } from '../../../hooks/ClientState';

class GoPixieChatElement extends HTMLElement {
  private root: any;

  constructor() {
    super();
    this.root = null;
  }

  connectedCallback() {
    this.render();
  }

  static get observedAttributes() {
    // it is important the attributes are lowercase
    return [
      'appid',
      'dev',
      'primarycolor',
      'secondarycolor',
      'highlightcolor',
      'backgroundcolor',
      "fontfamily",
      "fontsize",
      "fabsize",
      "fabspacing",
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      if (['primarycolor', 'secondarycolor', 'highlightcolor', 'backgroundcolor'].includes(name)) {
        this.dispatchEvent(new CustomEvent('palettechange', { detail: this.getPalette() }));
      }
      if (['fontfamily', 'fontsize'].includes(name)) {
        this.dispatchEvent(new CustomEvent('fontchange', { detail: this.getFont() }));
      }
      if (['fabsize', 'fabspacing'].includes(name)) {
        this.dispatchEvent(new CustomEvent('fablayoutchange', { detail: this.getFabLayout() }));
      }
    }
    this.render();
  }

  // TODO actually read from database for this app rather than global default.
  getDefaultStyling() {
    return {
      palette: defaultPalette,
      font: defaultFont,
      layout: defaultLayout,
      openAfter: null,
      icon: null,
    };
  }

  getPalette() {
    const defaultStyling = this.getDefaultStyling();
    return {
      primary: this.getAttribute('backgroundcolor') || defaultStyling.palette.primary,
      accent1: this.getAttribute('primarycolor') || defaultStyling.palette.accent1,
      accent2: this.getAttribute('secondarycolor') || defaultStyling.palette.accent2,
      callToAction: this.getAttribute('highlightcolor') || defaultStyling.palette.callToAction,
    };
  }

  getFont() {
    const defaultStyling = this.getDefaultStyling();
    let fontSizeInt = parseInt(this.getAttribute('fontsize'));
    if (isNaN(fontSizeInt)) {
      fontSizeInt = defaultStyling.font.fontSize;
    }
    return {
      fontFamily: this.getAttribute('fontfamily') || defaultStyling.font.fontFamily,
      fontSize: fontSizeInt || defaultStyling.font.fontSize,
    };
  }

  getFabLayout() {
    const defaultLayout = this.getDefaultStyling().layout;
    return {
      ...defaultLayout,
      fabSize: this.getAttribute('fabsize') || defaultLayout.fabSize,
      fabSpacing: this.getAttribute('fabspacing') || defaultLayout.fabSpacing,
    };
  }

  render() {
    const flowId = this.getAttribute('appid');
    const dev = this.getAttribute('dev') === 'true';
    const state = useClientStore.getState();
    if (flowId !== state.flowId) {
      useClientStore.setState({ ...initialClientState, flowId });
    }
    if (!this.root) {
      this.root = createRoot(this);
    }
    this.root.render(<EmbeddedChatApp
      useLocalhost={dev}
      palette={this.getPalette()}
      font={this.getFont()}
      layout={this.getFabLayout()}
      onStylingLoaded={({ palette, font }) => {
        this.setAttribute('primarycolor', palette.accent1);
        this.setAttribute('secondarycolor', palette.accent2);
        this.setAttribute('highlightcolor', palette.callToAction);
        this.setAttribute('backgroundcolor', palette.primary);
        this.setAttribute('fontfamily', font.fontFamily);
        this.setAttribute('fontsize', font.fontSize.toString());
        this.setAttribute('fabsize', this.getFabLayout().fabSize);
        this.setAttribute('fabspacing', this.getFabLayout().fabSpacing);
      }}
    />);
  }
}

customElements.define('go-pixie-chat', GoPixieChatElement);
