import { graphql } from '../../generated/gql/gql';

export const GET_STYLING = graphql(`
query GetStyling($flowId: ID!){
  styling(flowId: $flowId) {
    palette {
      primary
      accent1
      accent2
      callToAction
    }
    font {
      fontFamily
      fontSize
    }
    layout {
      fabSize
      fabSpacing
      dialogWidth
      dialogHeight
      dialogDraggable
    }
    openAfter
    icon {
      lottieUrl
    }
  }
}
`);

export const GET_SITE = graphql(`
query GetSite($siteId: ID!){
  site(siteId: $siteId) {
    id
    title
    slug
    path
    navigationBar
    servicesTitle
    portfolioTitle
    socialMedia {
      facebook
      instagram
      twitter
      youtube
      tiktok
      linkedin
      github
      wechat
      xiaohongshu
    }
  }
}
`);


export const GET_SITE_IDS = graphql(`
query GetSiteIds{
  sites {
    id
  }
}
`);

export const GET_SITES = graphql(`
query GetSites{
  sites {
    id
    images {
      id
      url
      caption
    }
    navigationBar
    ownerId
    path
    portfolioTitle
    servicesTitle
    slug
      socialMedia {
        facebook
        github
        instagram
        linkedin
        tiktok
        twitter
        wechat
        xiaohongshu
        youtube
      }
    title
  }
}
`);

export const GET_INTRO = graphql(`
  query GetIntro($siteId: ID!){
    intro(siteId: $siteId) {
      id
      title
      subtitle
      description
      images {
        id
        url
        caption
      }
      buttons {
        label
        actions {
          __typename
          ... on OpenLink {
            newWindow
            url
          }
          ... on OpenDrawer {
            drawerName
          }
          ... on Route {
            path
          }
          ... on Subscribe {
            successLabel
          }
        }
      }
    }
  }
`);

export const GET_CONTACT = graphql(`
  query GetContact($siteId: ID!){
    contact(siteId: $siteId) {
      id
      title
      description
      location
      contactMethods {
        email
        phone
        subscription {
          label
          successLabel
        }
      }
      images {
        id
        url
        caption
      }
    }
  }
`);

export const GET_ABOUT = graphql(`
  query GetAbout($siteId: ID!){
    about(siteId: $siteId) {
      id
      title
      content {
        title
        body
      }
      images {
        id
        url
        caption
      }
    }
  }
`);

export const GET_SERVICES = graphql(`
  query GetServices($siteId: ID!){
    services(siteId: $siteId) {
      id
      title
      subtitle
      description
      details
      images {
        id
        url
        caption
      }
      buttons {
        label
        actions {
          __typename
          ... on OpenLink {
            newWindow
            url
          }
          ... on OpenDrawer {
            drawerName
          }
          ... on Route {
            path
          }
          ... on Subscribe {
            successLabel
          }
        }
      }
    }
  }
`);

export const GET_PORTFOLIO = graphql(`
  query GetPortfolio($siteId: ID!){
    portfolio(siteId: $siteId) {
      id
      title
      slug
      subtitle
      summary
      body
      images {
        id
        url
        caption
      }
    }
  }
`);

export const GET_PEOPLE = graphql(`
  query GetPeople($siteId: ID!){
    people(siteId: $siteId) {
      id
      title
      role
      biography
      images {
        id
        url
        caption
      }
    }
  }
`);


export const CURRENT_USER = graphql(`
  query CurrentUser{
    user {
      userId
      username
      email
      isDeveloper
    }
  }
`);

export const IS_SITE_OWNER = graphql(`
  query IsSiteOwner($siteId: ID!){
    isSiteOwner(siteId: $siteId)
  }
`);

export const GET_DOCUMENTS = graphql(`
query GetDocuments($siteId: ID!) {
  documents(siteId: $siteId) {
    documentId
    name
    description
  }
}
`);


export const GET_DATASETS = graphql(`
query GetDatasets($siteId: ID!, $flowId: ID) {
  datasets(siteId: $siteId, flowId: $flowId) {
    datasetId
    datasetName
  }
}
`);

export const GET_DATASET_DETAIL = graphql(`
query GetDatasetDetail($datasetName: ID!) {
  dataset(datasetId: $datasetName) {
    name
    description
    columnDescriptions {
      name
      value
    }
    columns {
      name
      value
    }
    rowCount
    samples {
      name
      value
    }
    format
  }
}
`);

// This is necessary for exporting the param input type enum in generated types
// even though we don't use this query anywhere
export const GET_PARAM_INPUT_TYPES = graphql(`
query GetParamInputTYpes {
  getParamInputTypes
}
`);

export const GET_AI_CONFIG_SCHEMA = graphql(`
query GetAiConfigSchema {
  getAiConfigSchema
}
`);


export const LIST_SECRETS = graphql(`
query ListSecrets($siteId: ID!) {
  alistSecrets(siteId: $siteId) {
    id
    type
    name
  }
}
`);

export const LIST_SECRET_TYPES = graphql(`
query ListSecretTypes {
  alistSecretTypes {
    type
    schema
  }
}
`);

// api v2

export const GET_FLOW_V2 = graphql(`
query GetFlowV2($flowId: ID!) {
  agetFlowV2(flowId: $flowId) {
    id
    name
    isPublic
    startNodeId
    aiConfig
    signinRequired
    defaultEditorView
    config {
      edges {
        sourceId
        targetId
        branchId
        params
        dynamicParams
      }
      plugins {
        id
        pluginType {
          static
          dynamic {
            constructType
            param
          }
        }
        params
        dynamicParams
        displayName
        inProgressMessage
      }
    }
    layout {
      id
      x
      y
    }
  }
}
`);

export const GET_STATIC_INFO = graphql(`
query ListPluginInfo {
  alistPluginInfo {
    pluginType {
      static
      dynamic {
        constructType
        param
      }
    }
    name
    description
    inDevelopment
    requiresAi
    requiresInput
    producesOutcome
    allowedBranches
    parameterSchema
    dataResultSchema
    categories
  }
  alistPluginConstructs {
    constructType
    name
    description
    inDevelopment
    requiresInput
    producesOutcome
    parameterSchema
    categories
  }
}`);


export const LIST_FLOWS = graphql(`
query ListFlows($siteId: ID!) {
  alistFlows(siteId: $siteId) {
    id
    name
  }
}
`);


export const GET_DYNAMIC_PLUGIN_INFO = graphql(`
query GetDynamicPluginInfo($config: IDynamicTypedPlugin!) {
  agetDynamicPluginInfo(config: $config) {
    pluginType {
      static
      dynamic {
        constructType
        param
      }
    }
    name
    description
    inDevelopment
    requiresAi
    requiresInput
    producesOutcome
    allowedBranches
    parameterSchema
    dataResultSchema
    categories
  }
}
`);


export const SEARCH_LABELED_RESULTS = graphql(`
query SearchLabeledResult($flowId: ID!, $pluginId: String!) {
  asearchLabeledResults(flowId: $flowId, pluginId: $pluginId) {
    flowId
    pluginId
    actualizedParam
    pluginResult
    label
    note
    created
  }
}
`);


export const LIST_WHATSAPP_WEBHOOKS = graphql(`
query ListWhatsappWebhooks($flowId: ID!) {
  alistWhatsappWebhooks(flowId: $flowId) {
    name
    webhookId
    whatsappAccountId
  }
}
`);


export const GET_KNOWLEDGE_LINKS = graphql(`
query GetKnowledgeLinks($siteId: ID!) {
  agetLinks(siteId: $siteId) {
    id
    url
    updated
    sitemapId
  }
}
`);


export const GET_KNOWLEDGE_SITEMAPS = graphql(`
query GetKnowledgeSitemaps($siteId: ID!) {
  agetSitemaps(siteId: $siteId) {
    id
    url
    updated
  }
}
`);

export const LIST_FILES = graphql(`
  query ListFiles($siteId: ID, $flowId: ID) {
    files(siteId: $siteId, flowId: $flowId) {
      id
      fileName
    }
  }
  `);

export const LIST_APP_TEMPLATES = graphql(`
  query ListAppTemplates($flowId: ID!) {
    alistAppTemplates(flowId: $flowId) {
      id
      title
      settingsNodes
    }
  }
`);

export const GET_APP_TEMPLATE_DETAILS = graphql(`
  query GetAppTemplateDetails($templateId: ID!) {
    agetAppTemplateDetails(templateId: $templateId) {
      title
      sourceAppId
      settings {
        nodeId
        name
        settingsSchema
      }
    }
  }
`);


export const GET_PAYMENT_DETAILS = graphql(`
  query GetPaymentDetails($siteId: ID!) {
    agetPaymentDetails(siteId: $siteId) {
      subscriptionPlan
      status
    }
  }
`);


export const GET_EDITOR_SETTINGS = graphql(`
  query GetEditorSettings {
    editorSettings {
      shortcuts {
        pluginType
        pluginConstructorType
      }
    }
  }
`);


export const ValidateApp = graphql(`
  query VALIDATE_APP($flow: IFlowConfig!) {
    avalidateFlow(flow: $flow) {
      pluginId
      constraints {
        status
        sourcePluginId
        isError
        isWarning
        message
      }
    }
  }
`);
