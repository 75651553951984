import { Stack, Typography } from '@mui/material'
import React from 'react'

export default function PixieIcon(props: {
  onClick?: () => void,
  iconOnly?: boolean,
}): React.ReactElement {

  return <Stack direction='row'
    display='flex'
    alignItems='center'
    sx={{ cursor: props.onClick ? 'pointer' : 'default', userSelect: 'none' }}
    onClick={props.onClick}
  >
    <img src='https://storage.googleapis.com/butterfly-static/gopixie.png' style={{
      height: 56,
      width: 56,
      paddingRight: 8,
    }} />
    {!props.iconOnly && <>
      <Typography variant='h4'><b>GoPixie.</b></Typography>
      <Typography variant='h4' color='secondary'><b>ai</b></Typography>
    </>}
  </Stack>
}
