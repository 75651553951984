import React, { useState } from 'react';
import { Stack, Box, Typography, Dialog, Button, Card, Collapse, MenuItem, Select, TextField, ToggleButtonGroup, ToggleButton, Checkbox, FormControlLabel, Switch } from '@mui/material';
import { JSONSchema7, JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema';
import { JSONInputField, ListEditView } from '../common';
import { DynamicValue } from '../../../types/DynamicValueTypes';
import { DataRefAutocomplete } from '../DataRefPopover';
import { getSchemaDef } from '../../../utils/dynamic-value-utils';
import { useEditorStore } from '../../../hooks/EditorState';
import { getSchema } from '../../../utils/dynamic-value-utils';


function getSchemaOrDefault(schemaDef: JSONSchema7Definition | undefined): JSONSchema7 {
  if (!schemaDef || typeof schemaDef == 'boolean') return { "type": "null" };
  return schemaDef;
}

function getSingle<T>(val: T | T[] | undefined): T | undefined {
  if (val === undefined) return undefined;
  if (Array.isArray(val)) return val[0];
  return val;
}

export default function JsonSchemaEditor(props: {
  schema: JSONSchema7,
  onChange: (schema: JSONSchema7) => void,
}): React.ReactElement {
  const [showOptional, setShowOptional] = useState(false);
  const schemaType = getSingle(props.schema.type) || 'null';

  const [dialogOpen, setDialogOpen] = useState(false);
  const [typeRef, setTypeRef] = useState<DynamicValue | null>(null);
  const typeInfos = useEditorStore(state => state.actions.graph.getTypeInfo());

  const [dataRefType, setDataRefType] = useState<'parameter' | 'result'>('parameter');
  const [editRaw, setEditRaw] = useState(false);

  return <Stack spacing={1}>
    <Button
      variant='outlined'
      size='small'
      sx={{ textTransform: 'none' }}
      onClick={() => {
        setDialogOpen(true)
      }}
    >
      <Typography variant='body2'>Choose a type from flowchart</Typography>
    </Button>
    <Dialog open={dialogOpen} onClose={() => {
      setDialogOpen(false);
      setTypeRef(null);
    }} maxWidth='md' fullWidth>
      <Stack p={2} spacing={2}>
        <Box display='flex' justifyContent='center'>
          <ToggleButtonGroup exclusive onChange={(e, v) => setDataRefType(v)}>
            <ToggleButton value='parameter' selected={dataRefType === 'parameter'}>Parameter</ToggleButton>
            <ToggleButton value='result' selected={dataRefType === 'result'}>Result</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <DataRefAutocomplete dynamicValue={typeRef} dataRefType={dataRefType} onChange={typeRef => {
          const info = typeInfos[typeRef.reference]?.pluginInfo;
          const resultSchema = dataRefType === 'parameter' ? info?.parameterSchema : info?.dataResultSchema;
          props.onChange(getSchema(getSchemaDef(resultSchema, typeRef.access_path)) || { type: 'null' });
          setTypeRef(typeRef);
        }} />
      </Stack>
    </Dialog>
    <FormControlLabel control={<Switch checked={editRaw} onChange={(_, checked) => setEditRaw(checked)} />} label='Edit Raw Text' />
    {editRaw
      ? <JSONInputField value={props.schema} onChange={v => props.onChange(v || {})} />
      : <>
        <Select
          label='Type'
          variant='standard'
          value={schemaType}
          onChange={e => {
            const newSchema = { ...props.schema, type: (e.target.value || undefined) as JSONSchema7TypeName | undefined };
            if (newSchema.type !== 'object') delete newSchema['properties'];
            if (newSchema.type !== 'array') delete newSchema['items'];
            props.onChange(newSchema);
          }}
        >
          {["string", "number", "integer", "boolean", "object", "array", "null"]
            .map(t => <MenuItem value={t} key={t}>{t}</MenuItem>)
          }
        </Select>
        {schemaType === 'object'
          ? <ListEditView
            name='Properties'
            values={Object.entries(props.schema.properties || {})
              .map(([name, schema]) => ({
                name,
                schema,
                required: (props.schema.required || []).includes(name),
              }))
            }
            itemComponent={({ name, schema: propSchema, required }, onPropChange) => <Stack>
              <TextField label='Name' variant='standard' value={name} onChange={e => onPropChange({
                name: e.target.value,
                schema: propSchema,
                required,
              })} />
              <FormControlLabel
                label="Required"
                control={<Checkbox checked={required} onChange={(_, checked) => onPropChange(
                  { name, schema: propSchema, required: checked }
                )} />}
              />
              <JsonSchemaEditor schema={getSchemaOrDefault(propSchema)} onChange={s => onPropChange({ name, schema: s, required })} />
            </Stack>}
            default={{
              name: '',
              schema: { type: 'null' },
              required: false,
            }}
            onChange={ps => props.onChange({
              ...props.schema,
              properties: ps.reduce((o, { name, schema: propSchema }) => ({ ...o, [name]: propSchema }), {}),
              required: ps.filter(p => p.required).map(p => p.name),
            })}
          />
          : undefined
        }
        {schemaType === 'array'
          ? <Card>
            <Stack spacing={1} p={1}>
              <Typography variant='subtitle2'>Item Type</Typography>
              <JsonSchemaEditor
                schema={getSchemaOrDefault(getSingle(props.schema.items))}
                onChange={s => props.onChange({ ...props.schema, items: s })}
              />
            </Stack>
          </Card>
          : undefined
        }
        <Box>
          <Typography
            variant='body2'
            color='text.secondary'
            onClick={() => setShowOptional(o => !o)}
            sx={{
              textTransform: 'capitalize',
              cursor: 'pointer',
              textAlign: 'center',
              ":hover": { textDecorationLine: 'underline' }
            }}
          >
            Optionals
          </Typography>
          <Collapse in={showOptional}>
            <Stack spacing={1}>
              <TextField
                label='Title' variant='standard'
                value={props.schema.title || ''}
                onChange={e => props.onChange({ ...props.schema, title: e.target.value })}
              />
              <TextField
                label='Description' variant='standard'
                value={props.schema.description || ''}
                onChange={e => props.onChange({ ...props.schema, description: e.target.value })} />
            </Stack>
          </Collapse>
        </Box>
      </>
    }

  </Stack>
}
