import { TWebPageInteraction, WebPageAction } from '../../generated/gql/graphql';

function takeActionAndWait(selector: string, event: string, act: (elem: Element) => void): Promise<void> {
  return new Promise((resolve, reject) => {
    const element = document.querySelector(selector);
    if (!element) {
      reject(new Error("Element not found."));
    }

    const cancelOnTimeout = setTimeout(() => {
      element.removeEventListener(event, handleEvent);
      reject(new Error("Action timed out."));
    }, 5000);

    const handleEvent = () => {
      element.removeEventListener(event, handleEvent);
      clearTimeout(cancelOnTimeout);
      setTimeout(() => {
        resolve();
      }, 200); // wait for the page to update with the small delay
    };

    element.addEventListener(event, handleEvent);
    act(element);
  });
}


function clickElementAndWait(selector: string): Promise<void> {
  return takeActionAndWait(selector, 'click', (elem) => {
    (elem as HTMLElement).click();
  });
}

function changeElementAndWait(selector: string, value: string): Promise<void> {
  return takeActionAndWait(selector, 'input', (elem) => {
    if (elem instanceof HTMLInputElement || elem instanceof HTMLTextAreaElement) {
      // Handle text input fields and text areas
      elem.value = value;
      elem.dispatchEvent(new Event('input', { bubbles: true }));
    } else if ((elem as HTMLElement).isContentEditable) {
      // Handle content-editable elements
      const lines = value.split('\n');
      const paragraphs = lines.map(line => `<p>${line}</p>`).join('');
      elem.innerHTML = paragraphs;
      elem.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
      throw new Error('Element must be a text input, textarea, or content-editable element.');
    }
  });
}

export async function makeWebPageInteraction(interaction: TWebPageInteraction): Promise<void> {
  // not observing changes for now
  // const changes = [];
  // const observer = new MutationObserver((mutations) => {
  //   mutations.forEach((mutation) => {
  //     console.log(mutation);
  //     changes.push(mutation);
  //   });
  // });

  // // Specify what to observe (all changes to the document)
  // const config = {
  //   childList: true,
  //   subtree: true,
  //   attributes: true,
  //   characterData: true
  // };

  // async function observeDocumentChanges(timeout: number) {
  //   // Start observing the document
  //   observer.observe(document, config);
  //   console.log('Started observing...');

  //   // Stop observing after 3 seconds
  //   return new Promise<MutationRecord[]>(resolve => setTimeout(() => {
  //     observer.disconnect();
  //     console.log('Stopped observing.');
  //     resolve(changes);
  //   }, timeout));
  // }

  switch (interaction.action) {
    case WebPageAction.Click:
      return await clickElementAndWait(interaction.selector);
    case WebPageAction.Change:
      return await changeElementAndWait(interaction.selector, interaction.actionInput);
  }
}
