
export function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item));
  } else if (obj !== null && typeof obj === 'object') {
    const { __typename, ...rest } = obj;
    for (const key in rest) {
      rest[key] = removeTypename(rest[key]);
    }
    return rest;
  }
  return obj;
}
