import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { ApolloClient, split, InMemoryCache, ApolloProvider } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { WebSocketConnectionContext } from '../../../../contexts/WebSocketConnectionContext';

export function ExternalGraphQLClientProvider({ httpUrl, wsUrl, authToken, children }): React.ReactElement {
  const [graphqlClient, setgraphqlClient] = useState<ApolloClient<any> | undefined>(undefined);
  const [hasClosed, setHasClosed] = useState(false);
  const [connectedTrigger, setConnectedTrigger] = useState(false);

  useEffect(() => {
    const httpLink = createUploadLink({
      uri: httpUrl,
      // since set-cookie doesn't work in content script, we need to pass the auth token in headers
      headers: authToken ? {
        'Authorization': `Bearer ${authToken}`,
      } : undefined,
    });

    const wsLink = new GraphQLWsLink(createClient({
      url: wsUrl,
      connectionParams: () => {
        return {
          'Authorization': `Bearer ${authToken}`
        };
      },
      on: {
        closed: () => setHasClosed(true),
        connected: () => setConnectedTrigger(t => !t),
      },
    }));

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );
    setgraphqlClient(new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
      assumeImmutableResults: true,
    }));
  }, [authToken, httpUrl, wsUrl]);

  return graphqlClient
    ? <ApolloProvider client={graphqlClient}>
      <WebSocketConnectionContext.Provider value={{ hasClosed, connectedTrigger }}>
        {children}
      </WebSocketConnectionContext.Provider>
    </ApolloProvider>
    : <LinearProgress />;
}
