import { shallow } from "zustand/shallow";

// borrowed from https://github.com/pmndrs/zustand/issues/108#issuecomment-2197556875
type EqualityFn<T> = (a: T, b: T) => boolean;
type EqualityFns<TDeps extends readonly unknown[]> = {
  [Item in keyof TDeps]?: EqualityFn<TDeps[Item]>;
};

export function computed<
  const TDeps extends readonly unknown[] = unknown[],
  const TParams extends readonly unknown[] = unknown[],
  TResult = unknown,
>(
  depsFn: () => TDeps,
  computeFn: (params: TParams, ...deps: TDeps) => TResult,
  equalityFns: EqualityFn<TDeps[number]> | EqualityFns<TDeps> = Object.is,
): (...params: TParams) => TResult {
  let cachedDeps: TDeps;
  let cachedParams: TParams;
  let cachedResult: TResult;
  return (...params) => {
    const deps = depsFn();
    if (
      cachedDeps === undefined ||
      !equalDependencies(cachedDeps, deps, equalityFns) ||
      !equalDependencies(cachedParams, params, shallow)
    ) {
      cachedDeps = deps;
      cachedParams = params;
      cachedResult = computeFn(params, ...deps);
    }
    return cachedResult;
  };
}

function equalDependencies<
  TDeps extends readonly unknown[] = readonly unknown[],
>(
  deps1: TDeps,
  deps2: TDeps,
  equalityFns: EqualityFn<TDeps[number]> | EqualityFns<TDeps>,
): boolean {
  return (
    deps1.length === deps2.length &&
    deps1.every((dep, i) => {
      const equalityFn =
        typeof equalityFns === "function"
          ? equalityFns
          : equalityFns[i] ?? Object.is;
      return equalityFn(dep, deps2[i]);
    })
  );
}
