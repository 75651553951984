import { useMutation, useQuery } from "@apollo/client/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ADD_DATASET, UPDATE_DATASET } from "../../graphql/mutation";
import { Alert, Button, Collapse, LinearProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { TDatasetDetail, TDatasetFormat, TDatasetIdWIthName } from "../../../generated/gql/graphql";
import { GET_DATASET_DETAIL } from "../../graphql/query";
import { InlineCode, TypeString } from "./common";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { AppContext } from "../../contexts/AppContext";
import { AssetsContext } from "../../contexts/AssetsContext";


export function DatasetEditor(props: {
  datasetIdWithName?: TDatasetIdWIthName,
  siteId?: string,
  appId?: string,
  onSubmit: (datasetId: string) => void,
}): React.ReactElement {
  const { setError, setSuccessMessage } = useContext(AppContext);

  const [addDataset] = useMutation(ADD_DATASET);
  const [updateDataset] = useMutation(UPDATE_DATASET);
  const { refetchDatasets } = useContext(AssetsContext);
  const [name, setName] = useState<string>(props.datasetIdWithName?.datasetName || '');
  const [description, setDescription] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [savedDataset, setSavedDataset] = useState<TDatasetDetail | null>(null);
  const [showColumns, setShowColumns] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setName(props.datasetIdWithName?.datasetName || '');
  }, [props.datasetIdWithName?.datasetName]);

  useQuery(GET_DATASET_DETAIL, {
    variables: { datasetName: props.datasetIdWithName?.datasetId },
    onError(error) {
      setError(error);
    },
    onCompleted(data) {
      setSavedDataset(data.dataset);
    },
    skip: !props.datasetIdWithName,
  });

  useEffect(() => {
    if (savedDataset) {
      setName(savedDataset.name);
      setDescription(savedDataset.description || '');
    }
  }, [savedDataset]);

  function createDataset() {
    setUploadInProgress(true);
    addDataset({
      variables: {
        file,
        siteId: props.siteId,
        flowId: props.appId,
        name,
        description,
      }
    })
      .then(res => {
        if (res.errors) setError(res.errors[0]);
        else {
          setSuccessMessage(`Dataset ${name} created.`);
          props.onSubmit(res.data.aaddDataset);
        }
        refetchDatasets();
      })
      .catch(e => setError(JSON.stringify(e)))
      .finally(() => setUploadInProgress(false));
  }

  function modifyDataset() {
    if (!props.datasetIdWithName.datasetId) return;
    setUploadInProgress(true);
    updateDataset({
      variables: {
        datasetId: props.datasetIdWithName.datasetId,
        name,
        file,
        description,
        columnDescriptions: [],
      }
    })
      .then(res => {
        if (res.errors) setError(res.errors[0]);
        else {
          setSuccessMessage(`Dataset ${name} created.`);
          // TODO update implementation and return value
          props.onSubmit(res.data.updateDataset.toString());
        }
        refetchDatasets();
      })
      .catch(e => setError(JSON.stringify(e)))
      .finally(() => setUploadInProgress(false));
  }
  return <Stack spacing={2} p={3}>
    {props.datasetIdWithName
      ? <Typography variant="h5">Edit Dataset <b>{props.datasetIdWithName.datasetName}</b></Typography>
      : <Typography variant='h5'><b>Create Dataset</b></Typography>
    }
    {savedDataset || !props.datasetIdWithName
      ? <>
        <TextField label="Name" value={name} onChange={e => setName(e.target.value)} error={name == ''} />
        <TextField label="Description" multiline minRows={4} value={description} onChange={e => setDescription(e.target.value)} />
        {
          savedDataset
            ? <>
              <Typography variant="h6">{savedDataset.rowCount} rows</Typography>
              <Typography
                variant="h6"
                onClick={() => setShowColumns(s => !s)}
                sx={{ cursor: "pointer", display: "flex", alignItems: 'center' }}
              >
                {showColumns
                  ? <ExpandLessRoundedIcon />
                  : <ExpandMoreRoundedIcon />}
                Column Information
              </Typography>
              <Collapse in={showColumns}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Column Name</TableCell>
                      <TableCell>Data Type</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {savedDataset.columns.map((col, idx) => <TableRow key={idx}>
                      <TableCell><InlineCode>{col.name}</InlineCode></TableCell>
                      <TableCell><TypeString>{col.value}</TypeString></TableCell>
                      <TableCell>{savedDataset.columnDescriptions.find(d => d.name == col.name)?.value}</TableCell>
                    </TableRow>)}
                  </TableBody>
                </Table>
              </Collapse>
            </>
            : file
              ? <Typography variant="h5">{file.name}</Typography>
              : <Alert color='error'>No file selected</Alert>
        }
        {uploadInProgress
          ? <Typography variant="h3">Uploading...</Typography>
          : <>
            <input
              type="file"
              ref={fileInputRef}
              multiple={false}
              onChange={e => {
                const f = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
                if (!f) return;
                setFile(f);
              }}
              style={{ display: 'none' }}
            />
            <Button variant="text" onClick={() => {
              if (fileInputRef.current) fileInputRef.current.click();
            }}>Choose Dataset File</Button>
          </>
        }
        {uploadInProgress
          ? <LinearProgress />
          : savedDataset
            ? <Button onClick={modifyDataset} variant="contained" disabled={name == ''}>Update Dataset</Button>
            : <Button onClick={createDataset} variant="contained" disabled={name == '' || file == null}>Add Dataset</Button>
        }
      </>
      : <LinearProgress />
    }
  </Stack>;
}
