import React from 'react';
import { GlobalStyles } from "@mui/material";

export default function PageGlobalStyles() {
  return <GlobalStyles styles={{
    "body,html": {
      margin: 0,
      padding: 0,
    },
    "*": {
      boxSizing: 'border-box',
    }
  }} />
}
