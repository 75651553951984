import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SignInDialog } from '../../../../components/Auth';
import { AppRun, AppRunOptions, useAppRun } from '../../../../hooks/useAppRun';
import { ChatView } from './ChatView';
import CustomizationProvider from '../../../../components/customization/customization-provider';
import { StylingWithDefaults } from '../../../../types/ClientState';
import { AppPopover } from './AppPopover';
import { TPalette, TFont, TLayout } from '../../../../../generated/gql/graphql';
import { useClientStore } from '../../../../hooks/ClientState';
import { Property } from 'csstype';
import { Box, LinearProgress, Stack, Theme, debounce } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import { DebugView } from './DebugView';
import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { createFlowFromZustand } from '../../../../utils/graph-conversion';


// NOTE this component needs to be inside a GraphQLProvider and AppContainer
// TODO this should be the designated component to manage client state, right now it's all over the place
export function AppClientInPopover(props: {
  flowId: string | undefined,
  authToken?: string;
  palette?: TPalette;
  font?: TFont;
  layout?: TLayout;
  onStylingLoaded?: (styling: StylingWithDefaults) => void;
  replayMessagesOnConnect?: boolean;
  onClose?: () => void;
  initialOpen?: boolean;
  initialShowDebug?: boolean;
  disconnectOnClose?: boolean;
  // only effective if disconnectOnClose is true. if true, the session will be terminated on close
  quitSessionOnClose?: boolean;
  backdropFilter?: Property.BackdropFilter;
  disableQuickClose?: boolean;
  theme?: Partial<Theme>;
}): React.ReactElement {
  const setFlowId = useClientStore(state => state.setFlowId);
  const loadStylingCustomizations = useClientStore(state => state.loadStylingCustomizations);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setFlowId(props.flowId);
    loadStylingCustomizations(apolloClient).then(() => {
      const state = useClientStore.getState();
      props.onStylingLoaded?.({
        palette: state.getPalatte(),
        font: state.getFont(),
        layout: state.getLayout(),
        icon: state.icon,
        openAfter: state.openAfter,
      });
    });
  }, [props.flowId, apolloClient]);

  const [
    initializing,
    inConversation,
  ] = useClientStore(state => [
    state.initializing,
    state.inConversation,
  ]);

  const [showDebug, setShowDebug] = useState(props.initialShowDebug);
  const nodesData = useEditorStore(useShallow(state => state.app.graph.nodes.map(node => node.data)));
  const edges = useEditorStore(useShallow(state => state.app.graph.edges));
  const [
    startNodeId,
    aiConfig,
  ] = useEditorStore(useShallow(state => [
    state.app.startNodeId,
    state.app.aiConfig,
  ]));

  const disconnectRef = React.useRef(() => { });
  const [appRunOptions, setAppRunOptions] = useState<AppRunOptions>({
    debug: false,
    flowId: props.flowId,
    startId: null,
  });
  const [appRunOptionsInitialized, setAppRunOptionsInitialized] = useState(false);
  const computeAppRunOptions = useCallback(
    debounce(() => {
      disconnectRef.current?.();
      if (showDebug) {
        const flow = createFlowFromZustand(nodesData, edges);
        setAppRunOptions({
          debug: true,
          flowConfig: flow,
          flowId: props.flowId,
          startId: startNodeId,
          aiConfig,
        });
      }
      else {
        setAppRunOptions({
          debug: false,
          flowId: props.flowId,
          startId: null,
        });
      }
      setAppRunOptionsInitialized(true);
    }, 200),
    [props.flowId, showDebug, nodesData, edges, startNodeId, aiConfig]
  );

  useEffect(() => {
    computeAppRunOptions();
    return () => computeAppRunOptions.clear();
  }, [computeAppRunOptions]);

  const [showSignin, setShowSignin] = useState(false);
  const appRun = useAppRun(
    appRunOptions, () => setShowSignin(true),
    !appRunOptionsInitialized || !inConversation,
    props.replayMessagesOnConnect
  );

  useEffect(() => {
    disconnectRef.current = appRun.disconnect;
  }, [appRun.disconnect]);

  const onPopoverClose = useCallback(() => {
    if (props.disconnectOnClose) {
      appRun.disconnect(!props.quitSessionOnClose);
    }
    props.onClose?.();
  }, [appRun.disconnect, props.onClose, props.quitSessionOnClose]);

  return <CustomizationProvider
    palette={props.palette}
    font={props.font}
    theme={props.theme}
  >
    <AppPopover
      authToken={props.authToken}
      showLoading={!props.flowId}
      initialOpen={props.initialOpen}
      onClose={onPopoverClose}
      backdropFilter={props.backdropFilter}
      disableQuickClose={props.disableQuickClose}
      layout={props.layout}
    >
      {initializing
        ? <LinearProgress />
        : <Stack direction='row' width='100%' height='100%' spacing={2}>
          {showDebug && <DebugView appRun={appRun} onClose={() => setShowDebug(false)} width='70%' />}
          <ChatView {...appRun} width={showDebug ? '30%' : '100%'} />
          <SignInDialog
            open={showSignin}
            enableSignup={true}
            onSignin={() => {
              // NOTE: this could be wrong if signin required is not triggered at the beginning
              appRun.send([]);
              setShowSignin(false);
            }}
            message='Please signin first to use the app.' />
        </Stack>}
    </AppPopover>
  </CustomizationProvider>
}
