import { IWebContent } from "../../generated/gql/graphql";
import { GOPIXIE_CONTAINER_ELEM_ID, GOPIXIE_DRAWER_ELEM_ID } from "../constants/common";
import { getTabIdForSidePanel, isErrorResponse, isInChromeExtensionFile, isInSidePanel, sendRequestToContentScript } from "./chrome-extension";

// IMPORTANT: server plugin implementations has implicit dependency on this attribute name.
// Also search for text matches in the plugin py modules when changing this.
const SCROLLABLE_DATA_ATTRIBUTE = 'data-is-scrollable';
const SCROLL_DIRECTIONS_DATA_ATTRIBUTE = 'data-scroll-directions';

function isElementVisible(element: HTMLElement, contentWindow?: Window): boolean {
  const style = (contentWindow || window).getComputedStyle(element);
  return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
}

function isNaturallyInvisible(element: HTMLElement): boolean {
  const invisibleTags = ['meta', 'script', 'style', 'noscript', 'link', 'title', 'head'];
  return invisibleTags.includes(element.tagName.toLowerCase());
}

function isMeaninglessElement(element: HTMLElement): boolean {
  const meaninglessTags = ['br', 'svg', 'path', 'rect', 'circle', 'line', 'polygon', 'polyline', 'hr', 'input', 'textarea', 'button', 'select', 'option', 'div', 'span', 'p'];
  return (
    meaninglessTags.includes(element.tagName.toLowerCase()) &&
    element.innerHTML.trim() === '' &&
    !element.hasAttributes()
  );
}

function cleanAttributes(element: HTMLElement): void {
  // TODO the cleaning has been moved to server
  // Remove unnecessary attributes
  // including class to help LLM determine selector, need further evaluation on potential token saving
  // for (const attr of Array.from(element.attributes)) {
  //   if (!['id', 'href', 'src', 'contenteditable', "class"].includes(attr.name)) {
  //     element.removeAttribute(attr.name);
  //   }
  // }
}

// return a deep copy of the DOM tree with invisible elements removed
function traverseAndClean(
  originalNode: Node,
  contentWindow?: Window,
  beforeCaptureNode?: (node: Node) => void,
  onCaptureNode?: (node: Node) => void,
): Node | null {
  beforeCaptureNode?.(originalNode);
  const copiedNode = originalNode.cloneNode(false);
  if (originalNode.nodeType === Node.COMMENT_NODE) {
    return null;
  }
  // TODO whitespace might help LLM performance, need to see whether it's better to compress whitespaces than remove
  else if (originalNode.nodeType === Node.TEXT_NODE) {
    const text = originalNode.textContent?.trim();
    if (!text) {
      return null;
    }
    copiedNode.textContent = text; // Trim the text node
  }
  else if (originalNode.nodeType === Node.ELEMENT_NODE) {
    const originalElement = originalNode as HTMLElement;
    if ([GOPIXIE_CONTAINER_ELEM_ID, GOPIXIE_DRAWER_ELEM_ID].includes(originalElement.id)) {
      return null;
    }
    if (!isElementVisible(originalElement, contentWindow) || isNaturallyInvisible(originalElement)) {
      return null;
    }
    const childNodes = Array.from(originalNode.childNodes);
    for (const child of childNodes) {
      const copiedChild = traverseAndClean(child, contentWindow, beforeCaptureNode, onCaptureNode);
      if (copiedChild) {
        copiedNode.appendChild(copiedChild);
      }
    }
    cleanAttributes(copiedNode as HTMLElement);
    if (isMeaninglessElement(copiedNode as HTMLElement)) {
      return null;
    }
  }
  onCaptureNode?.(originalNode);
  return copiedNode;
}

function isElementScrollable(element: HTMLElement, contentWindow?: Window): { up?: number, down?: number, left?: number, right?: number } {
  const style = (contentWindow || window).getComputedStyle(element);
  const overflowX = style.overflowX;
  const overflowY = style.overflowY;

  const isHorizontalScrollable = (overflowX === 'auto' || overflowX === 'scroll') &&
    element.scrollWidth > element.clientWidth;
  const isVerticalScrollable = (overflowY === 'auto' || overflowY === 'scroll') &&
    element.scrollHeight > element.clientHeight;

  return {
    left: isHorizontalScrollable ? element.scrollLeft : undefined,
    right: isHorizontalScrollable ? element.scrollWidth - element.clientWidth - element.scrollLeft : undefined,
    up: isVerticalScrollable ? element.scrollTop : undefined,
    down: isVerticalScrollable ? element.scrollHeight - element.clientHeight - element.scrollTop : undefined
  };
}

// TODO this currently directly modify the DOM, which might have unintended side-effects
// consider to generate the annotations on a copy of the DOM instead
export function annotateDOM(rootElement: HTMLElement = document.documentElement): void {
  const elements = rootElement.getElementsByTagName('*');

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLElement;
    const scrollInfo = isElementScrollable(element);

    const directions = [];
    for (const [key, value] of Object.entries(scrollInfo)) {
      // not 0 and not undefined
      if (value) {
        directions.push(key);
      }
    }
    if (directions.length > 0) {
      element.setAttribute(SCROLLABLE_DATA_ATTRIBUTE, 'true');
      element.setAttribute(SCROLL_DIRECTIONS_DATA_ATTRIBUTE, directions.join(','));
    }
  }
}

export function filterDOM(
  element: HTMLElement,
  contentWindow?: Window,
  beforeCaptureNode?: (node: Node) => void,
  onCaptureNode?: (node: Node) => void,
): HTMLElement {
  const copied = traverseAndClean(element, contentWindow, beforeCaptureNode, onCaptureNode);
  return (copied as HTMLElement | null) || document.createElement(element.tagName);
}

// IMPORTANT: parameters have no effect when the function is called in side panel
export async function captureWebContent(
  elem?: HTMLElement,
  // window is used to resolve element styles
  contentWindow?: Window,
  // event handlers will be passed the ORIGINAL node that's being processed
  // rather than the copied node that'd be returned
  beforeCaptureNode?: (node: Node) => void,
  onCaptureNode?: (node: Node) => void,
): Promise<IWebContent> {
  if (isInChromeExtensionFile()) {
    const tabId = getTabIdForSidePanel();
    if (isInSidePanel() && tabId !== undefined) {
      const res = await sendRequestToContentScript(tabId, { __typename: 'CaptureWebContent' });
      if (isErrorResponse(res)) {
        throw new Error(res.message);
      }
      return {
        html: res.html,
        url: res.url,
      };
    }
  }

  return new Promise<IWebContent>((resolve) => {
    const capture = () => {
      elem = elem || document.documentElement;
      annotateDOM(elem);
      const filteredDOM = filterDOM(elem, contentWindow, beforeCaptureNode, onCaptureNode);
      window.removeEventListener('load', capture);
      resolve({
        html: filteredDOM.outerHTML,
        url: window.location.href,
      });
    };

    if (document.readyState === 'complete') {
      // Document is already loaded
      capture();
    } else {
      // Wait for the load event
      window.addEventListener('load', capture);
    }
  });
}
