import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { Alert, Avatar, Button, Dialog, Divider, LinearProgress, Link, Popover, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { LOGIN, LOGOUT, SIGNUP } from '../graphql/mutation';
import { GraphQLError } from 'graphql';
import { TUser } from '../../generated/gql/graphql';
import PixieIcon from './pixie/CompanyIcon';
import { useUserAndWorkspaceStore } from '../hooks/UserAndWorkspaceStore';
import { useShallow } from 'zustand/react/shallow';


export function SignInForm(props: {
  onSignin?: (user: TUser) => void,
  message?: string,
  enableSignup?: boolean,
  isSignup?: boolean,
}): React.ReactElement {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password1, setPassword1] = useState<string>('');
  const [login] = useMutation(LOGIN);
  const [signup] = useMutation(SIGNUP);
  const [isSignup, setIsSignup] = useState(Boolean(props.enableSignup) && props.isSignup);
  const [authError, setAuthError] = useState<ApolloError | GraphQLError | string | undefined>(props.message);
  const loadIdentity = useUserAndWorkspaceStore(state => state.loadIdentity);
  const client = useApolloClient();

  useEffect(() => {
    setIsSignup(Boolean(props.enableSignup) && props.isSignup);
  }, [props.isSignup, props.enableSignup]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSignup) {
      if (password !== password1) setAuthError('password mismatch')
      else {
        await signup({ variables: { email: username, password } })
          .then(res => {
            loadIdentity(client, true);
            if (!res.data) setAuthError(res.errors?.[0] || 'something went wrong');
            else {
              props.onSignin?.(res.data.signup);
              setAuthError(undefined);
            }
          })
          .catch(e => setAuthError(e));
      }
    }
    else {
      await login({ variables: { username, password } })
        .then(res => {
          loadIdentity(client, true);
          if (res.data) {
            props.onSignin?.(res.data.login);
            setAuthError(undefined);
          }
          else setAuthError(res.errors?.[0] || 'something went wrong');
        })
        .catch(e => setAuthError(e));
    }
  }

  return <form>
    <Stack p={{ xs: 2, md: 4, lg: 6 }} direction='column' spacing={3} display='flex' justifyContent='center'>
      <PixieIcon />
      {authError && <Alert variant='outlined' severity='error'>{typeof authError === 'string' ? authError : authError.message}</Alert>}
      <TextField
        placeholder='email'
        autoComplete='username'
        variant="outlined"
        onChange={e => setUsername(e.target.value)}
        value={username}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        type='password'
        placeholder='password'
        autoComplete={isSignup ? 'new-password' : 'current-password'}
        variant="outlined"
        onChange={e => setPassword(e.target.value)}
        value={password}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PasswordOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      {isSignup
        ? <TextField
          type='password'
          placeholder='repeat password'
          autoComplete='new-password'
          variant="outlined"
          onChange={e => setPassword1(e.target.value)}
          value={password1}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        : undefined
      }
      {isSignup
        ? <Typography width='100%' variant='subtitle1'>
          Already have an account? <Link onClick={() => setIsSignup(false)}>Login</Link> instead.
        </Typography>
        : props.enableSignup && <Typography width='100%' variant='subtitle1'>
          Don't have an account? <Link onClick={() => setIsSignup(true)}>Signup</Link> instead.
        </Typography>
      }
      <Divider variant='middle' />
      <Button
        variant='contained'
        size='large'
        color='secondary'
        onClick={handleSubmit}
      >
        <Typography variant='h6'>{isSignup ? 'sign up' : 'sign in'}</Typography>
      </Button>
    </Stack>
  </form>

}


export function SignInDialog(props: {
  open: boolean,
  // by not providing a setOpen method, this dialog becomes blocking
  setOpen?: (open: boolean) => void,
  onSignin?: (user: TUser) => void,
  message?: string,
  enableSignup?: boolean,
  isSignup?: boolean,
}): React.ReactElement {
  return <Dialog open={props.open} onClose={() => props.setOpen?.(false)} maxWidth='sm' fullWidth>
    <SignInForm
      message={props.message}
      enableSignup={props.enableSignup}
      isSignup={props.isSignup}
      onSignin={user => {
        props.onSignin?.(user);
        props.setOpen?.(false);
      }} />
  </Dialog>
}


const StyledButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 10,
})


export default function AuthButton(props: {
  SignInLabel?: string,
  SignOutLabel?: string,
  onSignout?: () => void,
  onSignin?: (user: TUser) => void,
  size?: number,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [
    user,
    loadIdentity
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.user,
    state.loadIdentity,
  ]));
  const [logout] = useMutation(LOGOUT);
  const { setError, setSuccessMessage } = useContext(AppContext);
  const client = useApolloClient();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const size = props.size || 56;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleLogout() {
    logout().then(res => {
      loadIdentity(client, true);
      if (res.errors) setError(res.errors[0]);
      else {
        setSuccessMessage('successfully signed out');
        setAnchorEl(null);
        if (props.onSignout) props.onSignout();
      }
    });
  }

  return <>
    {user
      ? <Tooltip title={`Signed in as ${user.username}`}>
        <Avatar
          sx={{ cursor: 'pointer', bgcolor: theme.palette.primary.light, width: size, height: size, fontSize: `${size / 2}px` }}
          onClick={handlePopoverOpen}
        >{user.username[0].toUpperCase()}</Avatar>
      </Tooltip>
      : <StyledButton variant='outlined' onClick={() => setDialogOpen(true)}>{props.SignInLabel || "Sign in"}</StyledButton>
    }
    <SignInDialog open={dialogOpen} setOpen={setDialogOpen} onSignin={u => {
      loadIdentity(client, true);
      if (props.onSignin) props.onSignin(u);
    }} />
    {user && <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ mt: 2 }}
    >
      <Stack p={2} direction='row' spacing={2} alignItems='baseline'>
        <Typography variant='subtitle1'>signed in as <b>{user.username}</b></Typography>
        <StyledButton variant='outlined' onClick={handleLogout}>{props.SignOutLabel || "Sign out"}</StyledButton>
      </Stack>
    </Popover>}
  </>
}

export function SignInRequired(props: {
  message?: React.ReactNode | string,
  children: React.ReactNode,
  signInDialog?: boolean,
  enableSignup?: boolean,
}): React.ReactElement {
  const [
    user,
    loading
  ] = useUserAndWorkspaceStore(state => [
    state.user,
    state.isLoading(),
  ]);

  return <>
    {user
      ? props.children
      : loading
        ? <LinearProgress />
        : props.signInDialog
          ? <SignInDialog open enableSignup={props.enableSignup} message={typeof props.message === 'string' ? props.message : undefined} />
          : props.message
    }</>;
}
