import { Alert, Box, Fade, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { AppContext } from '../../contexts/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { GraphQLError } from 'graphql';
import PageGlobalStyles from './PageGlobalStyles';



export function AppContainer({ children }): React.ReactElement {
  const containerRef = React.useRef<HTMLElement>(null);
  const [error, _setError] = useState<AxiosError | ApolloError | GraphQLError | string | undefined>(undefined);
  const [successMessage, _setSuccessMessage] = useState<string>('');
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  function setError(e: AxiosError | ApolloError | GraphQLError | string | undefined) {
    _setError(e);
    setShowErrorAlert(true);
  }

  function setSuccessMessage(msg: string) {
    _setSuccessMessage(msg);
    setShowSuccessAlert(true);
  }

  return <Box ref={containerRef}>
    <PageGlobalStyles />
    <TransitionGroup>
      <BrowserRouter>
        <AppContext.Provider value={{
          error,
          setError,
          containerRef,
          setSuccessMessage,
        }}>
          {children}
          <Fade in={showErrorAlert} timeout={{ enter: 1000, exit: 7000 }} onEntered={() => setShowErrorAlert(false)}>
            <Alert severity="error" sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex: 10000,
            }}>
              <Typography><b>Error loading data - {typeof error == 'string' ? error : error?.message}.</b></Typography>
            </Alert>
          </Fade>
          <Fade in={showSuccessAlert} timeout={{ enter: 1000, exit: 7000 }} onEntered={() => setShowSuccessAlert(false)}>
            <Alert sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex: 10000,
            }}>
              <Typography><b>{successMessage}</b></Typography>
            </Alert>
          </Fade>
        </AppContext.Provider>
      </BrowserRouter>
    </TransitionGroup>
  </Box>;
}
