import { removeTypename } from './removeTypename';
import { snakeToCamel } from './snakeToCamel';

function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

function convertData(data: any, keyConverter: (k: string) => string): any {
  if (Array.isArray(data)) {
    return data.map(item => toPydanticData(item));
  } else if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((newObj, key) => {
      const newKey = keyConverter(key);
      newObj[newKey] = toPydanticData(data[key]);
      return newObj;
    }, {});
  }
  return data;

}

export function toPydanticData(strawberryData: any): any {
  const pydanticData = convertData(strawberryData, camelToSnakeCase);
  return removeTypename(pydanticData);
}

export function toStrawberryData(pydanticData: any): any {
  return convertData(pydanticData, k => snakeToCamel(k, false));
}
