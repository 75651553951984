import Ajv from "ajv";
import { JSONSchema7, JSONSchema7Definition } from "json-schema";
import { DynamicValue, AccessPathSegment, isComposite, DynamicValueParam, isSimple, FormattedDynamicValue } from "../types/DynamicValueTypes";
import { FlowParamInputType } from "../../generated/gql/graphql";
import { snakeToCamel } from "./snakeToCamel";

function schemaCompatible(targetSchema: JSONSchema7 | undefined, candidateSchema: JSONSchema7 | undefined): boolean {
  if (!targetSchema) return true;
  if (!candidateSchema) return false;
  const relevantFields = ['type', 'properties', 'items', 'required', 'enum', 'anyOf'];

  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (!compareValues(arr1[i], arr2[i])) return false;
    }
    return true;
  }

  function objectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (const key of keys1) {
      if (!keys2.includes(key) || !compareValues(obj1[key], obj2[key])) return false;
    }
    return true;
  }

  function compareValues(value1, value2) {
    if (typeof value1 !== typeof value2) return false;

    if (Array.isArray(value1)) {
      return arraysEqual(value1, value2);
    } else if (typeof value1 === 'object' && value1 !== null) {
      return objectsEqual(value1, value2);
    }

    return value1 === value2;
  }

  for (const field of relevantFields) {
    const hasField1 = targetSchema.hasOwnProperty(field);
    const hasField2 = candidateSchema.hasOwnProperty(field);
    if (hasField1 || hasField2) {
      if (!hasField1 || !hasField2 || !compareValues(targetSchema[field], candidateSchema[field])) {
        return false;
      }
    }
  }

  return true;
}

export type DataRef = {
  accessPath: (string | number)[],
}

export function getCompatibleReferences(
  targetType: JSONSchema7 | undefined,
  candidates: { [id: string]: JSONSchema7 | undefined },
): DataRef[] {
  if (!targetType) Object.keys(candidates).map(k => ({ accessPath: [k] }));
  const ret = [] as DataRef[];
  for (const [id, candidate] of Object.entries(candidates)) {
    if (schemaCompatible(targetType, candidate)) ret.push({ accessPath: [id] });
    if (candidate?.properties) {
      const fieldCandidates = Object.entries(candidate.properties).reduce(
        (col, [f, schemaDef]) => {
          col[f] = getSchema(schemaDef);
          return col;
        }, {}
      );
      const matches = getCompatibleReferences(targetType, fieldCandidates);
      for (const m of matches) ret.push({ accessPath: [id, ...m.accessPath] });
    }
    if (candidate?.anyOf) {
      for (const schemaDef of candidate.anyOf) {
        const matches = getCompatibleReferences(targetType, { [id]: getSchema(schemaDef) });
        for (const m of matches) ret.push(m);
      }
    }
  }
  return ret;
}

export function getSchema(schemaDef: JSONSchema7Definition | JSONSchema7Definition[] | undefined): JSONSchema7 | undefined {
  if (Array.isArray(schemaDef)) return getSchema(schemaDef[0]);
  if (typeof schemaDef == 'boolean' || !schemaDef) return undefined;
  return schemaDef;
}
// replace empty objects/values with undefined
// note this can return undefined for the entire object after removal

export function removeEmpty(dynamicValue: any): any {
  if (!dynamicValue) return undefined;
  if (typeof dynamicValue == 'object') {
    const keysToRemove = [] as string[];
    for (const k in Object.keys(dynamicValue)) {
      const fieldValue = removeEmpty(dynamicValue[k]);
      dynamicValue[k] = fieldValue;
      if (!fieldValue) keysToRemove.push(k);
    }
    for (const k of keysToRemove) delete dynamicValue[k];
    if (Object.values(dynamicValue).length == 0) return undefined;
  }
  return dynamicValue;
}// given list of schemaDefinitions and value, return the index of type that matches the value
// returns default 0 if no option matches value


export function getMatchingDefinitionIndex(schemaDefs: JSONSchema7Definition[], value: any): number {
  let i = 0;
  for (const schemaDef of schemaDefs) {
    const subSchema = getSchema(schemaDef);
    if (!subSchema) continue;
    const ajv = new Ajv({ strict: false });
    const validate = ajv.compile(subSchema);
    const valid = validate(value);
    if (valid) return i;
    i++;
  }
  return 0;
}

export function isPropertyOptional(required: string[] | undefined, propertyName: string, propertySchemaDef: JSONSchema7Definition): boolean {
  return Boolean(getSchema(propertySchemaDef)?.default || !required?.includes(propertyName));
}

function getSpecialInputType(schema: JSONSchema7 & { inputType?: FlowParamInputType }): string | undefined {
  return schema.inputType ? snakeToCamel(schema.inputType.toLowerCase()) : undefined;
}

export function typeToString(schema: JSONSchema7Definition | JSONSchema7Definition[] | null | undefined): string {
  if (!schema || typeof schema == 'boolean') return 'any';
  if (Array.isArray(schema)) return schema.map(typeToString).join(' | ');
  return schema.type == 'array'
    ? `${getSpecialInputType(schema) || typeToString(schema.items)}[]`
    : getSpecialInputType(schema) || (
      Array.isArray(schema.type)
        ? schema.type.join(' | ')
        : schema.type == 'object'
          ? schema.title || 'object'
          : schema.type || typeToString(schema.anyOf)
    );
}

export function getDynamicValue(
  dv: DynamicValueParam | undefined,
  accessPath: AccessPathSegment[]
): DynamicValueParam | undefined {
  let ret = dv;
  for (const seg of accessPath) {
    if (seg.type == 'property' || seg.type == 'arrayIndex') {
      ret = ret && isComposite(ret) ? ret.props[seg.value] : undefined;
    }
  }
  return ret;
}

// convert composite to null
export function getNonCompositeDynamicValue(
  dv: DynamicValueParam | undefined,
  accessPath: AccessPathSegment[]
): DynamicValue | FormattedDynamicValue | null {
  const subDv = getDynamicValue(dv, accessPath);
  if (!subDv || isComposite(subDv)) return null;
  return subDv;
}

// NOTE this function doesn't support arrayIndex in the accessPath
// expectation is that updates to index of array will happen is a separate place
export function getUpdatedDynamicValue(
  dv: DynamicValueParam | undefined,
  update: DynamicValueParam | undefined,
  accessPath: AccessPathSegment[]
): DynamicValueParam | undefined {
  const initKey = 'value';
  const initVal = { props: { [initKey]: structuredClone(dv) } };
  let key = initKey;
  let toUpdate = initVal;
  for (const seg of accessPath) {
    if (seg.type == 'property') {
      if (!(key in toUpdate.props)) toUpdate.props[key] = { props: {} };
      toUpdate = toUpdate.props[key];
      key = seg.value;
    }
  }
  toUpdate.props[key] = update;
  return removeEmpty(initVal.props[initKey]);
}

export function getValue(val: any, accessPath: AccessPathSegment[]): any {
  let ret = val;
  for (const seg of accessPath) {
    if (seg.type == 'property' || seg.type == 'arrayIndex') {
      ret = ret?.[seg.value];
    }
  }
  return ret;
}
// NOTE this function doesn't support arrayIndex in the accessPath
// expectation is that updates to index of array will happen is a separate place
export function getUpdatedValue(val: any, update: any, accessPath: AccessPathSegment[]): any {
  const initKey = 'value';
  const initVal = { [initKey]: structuredClone(val) };
  let key = initKey;
  let toUpdate = initVal;
  for (const seg of accessPath) {
    if (seg.type == 'property') {
      if (!(key in toUpdate) || typeof toUpdate[key] != 'object') toUpdate[key] = {};
      toUpdate = toUpdate[key];
      key = seg.value;
    }
  }
  toUpdate[key] = update;
  return initVal[initKey];
}
// export function DynamicParamInput(props: {
//   value: DynamicParamConfig;
//   schema: JSONSchema7 | undefined;
//   onChange: (value: DynamicParamConfig) => void;
//   references: { [id: string]: TPluginInfo; };
// }): React.ReactElement {
//   // TODO implement
//   const compatibleReferences = useMemo(
//     () => {
//       const candidates = Object.entries(props.references)
//         .reduce(
//           (arr, [id, info]) => ({ ...arr, [id]: info.dataResultSchema }),
//           {} as { [id: string]: JSONSchema7 | undefined }
//         );
//       return getCompatibleReferences(props.schema, candidates);
//     },
//     [props.schema, props.references]
//   );
//   return <Stack spacing={1}>
//     <Autocomplete
//       freeSolo
//       autoComplete
//       options={compatibleReferences.map(dataRef => {
//         let exp = '';
//         for (const p of dataRef.accessPath) {
//           if (typeof p == 'string') {
//             if (exp) exp += `.${p}`;
//             else exp = p;
//           }
//           // otherwise it's index for anyOf which has no effect on expression
//         }
//         return exp;
//       })}
//       renderInput={(params) => <TextField {...params} label="Data Reference" />}
//       value={props.value.expression}
//       onInputChange={(_, v) => props.onChange({ ...props.value, expression: v })} />
//     {/* <TextField
//           label='Expression'
//           value={props.value.expression}
//           onChange={e => props.onChange({ ...props.value, expression: e.target.value })}
//         /> */}
//     <FormControlLabel
//       label={<Typography variant='subtitle2'>Specify version</Typography>}
//       control={<Switch
//         checked={props.value.version !== undefined}
//         onChange={(_, checked) => {
//           if (checked && !props.value.version) {
//             props.onChange({ ...props.value, version: { versionDelta: 0, default: {} } });
//           }
//           else if (!checked) {
//             props.onChange({ ...props.value, version: undefined });
//           }
//         }} />} />
//     <Collapse in={props.value.version !== undefined}>
//       {props.value.version
//         ? <Stack spacing={1}>
//           <TextField
//             label='Version delta'
//             type='number'
//             value={props.value.version.versionDelta}
//             onChange={e => props.onChange({
//               ...props.value,
//               version: {
//                 default: props.value.version!.default,
//                 versionDelta: parseInt(e.target.value)
//               }
//             })} />
//           <JSONInputField
//             label='Default value'
//             value={props.value.version.default}
//             onChange={v => props.onChange({
//               ...props.value,
//               version: {
//                 default: v,
//                 versionDelta: props.value.version!.versionDelta,
//               }
//             })} />
//         </Stack>
//         : undefined}
//     </Collapse>
//   </Stack>;
// }
// const segStartChars = ['<', '['] as const;
// type SegStartChar = typeof segStartChars[number];
// function isSegStartChar(char: string): char is SegStartChar {
//   return segStartChars.includes(char as SegStartChar);
// }
// function getCloseChar(char: SegStartChar): string {
//   switch (char) {
//     case '<': return '>';
//     case '[': return ']';
//   }
// }
// function getSegType(start: SegStartChar): 'typeSelect' | 'arrayIndex' {
//   switch (start) {
//     case '<': return 'typeSelect';
//     case '[': return 'arrayIndex';
//   }
// }
// // segment text is the expression split by '.'
// function parseSegmentText(segText: string): {
//   path: AccessPathSegment[],
//   remainder?: { // remainder is undefined when the segText fails parsing
//     text: string,
//     // the remainder is a property when start is null
//     // difference is that the value completion for the propery should lead with '.'
//     // when remainder text is empty but the original segText is not
//     start: SegStartChar | null,
//   }
// } {
//   let start: SegStartChar | null = null;
//   let remainderText = '';
//   const path: AccessPathSegment[] = [];
//   for (const char of segText) {
//     if (isSegStartChar(char)) {
//       if (start !== null) {
//         return { path: [] }; // invalid because start again before closing
//       } else if (remainderText.length > 0) {
//         if (path.length > 0) return { path: [] }; // invalid because non-enclosed segment after initial property segment
//         else {
//           path.push({ type: 'property', key: remainderText });
//           remainderText = '';
//           start = char;
//         }
//       } else {
//         start = char;
//       }
//     }
//     else if (start !== null && char == getCloseChar(start)) {
//       if (!remainderText) return { path: [] }; // invalid because of empty segment
//       else {
//         path.push({ type: getSegType(start), index: parseInt(remainderText, 10) });
//         remainderText = '';
//         start = null;
//       }
//     }
//     else if (start === null && path.length > 0) return { path: [] } // invalid because of property seg starting after another segment
//     else remainderText += char;
//   }
//   return { path, remainder: { text: remainderText, start } };
// }
// function parseExpression(expression: string): {
//   path: AccessPathSegment[],
//   remainder?: { // remainder is undefined when the segText fails parsing
//     text: string,
//     type: 'property' | 'arrayIndex' | 'typeSelect',
//   }
// } {
//   let accessPath: AccessPathSegment[] = [];
//   // Split input based on '.' to determine stage
//   const segTexts = expression.split('.');
//   if (segTexts.length == 0) return { path: [], remainder: { type: 'property', text: '' } };
//   for (let i = 0; i < segTexts.length - 1; i++) {
//     const parsed = parseSegmentText(segTexts[i]);
//     if (!parsed.remainder) return { path: [] }; // invalid expression because segment invalid
//     if (parsed.remainder.start) return { path: [] }; // incomplete middle segment
//     else {
//       accessPath = [...accessPath, ...parsed.path];
//       if (parsed.remainder.text) accessPath.push({ type: 'property', key: parsed.remainder.text });
//     }
//   }
//   const parsed = parseSegmentText(segTexts[segTexts.length - 1]);
//   if (!parsed.remainder) return { path: [] }; // invalid expression because segment invalid
//   accessPath = [...accessPath, ...parsed.path];
//   return {
//     path: accessPath,
//     remainder: {
//       text: parsed.remainder.text,
//       type: !parsed.remainder.start
//         ? 'property'
//         : getSegType(parsed.remainder.start)
//     }
//   }
// }

export function getSchemaDef(
  schemaDef: JSONSchema7Definition | undefined,
  accessPath: AccessPathSegment[]
): JSONSchema7 | undefined {
  let ret = getSchema(schemaDef);
  for (const seg of accessPath) {
    switch (seg.type) {
      case 'typeSelect':
        ret = getSchema(ret?.anyOf?.[seg.value]);
        break;
      case 'arrayIndex':
        ret = getSchema(Array.isArray(ret?.items) ? ret.items[0] : ret?.items); // TODO handle array case better
        break;
      case 'property':
        ret = getSchema(ret?.properties?.[seg.value]);
    }
  }
  return ret;
}
