import React from "react";
import { AiTask } from "../types/AiTask";
import { PageInEdit } from "../types/PageInEdit";



export const ItentoAppContext = React.createContext({
  chatOpen: false,
  setChatOpen: (open: boolean) => { },
  taskProgressionOpen: false,
  setTaskProgressionOpen: (open: boolean) => { },
  tasks: [] as AiTask[],
  updateTasksOrClear: (task: AiTask | null) => { },
  pageInEdit: undefined as PageInEdit | undefined,
  setPageInEdit: (page: PageInEdit | undefined) => { },
});
