import { useEffect, useCallback } from 'react';
import React from 'react';
import { Box, List, ListItem, Popover, PopoverOrigin } from '@mui/material';

export default function AutoSuggestMenu<T>(props: {
  options: T[],
  renderOptionFn: (option: T) => JSX.Element | undefined,
  onSelectOption: (selectedOption: T, selectedIndex: number) => void,
  anchorEl: HTMLElement | null,
  onHightlightOption?: (highlightedOption: T, hightlightedIndex: number) => void,
  onOpen?: () => void,
  onClose?: () => void,
  anchorOrigin?: PopoverOrigin,
}): JSX.Element {

  const [hightlightedIndex, setHightlightedIndex] = React.useState<number>(0);
  const shouldShowMenu = props.anchorEl != null && props.options.length > 0;

  useEffect(() => {
    // NOTE onClose is called with Popover onClose and keydown event listener
    if (shouldShowMenu) {
      props.onOpen?.();
    }
  }, [shouldShowMenu]);

  // Reset hightlighted index when options change
  useEffect(() => setHightlightedIndex(0), [props.options]);

  // Keyboard event handler for up/down/enter keys
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (!shouldShowMenu) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setHightlightedIndex((prevIndex) =>
          prevIndex < props.options.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setHightlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case "Enter":
      case 'Tab':
        event.preventDefault();
        props.onSelectOption(props.options[hightlightedIndex], hightlightedIndex);
        break;
      // NOTE: the keydown handler somehow disabled the default behavior of closing popover on 'Escape' key
      case 'Escape':
        event.preventDefault();
        props.onClose?.();
        break;
      default:
        break;
    }
  }, [shouldShowMenu, hightlightedIndex, props.options, props.onSelectOption]);

  // Add/remove keydown event listener when the popover is open
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Popover
      open={shouldShowMenu}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={props.anchorOrigin}
      disableEnforceFocus
      disableAutoFocus
    >
      <List>
        {props.options.map((option, index: number) => (
          <ListItem
            key={index}
            sx={{
              background: hightlightedIndex === index ? '#eee' : 'inherit',
              width: '100%',
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            <Box
              width='100%'
              onMouseEnter={() => setHightlightedIndex(index)}
              onClick={() => props.onSelectOption(option, index)}
            >
              {props.renderOptionFn(option)}
            </Box>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}
