import { ApolloError, FetchResult, Observer, useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CONTINUE_DEBUG_RUN, CONTINUE_RUN, START_DEBUG_RUN, START_RUN } from '../graphql/subscription';
import { IFlowConfig, IRerunOptions, IUserInput, RoleV2, TAppDebugInfo, TAppDebugRunResult, TAppDisplayUpdate, TAppRunResult, TAppStatusUpdate, TFlowRunMetadata, TMessage, TStatusMessage, TUserInputRequirement } from '../../generated/gql/graphql';
import { AxiosError } from 'axios';
import { GraphQLError } from 'graphql';
import { useShallow } from 'zustand/react/shallow';
import { useClientStore } from './ClientState';
import { makeWebPageInteraction } from '../utils/webPageInteraction';
import { useUserAndWorkspaceStore } from './UserAndWorkspaceStore';



export type AppRunOptions = {
  debug: false;
  flowId: string;
  startId: string | null;
} | {
  debug: true;
  flowConfig: IFlowConfig;
  flowId?: string;
  startId: string | null;
  aiConfig?: any;
};
type AppRunError = AxiosError<any, any> | ApolloError | GraphQLError;

export type AppRun = {
  clientId: string | undefined;
  initializing: boolean;
  inProgress: boolean;
  inputRequirement: TUserInputRequirement | undefined;
  messages: (TMessage | TStatusMessage)[];
  debugLogs: TAppDebugInfo[];
  send: (
    userInputs: IUserInput[],
    onComplete?: () => void,
    rerunOptions?: IRerunOptions,
    replayMessages?: boolean,
  ) => void;
  error: AppRunError | undefined;
  completed: boolean;
  disconnect: (keepSession?: boolean) => void;
};
type Subscription = {
  unsubscribe: () => void;
  closed: boolean;
};

export function useAppRun(
  options: AppRunOptions,
  onSigninRequired: () => void,
  skip?: boolean,
  replayMessagesOnConnect?: boolean
): AppRun {
  const client = useApolloClient();
  const fetchPolicy = 'no-cache';

  const siteId = useUserAndWorkspaceStore(state => state.workspaceId);
  const [
    clientId,
    initializing,
    serverInProgress,
    inputRequirement,
    messages,
    debugLogs,
    error,
    completed,
    setClientId,
    increaseUnreadCount,
    setInitializing,
    setServerInProgress,
    setInputRequirement,
    setError,
    setCompleted,
    addDebugLog,
    addMessage,
    resetClient,
    setInterrupted,
  ] = useClientStore(useShallow(state => [
    state.clientId,
    state.initializing,
    state.serverInProgress,
    state.inputRequirement,
    state.messages,
    state.debugLogs,
    state.error,
    state.completed,
    state.setClientId,
    state.increaseUnreadCount,
    state.setInitializing,
    state.setServerInProgress,
    state.setInputRequirement,
    state.setError,
    state.setCompleted,
    state.addDebugLog,
    state.addMessage,
    state.resetClient,
    state.setInterrupted,
  ]));
  // to properly set the state to a function, it need to be a function that returns the state
  // otherwise setState would just call the function and save the result
  const [disconnect, setDisconnect] = useState<(keepSession?: boolean) => void>(() => () => { });

  const queue = useRef<(
    TUserInputRequirement |
    TMessage |
    TAppDisplayUpdate |
    TAppDebugInfo |
    TAppStatusUpdate |
    TFlowRunMetadata)[]>([]);

  // using ref to accurately track if the queue is processing, to avoid duplicate processing
  const queueProcessingInProgress = useRef(false);
  // this is the same as queueProcessingInProgress, but can be outdated. Used for UX rendering
  const [clientInProgress, setClientInProgress] = useState(false);

  const inProgress = serverInProgress || clientInProgress;

  const addStatusMessage = useCallback((message: string, persistent: boolean = false) => {
    addMessage({ __typename: 'TStatusMessage', content: message, persistent });
  }, []);

  async function processQueue() {
    if (queueProcessingInProgress.current) return;
    queueProcessingInProgress.current = true;
    // NOTE: if queue.current changes during processing, this will not pick up the new queue
    while (queue.current.length > 0) {
      const result = queue.current.shift();

      if (result.__typename == 'TFlowRunMetadata') {
        setClientId(result.clientId);
        setInitializing(false);
      }
      if (result.__typename == 'TMessage') {
        // clear error when there's a new message
        setError(undefined);
        addMessage(result);
        increaseUnreadCount();
      }
      if (result.__typename == 'TAppDisplayUpdate') {
        // clear error when there's a new display update
        setError(undefined);
        if (result.statusMessage) {
          addMessage(result.statusMessage);
        }
        if (result.navigationUrl) {
          addStatusMessage(`Navigate to ${result.navigationUrl}`);
          window.location.href = result.navigationUrl;
        }
        if (result.interaction) {
          const actionInputStr = result.interaction.actionInput ? ` with input \`${result.interaction.actionInput}\`` : '';
          addStatusMessage(`${result.interaction.action} on element \`${result.interaction.selector}${actionInputStr}\``);
          await makeWebPageInteraction(result.interaction)
            .then(changes => {
              if (result.interaction.description) {
                addStatusMessage(result.interaction.description, true);
              }
            })
            .catch(setError);
        }
        if (result.jsonData) {
          addMessage({
            __typename: 'TMessage',
            role: RoleV2.Assistant,
            content: '```\n' + JSON.stringify(result.jsonData, null, 2) + '\n```'
          });
        }
      }
      if (result.__typename == 'TUserInputRequirement') setInputRequirement(result);
      if (result.__typename == 'TAppDebugInfo') {
        addDebugLog(result);
      }
      if (result.__typename == 'TAppStatusUpdate') {
        setCompleted(result.hasEnded);
        if (result.signinRequired) {
          onSigninRequired();
        }
      }
    }
    queueProcessingInProgress.current = false;
    setClientInProgress(false);
  }

  const setDisconnectForSubscription = useCallback((sub: Subscription) => {
    // NOTE disconnect is NOT called on existing subscription (if it exists)
    // as doing so might introduce inconsistency in the state
    // We are expecting the caller of this hook to properly disconnect the previous subscription
    // whenever they changes AppRunOptions
    setDisconnect(() => (keepSession?: boolean) => {
      sub.unsubscribe();
      // we have to reset state here instead of watching for subscription.closed
      // as there's no way to properly trigger when the closed value changes.
      setInitializing(false);
      setServerInProgress(false);
      if (!keepSession) {
        resetClient();
      }
    });
  }, []);


  const getObserver = useCallback(<T extends FetchResult>(
    getResult: (data: T) => TAppRunResult | TAppDebugRunResult | undefined,
    onComplete?: () => void
  ): Observer<T> => {
    return {
      next(value) {
        const res = getResult(value);
        if (res) {
          queue.current.push(res.result);
          setClientInProgress(true);
          processQueue();
        }
        if (value.errors) {
          setError(value.errors[0]);
        }
      },
      error(errorValue) {
        console.error('subscription error', errorValue);
        setError(errorValue);
        setServerInProgress(false);
        setInitializing(false);
        setInterrupted('error');
        onComplete?.();
      },
      complete() {
        setServerInProgress(false);
        setInitializing(false);
        onComplete?.();
      },
    };
  }, []);

  useEffect(() => {
    if (skip || !siteId) return;

    setServerInProgress(true);
    if (clientId) {
      if (options.debug) {
        setDisconnectForSubscription(
          client
            .subscribe({
              query: CONTINUE_DEBUG_RUN,
              variables: {
                siteId,
                clientId: clientId,
                userInputs: [],
                aiConfigJson: options.aiConfig,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.acontinueDebugRun
            ))
        );
      }
      else {
        setDisconnectForSubscription(
          client
            .subscribe({
              query: CONTINUE_RUN,
              variables: {
                clientId: clientId,
                userInputs: [],
                replayMessages: replayMessagesOnConnect,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.acontinueRun
            ))
        );
      };
    }
    else {
      if (options.debug) {
        setDisconnectForSubscription(
          client
            .subscribe({
              query: START_DEBUG_RUN,
              variables: {
                siteId,
                flow: options.flowConfig,
                flowId: options.flowId,
                startId: options.startId,
                userInputs: [],
                aiConfigJson: options.aiConfig,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.astartDebugRun
            ))
        );
      }
      else {
        setDisconnectForSubscription(
          client
            .subscribe({
              query: START_RUN,
              variables: {
                flowId: options.flowId,
                startId: options.startId,
                userInputs: [],
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.astartRun
            ))
        );
      }
    }
  }, [options, skip, siteId]);

  const send = (
    userInputs: IUserInput[],
    onComplete?: () => void,
    // only effective when debug is true
    rerunOptions?: IRerunOptions,
    replayMessages?: boolean,
  ) => {
    if (!clientId) return;
    setServerInProgress(true);
    if (options.debug) {
      setDisconnectForSubscription(
        client
          .subscribe({
            query: CONTINUE_DEBUG_RUN,
            variables: {
              siteId,
              clientId,
              userInputs,
              aiConfigJson: options.aiConfig,
              replayMessages,
              rerunOptions,
            },
            fetchPolicy,
          })
          .subscribe(getObserver(v => v.data?.acontinueDebugRun, onComplete))
      );
    }
    else {
      setDisconnectForSubscription(
        client
          .subscribe({
            query: CONTINUE_RUN,
            variables: {
              clientId,
              userInputs,
              replayMessages,
            },
            fetchPolicy,
          })
          .subscribe(getObserver(v => v.data?.acontinueRun, onComplete))
      );
    }
  };

  return {
    clientId,
    initializing,
    inProgress,
    inputRequirement,
    messages,
    debugLogs,
    send,
    error,
    completed,
    disconnect,
  };
}
