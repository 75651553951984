import { TWebPageInteraction, WebPageAction } from '../../generated/gql/graphql';
import { DOMChangeTracker } from '../components/pixie/DOMChangeTracker';
import { WEB_INTERACTION_WAIT_TIME } from '../constants/common';
import { getTabIdForSidePanel, isErrorResponse, isInChromeExtensionFile, isInSidePanel, sendRequestToContentScript } from './chrome-extension';

function takeActionAndWait(selector: string, event: string, waitTimeSec: number, act: (elem: Element) => void): Promise<string> {
  return new Promise((resolve, reject) => {
    const element = document.querySelector(selector);
    if (!element) {
      reject(new Error("Element not found."));
    }

    const tracker = new DOMChangeTracker();

    const cancelOnTimeout = setTimeout(() => {
      element.removeEventListener(event, handleEvent);
      reject(new Error("Action timed out."));
    }, waitTimeSec * 1000 + WEB_INTERACTION_WAIT_TIME * 3);

    const handleEvent = () => {
      element.removeEventListener(event, handleEvent);
      clearTimeout(cancelOnTimeout);
      setTimeout(() => {
        const changedHtml = tracker.getModifiedHTML();
        tracker.stopTracking();
        resolve(changedHtml);
      }, waitTimeSec * 1000 + WEB_INTERACTION_WAIT_TIME); // wait for the page to update with the small delay
    };

    element.addEventListener(event, handleEvent);
    tracker.startTracking();
    act(element);
  });
}


function clickElementAndWait(selector: string, waitTimeSec: number): Promise<string> {
  return takeActionAndWait(selector, 'click', waitTimeSec, (elem) => {
    (elem as HTMLElement).click();
  });
}

function changeElementAndWait(selector: string, value: string, waitTimeSec: number): Promise<string> {
  return takeActionAndWait(selector, 'input', waitTimeSec, (elem) => {
    if (elem instanceof HTMLInputElement || elem instanceof HTMLTextAreaElement) {
      // Handle text input fields and text areas
      elem.value = value;
      elem.dispatchEvent(new Event('input', { bubbles: true }));
    } else if ((elem as HTMLElement).isContentEditable) {
      // Handle content-editable elements
      const lines = value.split('\n');
      const paragraphs = lines.map(line => `<p>${line}</p>`).join('');
      elem.innerHTML = paragraphs;
      elem.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
      throw new Error('Element must be a text input, textarea, or content-editable element.');
    }
  });
}

function scrollElementAndWait(selector: string, waitTimeSec: number): Promise<string> {
  return takeActionAndWait(selector, 'scroll', waitTimeSec, (elem) => {
    elem.scrollTo({ top: elem.scrollHeight, behavior: "smooth" });
  });
}

export async function makeWebPageInteraction(interaction: TWebPageInteraction): Promise<string> {
  if (isInChromeExtensionFile()) {
    const tabId = getTabIdForSidePanel();
    if (isInSidePanel() && tabId !== undefined) {
      const outcome = await sendRequestToContentScript(tabId, interaction);
      if (isErrorResponse(outcome)) {
        throw new Error(outcome.message);
      }
      if (outcome.error) {
        throw outcome.error;
      }
      return outcome.htmlDelta || '';
    } else {
      throw new Error('Cannot make a web page interaction from a non-side panel chrome extension files.');
    }
  }
  else {
    switch (interaction.action) {
      case WebPageAction.Click:
        return await clickElementAndWait(interaction.selector, interaction.wait);
      case WebPageAction.Change:
        return await changeElementAndWait(interaction.selector, interaction.actionInput, interaction.wait);
      case WebPageAction.Scroll:
        return await scrollElementAndWait(interaction.selector, interaction.wait);
    }
  }
}

export async function navigateToUrl(url: string) {
  if (isInChromeExtensionFile()) {
    const tabId = getTabIdForSidePanel();
    if (isInSidePanel() && tabId !== undefined) {
      await sendRequestToContentScript(tabId, { __typename: 'NavigateToUrl', url });
      // NOTE: this is a hack to wait for the page to load
      await new Promise(resolve => setTimeout(resolve, WEB_INTERACTION_WAIT_TIME));
    } else {
      throw new Error('Cannot navigate to a URL from a non-side panel chrome extension files.');
    }
  }
  else {
    window.location.href = url;
  }
}
