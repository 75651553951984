import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { FlowNodeData, GraphNode } from '../types/GraphNode'
import { Connection, Edge, EdgeAddChange, EdgeChange, NodeAddChange, NodeChange, NodeRemoveChange, OnConnect, XYPosition, applyEdgeChanges, applyNodeChanges } from 'reactflow'
import { ApolloClient, ApolloError, FetchResult } from '@apollo/client';
import { GET_DYNAMIC_PLUGIN_INFO, GET_FLOW_V2, GET_STATIC_INFO, ValidateApp } from '../graphql/query'
import { createEdge, createFlowFromZustand, getEdgeStyle, getGraphForZustand } from '../utils/graph-conversion';
import { SettingsMenuOption } from '../types/SettingsMenuOption'
import { CREATE_FLOW_V2, DELETE_FLOW_V2, UPDATE_FLOW_V2 } from '../graphql/mutation'
import { Selection } from '../types/Selection'
import { CreateFlowV2Mutation, DeleteFlowV2Mutation, PluginCategory, TDynamicTypedPlugin, TFlowPluginType, TFlowPluginV2, TFlowValidationResult, TPluginConstructInfo, TPluginInfo } from '../../generated/gql/graphql';
import { AxiosError } from 'axios'
import { GraphQLError } from 'graphql'
import { arrayToObject } from '../utils/common';
import { isEqual } from 'lodash';
import { removeTypename } from '../utils/removeTypename'
import { logDebug } from '../utils/logging';
import { DynamicValueParam, isComposite } from '../types/DynamicValueTypes'
import { computed } from '../utils/zustand-computed';
import { shallow } from 'zustand/shallow';


export interface TypeInfo {
  type: TFlowPluginType,
  constructInfo: TPluginConstructInfo | undefined,
  pluginInfo: TPluginInfo | undefined,
  dynamicTypeInfoOutdated: boolean,
}

// for tracking action history, to carry out undo/redo
type ActionRecord = {
  action: 'add',
  nodeIds: string[],
  edgeIds: string[],
  prevSelection: Selection[],
} | {
  action: 'remove',
  nodes: GraphNode[],
  edges: Edge[],
} | {
  action: 'change',
  prevNodeData: FlowNodeData,
}

type ActionHistory = { timestamp: number, actions: ActionRecord[] }[];

interface ConnectHandle {
  nodeId: string,
  handleId: string
}

interface AppState {
  id: string,  // unsaved app will have empty string as id
  name: string,
  aiConfig: any,
  isPublic: boolean,
  startNodeId: string | null,
  signinRequired: boolean,
  // this is only used during connect. Handle can still be highlighted for other reasons other than being here.
  highlightedConnectHandles: ConnectHandle[],

  graph: {
    nodes: GraphNode[],
    edges: Edge[],
    pastActions: ActionHistory,
    futureActions: ActionHistory,
    validationResults: TFlowValidationResult[],
  },

};

interface Actions {
  setSelectedSettingMenuOption: (option: SettingsMenuOption | null) => void,

  setAiConfig: (aiConfig: any) => void,
  setAppIsPublic: (isPublic: boolean) => void,
  setAppName: (name: string) => void,
  setStartNodeId: (startNodeId: string | null) => void,
  setSigninRequired: (requireSignin: boolean) => void,
  setHighlightedConnectHandles: (handles: ConnectHandle[]) => void,

  graph: {
    updateNodeData: (nodeId: string, updates: Partial<FlowNodeData>) => void,
    addNode: (data: Omit<FlowNodeData, "id" | "__typename">, position: XYPosition, onCreate?: (node: GraphNode) => void) => void,
    onNodesChange: (changes: NodeChange[]) => void,
    onEdgesChange: (changes: EdgeChange[]) => void,
    onConnect: OnConnect,
    removeNode: (nodeId: string) => void,
    removeSelected: () => void,
    copyElements: (selection: Selection[], offset?: XYPosition) => void,
    selectAll: () => void,
    validateApp: () => void,
    // undo/redo
    undo: () => void,
    redo: () => void,
    // computed properties
    getSelections: () => Selection[],
    getSelectedNodeId: () => string | null,
    getSelectedEdgeId: () => string | null,
    getSelectionCount: () => number,
    getTypeInfo: () => { [nodeId: string]: TypeInfo },
    getRelatedNodeIds: (distance: number, sourceNodeId?: string) => string[],
    requiresChromeExtension: () => boolean,
  }

  addErrorNotification: (error: AxiosError | ApolloError | GraphQLError | string) => void,
  addSuccessNotification: (message: string) => void,

  startDebug: () => void,
  endDebug: () => void,
  setTemplateView: (view: string | null) => void,
  setEdgeHighlightColor: (color: string) => void,

  setClipboard: (selections: Selection[]) => void,
  setShortcutsPopoverOpen: (open: boolean) => void,
}

interface Graphql {
  loadApp: (client: ApolloClient<object>, appId: string | null) => Promise<void>,
  saveApp: (client: ApolloClient<object>, siteId: string) => Promise<FetchResult<string>>,
  deleteApp: (client: ApolloClient<object>) => Promise<FetchResult<DeleteFlowV2Mutation>>,
  duplicateApp: (client: ApolloClient<object>, siteId: string) => Promise<FetchResult<CreateFlowV2Mutation>>,
  validatePluginDependencies: (client: ApolloClient<object>) => Promise<void>,

  loadStaticTypes: (client: ApolloClient<object>) => Promise<void>,
  // load all dynamic types for the current app state
  loadDynamicTypes: (client: ApolloClient<object>) => Promise<void>,
  loadDynamicType: (client: ApolloClient<object>, nodeId: string, constructParam?: TDynamicTypedPlugin) => Promise<void>,
  updateDefaultEditorView: (client: ApolloClient<object>, view: string) => Promise<void>,
}

interface Notification {
  time: Date,
  content: AxiosError | ApolloError | GraphQLError | {
    type: 'error' | 'success',
    message: string,
  },
}

interface SettingsMenu {
  selected: SettingsMenuOption | null,
}

interface EditorView {
  debugAppOpen: boolean,
  edgeHightlightColor: string,
  templateView: string | null,
  shortcutsPopoverOpen: boolean,
}

interface TypeInfoCollection {
  // actual key is PluginType
  static: { [type: string]: TPluginInfo },
  construct: { [type: string]: TPluginConstructInfo },
  dynamic: {
    [appId: string]: {
      [nodeId: string]: TPluginInfo,
    }
  }
}

export interface EditorState {
  settingsMenu: SettingsMenu,
  app: AppState,
  notifications: Notification[],
  clipboard: Selection[],
  types: TypeInfoCollection,

  editorView: EditorView,

  actions: Actions,
  graphql: Graphql,
}

const defaultApp: AppState = {
  id: '',
  name: 'Pixie app',
  aiConfig: null,
  isPublic: false,
  startNodeId: null,
  signinRequired: false,
  highlightedConnectHandles: [],
  graph: {
    nodes: [],
    edges: [],
    pastActions: [],
    futureActions: [],
    validationResults: [],
  }
}

export const useEditorStore = create<EditorState>()(
  devtools(subscribeWithSelector(immer((set, get) => ({
    settingsMenu: {
      selected: null,
    },
    editorView: {
      debugAppOpen: false,
      edgeHightlightColor: '#9c27b0',
      templateView: null,
      shortcutsPopoverOpen: false,
    },

    app: defaultApp,

    notifications: [] as Notification[],
    clipboard: [] as Selection[],

    types: {
      static: {},
      construct: {},
      dynamic: {},
    } as TypeInfoCollection,

    actions: {
      setSelectedSettingMenuOption: (option: SettingsMenuOption | null) => set(state => {
        state.settingsMenu.selected = option;
      }),
      setAiConfig: (aiConfig: any) => set(state => {
        state.app.aiConfig = aiConfig;
      }),
      setAppIsPublic: (isPublic: boolean) => set(state => {
        state.app.isPublic = isPublic;
      }),
      setAppName: (name: string) => set(state => {
        state.app.name = name;
      }),
      setStartNodeId: (startNodeId: string | null) => set(state => {
        if (startNodeId === null || state.app.graph.nodes.every(n => n.id !== startNodeId)) {
          state.app.startNodeId = state.app.graph.nodes[0]?.id || null;
        }
        else state.app.startNodeId = startNodeId;
      }),
      setSigninRequired: (requireSignin: boolean) => set(state => {
        state.app.signinRequired = requireSignin;
      }),
      setHighlightedConnectHandles: (handles: ConnectHandle[]) => set(state => {
        state.app.highlightedConnectHandles = handles;
      }),
      setEdgeHighlightColor: (color: string) => set(state => {
        state.editorView.edgeHightlightColor = color;
      }),

      graph: {
        updateNodeData: (nodeId: string, updates: Partial<FlowNodeData>) => set(state => {
          for (const node of state.app.graph.nodes) {
            if (node.id === nodeId) {
              const record: ActionRecord = { action: 'change', prevNodeData: node.data };
              node.data = { ...node.data, ...updates };
              $recordAction(state, record);
            }
          }
        }),

        addNode: (data: Omit<FlowNodeData, "id" | "__typename">, position: XYPosition, onCreate?: (node: GraphNode) => void) => set(state => {
          const n = $addNode(state, data, position);
          const prevSelection = $getSelections(state);
          const record: ActionRecord = { action: 'add', nodeIds: [n.id], edgeIds: [], prevSelection };
          $recordAction(state, record);
          $updateSelections(state, [{ type: 'node', id: n.id }]);
          onCreate?.(n);
        }),

        onNodesChange: (changes: NodeChange[]) => set(state => {
          const prevSelections = $getSelections(state);
          const addRecord: ActionRecord = {
            action: 'add',
            nodeIds: changes.filter(c => c.type === 'add').map(c => (c as NodeAddChange).item.id),
            edgeIds: [],
            prevSelection: prevSelections
          };
          const nodesById = arrayToObject(state.app.graph.nodes, 'id');
          const removeRecord: ActionRecord = {
            action: 'remove',
            nodes: changes
              .filter(c => c.type === 'remove')
              .map(c => nodesById[(c as NodeRemoveChange).id])
              .filter(n => n) as GraphNode[],
            edges: [],
          };
          state.app.graph.nodes = applyNodeChanges<FlowNodeData>(changes, state.app.graph.nodes) as GraphNode[];
          if (addRecord.nodeIds.length > 0) {
            $recordAction(state, addRecord);
          }
          if (removeRecord.nodes.length > 0) {
            $recordAction(state, removeRecord);
          }
          // this does nothing because the function doesn't do anything on selected nodes, but add here for consistency
          $updateSelections(state);
        }),

        onEdgesChange: (changes: EdgeChange[]) => set(state => {
          const prevSelections = $getSelections(state);
          const addRecord: ActionRecord = {
            action: 'add',
            nodeIds: [],
            edgeIds: changes.filter(c => c.type === 'add').map(c => (c as EdgeAddChange).item.id),
            prevSelection: prevSelections
          };
          const edgesById = arrayToObject(state.app.graph.edges, 'id');
          const removeRecord: ActionRecord = {
            action: 'remove',
            nodes: [],
            edges: changes
              .filter(c => c.type === 'remove')
              .map(c => edgesById[(c as NodeRemoveChange).id])
              .filter(e => e) as Edge[],
          };
          $recordAction(state, addRecord);
          $recordAction(state, removeRecord);
          state.app.graph.edges = applyEdgeChanges(changes, state.app.graph.edges);
          $updateSelections(state);
        }),

        onConnect: (conn: Connection) => set(state => {
          if (conn.source && conn.target && conn.sourceHandle) {
            const newEdge = createEdge(conn.source, conn.target, conn.sourceHandle, {});
            const prevSelections = $getSelections(state);
            const record: ActionRecord = {
              action: 'add',
              nodeIds: [],
              edgeIds: [newEdge.id],
              prevSelection: prevSelections,
            };
            $recordAction(state, record);
            state.app.graph.edges.push(newEdge);
          }
        }),

        removeNode: (nodeId: string) => set(state => {
          const { removedNode, removedEdges } = $removeNodeFromState(nodeId, state);
          const record: ActionRecord = {
            action: 'remove',
            nodes: removedNode ? [removedNode] : [],
            edges: removedEdges,
          };
          $recordAction(state, record);
        }),

        removeSelected: () => set(state => {
          const selectedNodes = state.app.graph.nodes.filter(n => n.selected);
          const removed = selectedNodes.map(n => $removeNodeFromState(n.id, state));
          const removedNodes = removed.filter(r => r.removedNode).map(r => r.removedNode);
          const removedEdges = removed.flatMap(r => r.removedEdges);
          const selectedEdges = state.app.graph.edges.filter(e => e.selected);
          removedEdges.push(...selectedEdges);
          state.app.graph.edges = state.app.graph.edges.filter(e => !e.selected);
          // this does nothing because nothing is selected, but adding here for consistency
          $updateSelections(state);
          const record: ActionRecord = {
            action: 'remove',
            nodes: removedNodes,
            edges: removedEdges,
          };
          $recordAction(state, record);
        }),

        selectAll: () => set(state => {
          const all = [
            ...state.app.graph.nodes.map(n => ({ type: 'node', id: n.id })),
            ...state.app.graph.edges.map(e => ({ type: 'edge', id: e.id })),
          ] as Selection[];
          $updateSelections(state, all);
        }),

        copyElements: (selection: Selection[], offset?: XYPosition) => set(state => {
          if (selection.length === 0) return;

          const trueOffset = offset || { x: 50, y: 50 };
          const nodesById = arrayToObject(state.app.graph.nodes, 'id');
          const nodeIdMap = new Map<string, string>();
          const newNodes: GraphNode[] = [];
          for (const nodeSelection of selection.filter(s => s.type === 'node')) {
            if (nodeSelection.id in nodesById) {
              const oldNode = nodesById[nodeSelection.id];
              const newPosition = { x: oldNode.position.x + trueOffset.x, y: oldNode.position.y + trueOffset.y };
              // Id in old data is discarded in addNode, a new id is generated and the id should be read from returned node
              // somehow structuredClone is not working here, so use json parse
              const newNode = $addNode(state, JSON.parse(JSON.stringify(oldNode.data)), newPosition);
              nodeIdMap.set(nodeSelection.id, newNode.id);
              newNodes.push(newNode);
            }
          }

          // update references for all newly copied nodes
          for (const newNode of newNodes) {
            newNode.data.dynamicParams = replaceReferences(nodeIdMap, newNode.data.dynamicParams)
          }

          const edgesById = arrayToObject(state.app.graph.edges, 'id');
          const newEdges: Edge[] = [];
          for (const edgeSelection of selection.filter(s => s.type === 'edge')) {
            const edge = edgesById[edgeSelection.id];
            if (!edge) continue;
            const newEdge = createEdge(
              nodeIdMap.get(edge.source) || edge.source,
              nodeIdMap.get(edge.target) || edge.target,
              edge.sourceHandle,
              // somehow structuredClone is not working here, so use json parse
              JSON.parse(JSON.stringify((edge.data))),
            )
            state.app.graph.edges.push(newEdge);
            newEdges.push(newEdge);
          }

          const prevSelections = $getSelections(state);
          const record: ActionRecord = {
            action: 'add',
            nodeIds: newNodes.map(n => n.id),
            edgeIds: newEdges.map(e => e.id),
            prevSelection: prevSelections,
          };
          $recordAction(state, record);

          const newSelections = newNodes.map(n => ({ type: 'node', id: n.id })).concat(newEdges.map(e => ({ type: 'edge', id: e.id }))) as Selection[];
          $updateSelections(state, newSelections);
        }),

        undo: () => set(state => $undoActions(state, state.app.graph.pastActions, state.app.graph.futureActions)),

        redo: () => set(state => $undoActions(state, state.app.graph.futureActions, state.app.graph.pastActions)),

        validateApp: () => set(state => {
          // TODO to be implemented
        }),

        //computed properties
        getSelections: computed(
          () => [
            get().app.graph.nodes.filter(n => n.selected).map(n => n.id),
            get().app.graph.edges.filter(e => e.selected).map(e => e.id)
          ],
          ([], selectedNodeIds, selectedEdgeIds) => [
            ...selectedNodeIds.map(id => ({ type: 'node', id })),
            ...selectedEdgeIds.map(id => ({ type: 'edge', id })),
          ] as Selection[],
          shallow,
        ),

        getSelectedNodeId: computed(
          () => [get().app.graph.nodes.filter(n => n.selected).map(n => n.id)],
          ([], selectedNodeIds) => selectedNodeIds.length === 1 ? selectedNodeIds[0] : null,
          shallow,
        ),

        getSelectedEdgeId: computed(
          () => [get().app.graph.edges.filter(e => e.selected).map(e => e.id)],
          ([], selectedEdgeIds) => selectedEdgeIds.length === 1 ? selectedEdgeIds[0] : null,
          shallow,
        ),

        getSelectionCount: computed(
          () => [get().app.graph.nodes.filter(n => n.selected).length, get().app.graph.edges.filter(e => e.selected).length],
          ([], selectedNodeCount, selectedEdgeCount) => selectedNodeCount + selectedEdgeCount,
        ),

        getTypeInfo: computed(
          () => [
            get().app.graph.nodes.map(node => node.id),
            get().app.graph.nodes.map(node => node.data.pluginType),
            get().types.static,
            get().types.construct,
            get().types.dynamic[get().app.id || ''] || {},
          ],
          ([], nodeIds, nodeTypes, staticTypes, constructTypes, dynamicTypes) => {
            const ret: { [nodeId: string]: TypeInfo } = {}
            for (let i = 0; i < nodeIds.length; i++) {
              const nodeId = nodeIds[i];
              const nodeType = nodeTypes[i];
              ret[nodeId] = {
                type: nodeType,
                constructInfo: nodeType.dynamic?.constructType && constructTypes[nodeType.dynamic.constructType],
                pluginInfo: nodeType.static
                  ? staticTypes[nodeType.static]
                  : dynamicTypes[nodeId],
                dynamicTypeInfoOutdated: Boolean(nodeType.dynamic && !isEqual(removeTypename(nodeType.dynamic), removeTypename(dynamicTypes[nodeId]?.pluginType?.dynamic))),
              };
            }
            return ret;
          },
          shallow,
        ),

        getRelatedNodeIds: computed(
          () => [
            get().app.graph.edges.reduce((acc, edge) => {
              acc[edge.source] = edge.target;
              return acc;
            }, {} as { [sourceNodeId: string]: string }),
            get().actions.graph.getSelectedNodeId(),
          ],
          ([distance, sourceNodeId], edgeMapping, selectedNodeId) => {
            if (selectedNodeId === null) return [];
            return getRelatedNodeIds(sourceNodeId || selectedNodeId, distance, edgeMapping);
          },
          shallow,
        ),

        requiresChromeExtension: computed(
          () => [
            Object.entries(get().actions.graph.getTypeInfo()).reduce((requiresExt, [nodeId, typeInfo]) => {
              for (const c of typeInfo.pluginInfo?.categories || []) {
                if (c === PluginCategory.ChromeExtensionOnly) {
                  return true;
                }
              }
              return requiresExt;
            }, false),

          ],
          ([], requiresExt) => requiresExt,
        ),
      },

      addErrorNotification: (error: AxiosError | ApolloError | GraphQLError | string) => {
        console.warn(error);
      },

      addSuccessNotification: (message: string) => {
        console.log(message);
      },

      startDebug: () => set(state => {
        state.editorView.debugAppOpen = true;
      }),
      endDebug: () => set(state => {
        state.editorView.debugAppOpen = false;
      }),
      setTemplateView: (view: string | null) => set(state => {
        state.editorView.templateView = view;
      }),
      setClipboard: (selections: Selection[]) => set(state => {
        state.clipboard = [...selections];
      }),
      setShortcutsPopoverOpen: (open: boolean) => set(state => {
        state.editorView.shortcutsPopoverOpen = open;
      }),
    },

    graphql: {
      loadApp: async (client: ApolloClient<object>, appId: string | null) => await loadApp(client, appId, get, set),

      saveApp: async (client: ApolloClient<object>, siteId: string) => {
        const state = get();
        const commonVars = {
          flowName: state.app.name,
          flow: createFlowFromZustand(
            state.app.graph.nodes.map(n => n.data),
            state.app.graph.edges,
          ),
          isPublic: state.app.isPublic,
          layout: state.app.graph.nodes
            .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
          startNodeId: state.app.startNodeId,
          aiConfig: state.app.aiConfig,
          signinRequired: state.app.signinRequired,
        }
        if (!state.app.id) {
          const result = await client.mutate({
            mutation: CREATE_FLOW_V2,
            variables: {
              siteId,
              ...commonVars,
            }
          })
          return { ...result, data: result.data?.acreateFlowV2 } as FetchResult<string>;
        }
        else {
          const result = await client.mutate({
            mutation: UPDATE_FLOW_V2,
            variables: {
              flowId: state.app.id,
              ...commonVars,
            }
          })
          return { ...result, data: result.data?.aupdateFlowV2.id } as FetchResult<string>;
        }
      },

      deleteApp: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot delete unsaved app");
        }
        const result = await client.mutate({
          mutation: DELETE_FLOW_V2,
          variables: { flowId: state.app.id }
        })
        return result;
      },

      duplicateApp: async (client: ApolloClient<object>, siteId: string) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot duplicate unsaved app");
        }
        const result = await client.mutate({
          mutation: CREATE_FLOW_V2,
          variables: {
            siteId: siteId,
            flowName: state.app.name + ' (copy)',
            flow: createFlowFromZustand(
              state.app.graph.nodes.map(n => n.data),
              state.app.graph.edges,
            ),
            isPublic: state.app.isPublic,
            layout: state.app.graph.nodes
              .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
            startNodeId: state.app.startNodeId,
            aiConfig: state.app.aiConfig,
            signinRequired: state.app.signinRequired,
          }
        })
        const newAppId = result.data?.acreateFlowV2;
        await loadApp(client, newAppId, get, set);
        return result;
      },

      validatePluginDependencies: async (client: ApolloClient<object>) => {
        const state = get();
        const flow = createFlowFromZustand(
          state.app.graph.nodes.map(n => n.data),
          state.app.graph.edges,
        );
        await client.query({
          query: ValidateApp,
          variables: { flow }
        }).then(res => {
          if (res.error) {
            get().actions.addErrorNotification(res.error);
          }
          else if (res.data.avalidateFlow) {
            set(state => {
              state.app.graph.validationResults = res.data.avalidateFlow;
            });
          }
        }).catch(get().actions.addErrorNotification);
      },


      updateDefaultEditorView: async (client: ApolloClient<object>, view: string) => {
        const state = get();
        if (state.app.id) {
          await client.mutate({
            mutation: UPDATE_FLOW_V2,
            variables: {
              flowId: state.app.id,
              defaultEditorView: view,
            }
          });
        }
      },

      loadStaticTypes: async (client: ApolloClient<object>) => {
        await client.query({ query: GET_STATIC_INFO })  // this is cached
          .then(res => {
            if (res.error) {
              get().actions.addErrorNotification(res.error);
            }
            else {
              set(state => {
                state.types.static = arrayToObject(res.data.alistPluginInfo, ['pluginType', 'static']);
                state.types.construct = arrayToObject(res.data.alistPluginConstructs, 'constructType');
              })
            }
          })
          .catch(get().actions.addErrorNotification);

      },

      loadDynamicTypes: async (client: ApolloClient<object>) => {
        const state = get();
        await loadDynamicTypes(client, state.app.graph.nodes.map(n => n.data), get);
      },

      loadDynamicType: async (client: ApolloClient<object>, nodeId: string, constructParam?: TDynamicTypedPlugin) => {
        logDebug(`loading dynamic type for ${nodeId}`)
        const state = get();
        const appId = state.app.id || ''; // we will store unsaved app's typeinfo under key ''
        let cparam = constructParam
          || state.app.graph.nodes.find(n => n.id === nodeId)?.data?.pluginType?.dynamic
          || state.types.dynamic[appId]?.[nodeId]?.pluginType?.dynamic;
        if (!cparam) {
          state.actions.addErrorNotification("Cannot load dynamic type without construct parameters.");
          return;
        }
        await client.query({
          query: GET_DYNAMIC_PLUGIN_INFO,
          variables: { config: removeTypename(cparam) },
          fetchPolicy: 'no-cache',
        }).then(res => {
          if (res.error) {
            get().actions.addErrorNotification(res.error);
          }
          else {
            set(state => {
              if (!(appId in state.types.dynamic)) {
                state.types.dynamic[appId] = {}
              }
              state.types.dynamic[appId][nodeId] = res.data.agetDynamicPluginInfo;
            })
          }
        })
          .catch(get().actions.addErrorNotification);
      },

    },
  }))))
)

//useEditorStore.subscribe(state => state.types, logDebug)

///// helper functions //////////////////////////////////////
// NOTE $ prefix functions are used by store functions to mutate the draft
// these functions cannot call functions inside the store

function getRelatedNodeIds(
  fromNodeId: string,
  distance: number,
  edgeMapping: { [source: string]: string },
): string[] {
  if (distance == 0) return [fromNodeId];
  const previousNodeIds = Object.entries(edgeMapping)
    .filter(([_, target]) => target === fromNodeId).map(([source]) => source);
  return previousNodeIds.flatMap(nodeId => getRelatedNodeIds(nodeId, distance - 1, edgeMapping));
}

async function loadApp(client: ApolloClient<object>, appId: string | null, get: () => EditorState, set: (fn: (state: EditorState) => any) => void) {
  set(state => {
    state.settingsMenu.selected = null;
    state.editorView.debugAppOpen = false;
    state.app = defaultApp;
  })
  if (appId !== null) {
    const { data } = await client.query({
      query: GET_FLOW_V2,
      variables: { flowId: appId },
      fetchPolicy: 'no-cache',
    });
    const flow = data.agetFlowV2;
    const { nodesData, nodesLocations, edges } = getGraphForZustand(flow.config, flow.layout);
    set(state => {
      state.app.id = flow.id;
      state.app.aiConfig = flow.aiConfig;
      state.app.id = flow.id;
      state.app.name = flow.name;
      state.app.isPublic = flow.isPublic;
      state.app.graph.nodes = Object.entries(nodesData).map(([id, data]) => ({
        id: id,
        data: data,
        position: nodesLocations[id],
        type: 'flowNode',
        selected: false,
      }));
      // have to use config since nodesData lost ordering
      state.app.startNodeId = flow.startNodeId || flow.config.plugins[0]?.id || null;
      // TODO the color needs to come from theme
      state.app.graph.edges = Object.values(edges);
      state.app.signinRequired = flow.signinRequired;
      state.editorView.templateView = flow.defaultEditorView;
    });
    await loadDynamicTypes(client, flow.config.plugins, get);
  }
}

async function loadDynamicTypes(
  client: ApolloClient<object>,
  plugins: TFlowPluginV2[],
  get: () => EditorState,
) {
  const promises = plugins.filter(p => p.pluginType.dynamic)
    .map(p => get().graphql.loadDynamicType(client, p.id, p.pluginType.dynamic || undefined))

  await Promise.all(promises);
}


function createNodeId(nodeIds: string[]): string {
  let i = 1;
  while (true) {
    if (!nodeIds.includes(i.toString())) return i.toString();
    i++;
  }
}

function $addNode(
  state: EditorState,
  data: Omit<FlowNodeData, "id" | "__typename">,
  position: XYPosition,
): GraphNode {
  const nodeId = createNodeId(state.app.graph.nodes.map(n => n.id));
  const n: GraphNode = {
    id: nodeId,
    data: {
      ...data,
      id: nodeId,
      __typename: 'TFlowPluginV2',
    },
    position,
    type: 'flowNode',
  }
  state.app.graph.nodes.push(n);

  if (state.app.graph.nodes.length === 0) {
    state.app.startNodeId = nodeId;
  }

  return n;
}

// return a new object with references replaced
function replaceReferences(idMapping: Map<string, string>, original: DynamicValueParam) {
  if (!original) return original;

  if (Array.isArray(original)) {
    return original.map(v => typeof v === 'string' ? v : replaceReferences(idMapping, v));
  }

  if (isComposite(original)) {
    const newProps: { [key: string]: DynamicValueParam } = {};
    for (const [key, val] of Object.entries(original.props)) {
      newProps[key] = replaceReferences(idMapping, val);
    }
    return { props: newProps };
  }

  // isSimple
  const possibleNewId = idMapping.get(original.reference);
  if (possibleNewId) {
    return { ...original, reference: possibleNewId };
  }
  else return structuredClone(original);
}

// use current selections in graph if selections is not provided, in that case this function updates styling of selected nodes and edges
function $updateSelections(state: EditorState, selections?: Selection[]) {

  const trueSelections = selections || [
    ...state.app.graph.nodes.filter(n => n.selected).map(n => ({ type: 'node', id: n.id })),
    ...state.app.graph.edges.filter(e => e.selected).map(e => ({ type: 'edge', id: e.id })),
  ];

  state.app.graph.nodes = state.app.graph.nodes.map(n => ({ ...n, selected: false }));
  state.app.graph.edges = state.app.graph.edges.map(e => ({ ...e, selected: false, style: getEdgeStyle() }));

  for (const selection of trueSelections) {
    if (selection.type === 'node') {
      const node = state.app.graph.nodes.find(n => n.id === selection.id);
      if (node) node.selected = true;
    }
    else if (selection.type === 'edge') {
      const edge = state.app.graph.edges.find(e => e.id === selection.id);
      if (edge) {
        edge.selected = true;
        edge.style = getEdgeStyle(state.editorView.edgeHightlightColor);
      }
    }
  }
}

function $removeNodeFromState(nodeId: string, state: EditorState): { removedNode: GraphNode | undefined, removedEdges: Edge[] } {
  const removedNode = state.app.graph.nodes.find(n => n.id === nodeId);
  const removedEdges: Edge[] = [];
  if (removedNode) {
    state.app.graph.nodes = state.app.graph.nodes.filter(n => n.id !== nodeId);

    for (const e of state.app.graph.edges) {
      if (e.source === nodeId || e.target === nodeId) {
        const removedEdge = $removeEdgeFromState(e.id, state);
        if (removedEdge) {
          removedEdges.push(removedEdge);
        }
      }
    }
    if (state.app.graph.nodes.length === 0) {
      state.app.startNodeId = null;
    }
  }

  return { removedNode, removedEdges };
}

function $removeEdgeFromState(edgeId: string, state: EditorState): Edge | undefined {
  const edge = state.app.graph.edges.find(e => e.id === edgeId);
  state.app.graph.edges = state.app.graph.edges.filter(e => e.id !== edgeId);
  return edge || null;
}

function $recordAction(state: EditorState, action: ActionRecord) {
  const now = Date.now();
  const prevEntry = state.app.graph.pastActions[state.app.graph.pastActions.length - 1];
  if (now - prevEntry?.timestamp < 1000) {
    prevEntry.actions.push(action);
  }
  else {
    state.app.graph.pastActions.push({ timestamp: now, actions: [action] });
  }
  while (state.app.graph.pastActions.length > 50) {
    state.app.graph.pastActions.shift();
  }
  // clear out redo queue when new action is recorded
  state.app.graph.futureActions = [];
}

function $undoActions(state: EditorState, recordsToUndo: ActionHistory, recordsForRedo: ActionHistory) {
  if (recordsToUndo.length === 0) return;
  const record = recordsToUndo.pop();
  const revertRecord = { timestamp: Date.now(), actions: [] as ActionRecord[] };
  while (record && record.actions.length > 0) {
    const action = record.actions.pop();
    const reversedAction = $applyAction(state, action);
    revertRecord.actions.push(reversedAction);
  }
  if (revertRecord.actions.length > 0) {
    recordsForRedo.push(revertRecord);
  }
}

function $getSelections(state: EditorState): Selection[] {
  return [
    ...state.app.graph.nodes.filter(n => n.selected).map(n => ({ type: 'node', id: n.id })),
    ...state.app.graph.edges.filter(e => e.selected).map(e => ({ type: 'edge', id: e.id })),
  ] as Selection[];
}

// apply action to state, return the reversed action
function $applyAction(state: EditorState, action: ActionRecord): ActionRecord {


  const timestamp = Date.now();

  switch (action.action) {
    case 'add':
      const removedNodes = [];
      const removedEdges = []
      for (const id of action.nodeIds) {
        const removed = $removeNodeFromState(id, state);
        if (removed.removedNode) {
          removedNodes.push(removed.removedNode);
        }
        removedEdges.push(...removed.removedEdges);
      }
      for (const id of action.edgeIds) {
        const removed = $removeEdgeFromState(id, state);
        if (removed) {
          removedEdges.push(removed);
        }
      }
      $updateSelections(state, action.prevSelection);
      return { action: 'remove', nodes: removedNodes, edges: removedEdges };
    case 'remove':
      const currentSelections = $getSelections(state);
      state.app.graph.nodes.push(...action.nodes);
      state.app.graph.edges.push(...action.edges);
      $updateSelections(state, [
        ...action.nodes.map(n => ({ type: 'node', id: n.id })),
        ...action.edges.map(e => ({ type: 'edge', id: e.id })),
      ] as Selection[]);
      return {
        action: 'add',
        nodeIds: action.nodes.map(n => n.id),
        edgeIds: action.edges.map(e => e.id),
        prevSelection: currentSelections
      };
    case 'change':
      const node = state.app.graph.nodes.find(n => n.id === action.prevNodeData.id);
      if (node) {
        const prevNodeData = node.data;
        node.data = action.prevNodeData;
        $updateSelections(state, [{ type: 'node', id: node.id }]);
        return { action: 'change', prevNodeData };
      }
      // This case should theoretically never happen (if the history is properly tracked)
      // having it here for safety
      else {
        const prevSelection = $getSelections(state);
        $addNode(state, action.prevNodeData, { x: 0, y: 0 });
        return { action: 'add', nodeIds: [action.prevNodeData.id], edgeIds: [], prevSelection };
      }
  }
}
