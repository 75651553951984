import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';

// export const dragonfruitJoyTheme = responsiveFontSizes(createTheme({
//   palette: {
//     primary: {
//       light: '#f95688',
//       main: '#c1185b',
//       dark: '#8b0032',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff7b47',
//       main: '#e54819',
//       dark: '#ab0300',
//       contrastText: '#000',
//     },
//     warning: {
//       light: '#76d275',
//       main: '#43a047',
//       dark: '#00701a',
//       contrastText: '#000',
//     },
//     info: {
//       light: '#000',
//       main: '#000',
//       dark: '#000',
//       contrastText: '#fff',
//     },
//   },
//   shape: {
//     borderRadius: 4,
//   },
//   // typography: {
//   //   htmlFontSize: 25,
//   //   fontSize: 25,
//   //   body1: {
//   //     fontSize: '1.5rem',
//   //     lineHeight: 2,
//   //   },
//   //   body2: {
//   //     fontSize: '1.2rem',
//   //     lineHeight: 1.5,
//   //   }
//   // }
//   typography: {
//     fontFamily: [
//       'Poppins, sans-serif',
//       'Proxima Nova, sans-serif',
//       'Helvetica Neue, sans-serif',
//       'Helvetica, sans-serif',
//       'Arial, sans-serif',
//     ].join(','),
//     fontWeightLight: 300,
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//     fontWeightBold: 700,
//     h1: {
//       fontSize: '4rem',
//       fontWeight: 'bold',
//       letterSpacing: '-0.01562em',
//       lineHeight: '1.1',
//     },
//     h2: {
//       fontSize: '3.5rem',
//       fontWeight: 'bold',
//       letterSpacing: '-0.00833em',
//       lineHeight: '1.1',
//     },
//     h3: {
//       fontSize: '3rem',
//       fontWeight: 'bold',
//       letterSpacing: '0em',
//       lineHeight: '1.1',
//     },
//     h4: {
//       fontSize: '2.125rem',
//       fontWeight: 'bold',
//       letterSpacing: '0.00735em',
//       lineHeight: '1.1',
//     },
//     h5: {
//       fontSize: '1.5rem',
//       fontWeight: 'bold',
//       letterSpacing: '0em',
//       lineHeight: '1.1',
//     },
//     h6: {
//       fontSize: '1.25rem',
//       fontWeight: 'bold',
//       letterSpacing: '0.0075em',
//       lineHeight: '1.1',
//     },
//     subtitle1: {
//       fontSize: '1rem',
//       fontWeight: 'bold',
//       letterSpacing: '0.00938em',
//       lineHeight: '1.1',
//     },
//     subtitle2: {
//       fontSize: '0.875rem',
//       fontWeight: 'bold',
//       letterSpacing: '0.00714em',
//       lineHeight: '1.1',
//     },
//     body1: {
//       fontSize: '1rem',
//       fontWeight: 400,
//       letterSpacing: '0.00938em',
//       lineHeight: '1.8',
//     },
//     body2: {
//       fontSize: '0.875rem',
//       fontWeight: 400,
//       letterSpacing: '0.01071em',
//       lineHeight: '1.5',
//     },
//     button: {
//       fontSize: '0.875rem',
//       fontWeight: 'bold',
//       letterSpacing: '0.02857em',
//       lineHeight: '1.5',
//       textTransform: 'uppercase',
//     },
//     caption: {
//       fontSize: '0.75rem',
//       fontWeight: 400,
//       letterSpacing: '0.03333em',
//       lineHeight: '1.66',
//     },
//     overline: {
//       fontSize: '0.625rem',
//       fontWeight: 'bold',
//       letterSpacing: '0.16667em',
//       lineHeight: '2.66',
//       textTransform: 'uppercase',
//     },
//   },
// }));

// export const iTentoTheme = createTheme(dragonfruitJoyTheme, {
//   palette: {
//     primary: {
//       light: '#fff',
//       main: '#eee',
//       dark: '#a6a6a6',
//       contrastText: '#000',
//     },
//     secondary: {
//       light: '#ffe6c1',
//       main: '#ffe0b2',
//       dark: '#b29c7c',
//       contrastText: '#000',
//     },
//     warning: {
//       light: '#000',
//       main: '#000',
//       dark: '#000',
//       contrastText: '#fff',
//     },
//     info: {
//       light: '#a3c06b',
//       main: '#a1bd7c',
//       dark: '#697f43',
//       contrastText: '#000',
//     },
//   },
//   typography: {
//     spacing: (num: number) => `${num * 0.5}rem`,
//     fontFamily: [
//       'Poppins, sans-serif',
//       'Proxima Nova, sans-serif',
//       'Helvetica Neue, sans-serif',
//       'Helvetica, sans-serif',
//       'Arial, sans-serif',
//     ].join(','),
//     body1: {
//       fontSize: '1.6rem',
//     },
//     body2: {
//       fontSize: '1.6rem',
//     },
//     button: {
//       fontSize: '2rem',
//       fontWeight: 500,
//       textTransform: 'none',
//     },
//     caption: {
//       fontSize: '0.75rem',
//       fontWeight: 400,
//       letterSpacing: '0.03333em',
//     },
//     overline: {
//       fontSize: '0.625rem',
//       fontWeight: 500,
//       letterSpacing: '0.16667em',
//       textTransform: 'uppercase',
//     },
//   },
// });

// export const dragonfruitJoyTheme1 = createTheme(iTentoTheme, {
//   palette: {
//     secondary: {
//       light: '#f9c8d9',
//       main: '#f8bbd0',
//       dark: '#ad8291',
//       contrastText: '#000',
//     },
//   },
//   typography: {
//     fontFamily: [
//       'Poppins, sans-serif',
//       'Proxima Nova, sans-serif',
//       'Helvetica Neue, sans-serif',
//       'Helvetica, sans-serif',
//       'Arial, sans-serif',
//     ].join(','),
//     fontSize: 18,
//     h1: {
//       fontSize: '3rem',
//       fontWeight: 500,
//       letterSpacing: '-0.01562em',
//     },
//     h2: {
//       fontSize: '2.5rem',
//       fontWeight: 500,
//       letterSpacing: '-0.00833em',
//     },
//     h3: {
//       fontSize: '2.25rem',
//       fontWeight: 500,
//       letterSpacing: '0em',
//     },
//     h4: {
//       fontSize: '2rem',
//       fontWeight: 500,
//       letterSpacing: '0.00735em',
//     },
//     h5: {
//       fontSize: '1.75rem',
//       fontWeight: 500,
//       letterSpacing: '0em',
//     },
//     h6: {
//       fontSize: '1.5rem',
//       fontWeight: 500,
//       letterSpacing: '0.0075em',
//     },
//     body1: {
//       fontSize: '1.5rem',
//       fontWeight: 500,
//       letterSpacing: '0.01em',
//     },
//     body2: {
//       fontSize: '1.2rem',
//       fontWeight: 400,
//       letterSpacing: '0.00938em',
//     },
//     subtitle1: {
//       fontSize: '1.2rem',
//       fontWeight: 500,
//       letterSpacing: '0.00938em',
//     },
//     subtitle2: {
//       fontSize: '1rem',
//       fontWeight: 500,
//       letterSpacing: '0.00714em',
//     },
//     caption: {
//       fontSize: '0.75rem',
//       fontWeight: 400,
//       letterSpacing: '0.03333em',
//     },
//     overline: {
//       fontSize: '0.75rem',
//       fontWeight: 500,
//       letterSpacing: '0.08333em',
//     },
//     button: {
//       fontSize: '1.25rem', // larger font size for button
//       fontWeight: 700,
//       textTransform: 'none',
//     },
//   },
// });

export const defaultTheme = createTheme();

// export const bwTheme = createTheme(defaultTheme, {
//   palette: {
//     primary: defaultTheme.palette.augmentColor({ color: { main: '#000' } }),
//     secondary: defaultTheme.palette.augmentColor({ color: { main: '#ddd' } }),
//     info: defaultTheme.palette.augmentColor({ color: { main: '#fff' } }),
//     warning: defaultTheme.palette.augmentColor({ color: { main: '#fff' } }),
//     text: {
//       primary: alpha('#fff', 0.87),
//       secondary: alpha('#fff', 0.6),
//       disabled: alpha('#fff', 0.38),
//     },
//     background: {
//       paper: '#000',
//       default: '#000',
//     },
//   },

//   typography: {
//     fontFamily: [
//       'Poppins, sans-serif',
//       'Proxima Nova, sans-serif',
//       'Helvetica Neue, sans-serif',
//       'Helvetica, sans-serif',
//       'Arial, sans-serif',
//     ].join(','),
//     fontSize: 18,
//     h1: {
//       fontSize: '3rem',
//       fontWeight: 500,
//       letterSpacing: '-0.01562em',
//     },
//     h2: {
//       fontSize: '2.5rem',
//       fontWeight: 500,
//       letterSpacing: '-0.00833em',
//     },
//     h3: {
//       fontSize: '2.25rem',
//       fontWeight: 500,
//       letterSpacing: '0em',
//     },
//     h4: {
//       fontSize: '2rem',
//       fontWeight: 500,
//       letterSpacing: '0.00735em',
//     },
//     h5: {
//       fontSize: '1.75rem',
//       fontWeight: 500,
//       letterSpacing: '0em',
//     },
//     h6: {
//       fontSize: '1.5rem',
//       fontWeight: 500,
//       letterSpacing: '0.0075em',
//     },
//     body1: {
//       fontSize: '1.5rem',
//       fontWeight: 500,
//       letterSpacing: '0.01em',
//     },
//     body2: {
//       fontSize: '1.2rem',
//       fontWeight: 400,
//       letterSpacing: '0.00938em',
//     },
//     subtitle1: {
//       fontSize: '1.2rem',
//       fontWeight: 500,
//       letterSpacing: '0.00938em',
//     },
//     subtitle2: {
//       fontSize: '1rem',
//       fontWeight: 500,
//       letterSpacing: '0.00714em',
//     },
//     caption: {
//       fontSize: '0.75rem',
//       fontWeight: 400,
//       letterSpacing: '0.03333em',
//     },
//     overline: {
//       fontSize: '0.75rem',
//       fontWeight: 500,
//       letterSpacing: '0.08333em',
//     },
//     button: {
//       fontSize: '1.25rem', // larger font size for button
//       fontWeight: 700,
//       textTransform: 'none',
//     },
//   },
// });
