import { Theme, ThemeProvider, alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';
import React from 'react';
import { defaultTheme } from './themes';
import { TFont, TPalette } from '../../../generated/gql/graphql';
import { useClientStore } from '../../hooks/ClientState';
import { useShallow } from 'zustand/react/shallow';

function getTheme(palette: TPalette, font: TFont, themeOverride?: Partial<Theme>): Theme {

  const augmentedPrimary = defaultTheme.palette.augmentColor({ color: { main: palette.primary } })

  const theme = responsiveFontSizes(createTheme(
    {
      ...themeOverride,
      typography: {
        fontSize: font.fontSize,
        fontFamily: font.fontFamily,
      },
      palette: {
        background: {
          default: augmentedPrimary.main,
          paper: augmentedPrimary.light,
        },
        // NOTE: we are swapping primary and secondary colors here due to how it's currently used in the app
        primary: defaultTheme.palette.augmentColor({ color: { main: palette.accent2 } }),
        secondary: defaultTheme.palette.augmentColor({ color: { main: palette.accent1 } }),
        info: defaultTheme.palette.augmentColor({ color: { main: palette.callToAction } }),
        text: {
          primary: alpha(augmentedPrimary.contrastText, 0.87),
          secondary: alpha(augmentedPrimary.contrastText, 0.6),
          disabled: alpha(augmentedPrimary.contrastText, 0.38),
        }
      },
    }))
  return theme;
}


export default function CustomizationProvider(props: {
  children: React.ReactNode,
  palette?: TPalette,
  font?: TFont,
  theme?: Partial<Theme>,
}): React.ReactElement {
  const palette = useClientStore(useShallow(state => state.getPalatte()));
  const font = useClientStore(useShallow(state => state.getFont()));
  const theme = getTheme(props.palette || palette, props.font || font, props.theme);

  return <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider >
}
