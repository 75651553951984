import { Alert, Card, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { IWebInteractionOutcome } from '../../../generated/gql/graphql';
import { useClientStore } from '../../hooks/ClientState';

export default function WebInteractionOutcome(props: {
  required: boolean,
  onSubmit: (outcome: IWebInteractionOutcome) => void,
}): React.ReactElement {
  const webInteractionOutcome = useClientStore(state => state.webInteractionOutcome);

  useEffect(() => {
    props.onSubmit(webInteractionOutcome);
  }, [props.required]);



  return canUploadWebContent() ?
    <Card sx={{ p: 2, width: '100%' }}>
      <Stack direction='row' spacing={1} justifyContent='center' display='flex'>
        <CircularProgress />
        <Typography>Uploading changes in web page since last action...</Typography>
      </Stack>
    </Card>
    : <Alert severity="warning">Auto-upload web content is only available with chrome extension. Please manually submit html content.</Alert>;
}

// TODO this might no longer be correct
export function canUploadWebContent(): boolean {
  return Boolean(chrome?.runtime);
}
